import {
  APIMenu,
  APIMenuItem,
  APIMenuSectionChanges,
  APIModifier,
  APIOrder,
  APIOrderItem,
} from './api';
import {
  Currency,
  DinerInfo,
  DiningModeName,
  I18nRichText,
  I18nText,
  LocaleInfo,
  PaymentBypassType,
  PaymentConfig,
  PaymentError,
  PaymentStatus,
  Period,
  Table,
  Tag,
  TaxRate,
  Theme,
  UserInfo,
  VerifiableValue,
  Visitor,
  Voucher,
} from './model';


export enum Event {
  authKioskDeviceV1 = 'v1 auth kiosk device',
  authDinerDeviceV1 = 'v1 auth diner device',

  dinerSessionOpenV1 = 'v1 session open',
  kioskSessionOpenV1 = 'v1 kiosk session open',
  connectDinerDeviceV1 = 'v1 connect device',
  freeDinerDeviceV1 = 'v1 free device',

  sessionCloseV1 = 'v1 session close',
  takeAwaySessionCloseV1 = 'v1 takeaway session close',

  addOrderItemWithModifiersV1 = 'v1 add order item with modifiers',
  removeOrderItemsV1 = ' v1 remove order items',

  updateOrderVoucherV1 = 'v1 update order voucher',
  voucherUpdatedV1 = 'v1 voucher updated',

  deviceToAssignV1 = 'v1 device to assign',
  deviceAssignedV1 = 'v1 device assigned',
  deviceUnassignedV1 = 'v1 device unassigned',
  dinerDeviceUpdatedV1 = 'v1 diner device updated',
  kioskDeviceUpdatedV1 = 'v1 kiosk device updated',

  createVisitorV1 = 'v1 create visitor',

  // menuSectionsChangedV1 = 'v1 menu sections changed',
  // menuRankUpdatedV1 = 'v1 menu rank updated',
  // itemUpdatedV1 = 'v1 item updated',
  // itemsUpdatedV1 = 'v1 items updated',

  // modifierUpdatedV1 = 'v1 modifier updated',
  // modifiersChangedV1 = 'v1 modifiers changed',
  // modifierRankUpdatedV1 = 'v1 modifier rank updated',
  // showModifiersImagesUpdatedV1 = 'v1 show modifiers images updated',

  ordersChangedV1 = 'v1 orders changed',
  // orderThresholdUpdatedV1 = 'v1 order threshold updated',

  updateOpenOrderScheduledTakeawayV1 = 'v1 update open order scheduled takeaway',

  sendDinerOrderToWaiterV1 = 'v1 send order',
  sendDinerOrderV1 = 'V1 send diner order',
  waitingForWaiterApprovalV1 = 'v1 waiting for waiter approval',
  sendUnpaidTakeAwayOrderV1 = 'v1 send unpaid takeaway order',

  sendOrderSuccessV1 = 'v1 send order success',
  sendOrderFailureV1 = 'v1 send order failure',

  paymentChangedV1 = 'v1 order payment changed',
  paymentUpdatedV1 = 'v1 payment updated',
  receiptDetailsUpdatedV1 = 'v1 receipt details updated',

  restaurantNameUpdatedV1 = 'v1 restaurant name updated',
  localesChangedV1 = 'v1 locales changed',
  // currencyUpdatedV1 = 'v1 currency updated',
  applicationTextUpdatedV1 = 'v1 application text updated',
  applicationTextsChangedV1 = 'v1 application texts changed',

  posConnectedV1 = 'v1 pos connected',
  // itemsSyncedWithPosV1 = 'v1 items synced with pos',

  tagsChangedV1 = 'v1 tags changed',

  sendEmailV1 = 'v1 send email',

  updateDinerInfoV1 = 'v1 update diner info',
}

type Error = string | { message: string };

export interface Payload {}
export interface Response {
  error?: Error;
}

export interface AuthDevicePayload extends Payload {
  identification: string;
}

export interface AuthDeviceResponse extends Response {
  id: number;
  identification: string;
  title: string;
  accepted: boolean;
}

export interface CreateVisitorPayload extends Payload {
  identification: string;
  visitor: Visitor;
}

export interface KioskSessionOpenPayload extends Payload {
  deviceId: number;
}

export interface DinerSessionOpenPayload extends Payload {
  deviceIds: number[];
  tableIds: number[];
  waiterId: number;
  allowTableSharing: true;
  roomId?: number;
  pax?: number;
}

export interface SessionOpenResponse extends Response {
  diner_session_id: number;
}

export interface ConnectDinerDevicePayload extends Payload {
  deviceId: number;
  sessionId: number;
}

export interface ConnectDinerDeviceResponse extends Response {
  diner_session_id: number;
  device_id: number;
}

export interface FreeDinerDevicePayload extends Payload {
  deviceId: number;
  sessionId: number;
}

export interface FreeDinerDeviceResponse extends Response {
  device_id: number;
}

export interface CloseSessionPayload extends Payload {
  sessionId: number;
}

export interface CloseDinerSessionPayload extends CloseSessionPayload {
  waiterId: number;
}

export type CloseTakeAwaySessionPayload = CloseSessionPayload;

export interface CloseSessionResponse extends Response {
  oldSessionId: number;
}

interface ChangeOrderedItemPayload extends Payload {
  sessionId: number;
  deviceId: number;
  dineIn: boolean;
  nodeId: number;
  itemId: number;
}

export interface OrderedModifiers {
  [key: number]: string | number[]; // text or optionIds
}

export interface AddOrderedItemPayload extends ChangeOrderedItemPayload {
  modifierValues: OrderedModifiers;
  quantity?: number;
}

export interface RemoveOrderedItemPayload extends ChangeOrderedItemPayload {
  orderItemIds: number[];
}

export interface ChangeOrderedItemResponse extends Response {
  diner_session_id: number;
  node_id: number;
  item_id: number;
  change: number;
}

export interface UpdateOpenOrderScheduledTakeawayPayload extends Payload {
  sessionId: number;
  scheduledTakeaway?: number;
}

export interface SendDinerOrderPayload extends Payload {
  sessionId: number;
  deviceId: number | undefined;
  paymentBypass: PaymentBypassType;
}

export interface SendDinerOrderToWaiterPayload extends Payload {
  sessionId: number;
  deviceId: number | undefined;
}

export interface SendUnpaidTakeAwayOrderPayload extends Payload {
  sessionId: number;
  deviceId: number | undefined;
  userInfo: UserInfo;
  paymentBypass: PaymentBypassType;
  tipAmount?: number;
  note?: string;
  scheduledTakeaway?: number;
}

export interface SendOrderResponse extends Response {
  diner_session_id: number;
}

export interface DeviceAssignedPayload extends Payload {
  restaurantName: string;
  tables: Table[];
  dineIn?: boolean;
  diner_session_id?: number;
  menus: APIMenu[];
  menuSections: APIMenuSectionChanges;
  items: APIMenuItem[];
  modifiers: APIModifier[];
  orders: CurrentOrderChangedPayload;
  pastOrders: PastOrdersChangedPayload;
  paymentConfig: PaymentConfig | null;
  payment: DeviceConnectedPaymentPayload;
  tags: Tag[];
  webTheme: Theme;
  serviceCharge: ServiceChargePayload;
  privacyPolicy: VerifiableValue<I18nRichText>;
  termsOfUse: VerifiableValue<I18nRichText>;
  dineInUserHelp?: string;
  orderThreshold: number;
  diningMode?: DiningModeName;
  isPaymentDisabled: boolean;
  isTakeAwayPayOnCollectionEnabled: boolean;
  taxRates: TaxRate[];
  vouchers: Voucher[];
  isVisitorCaptureActive: boolean;
  isEmailReceiptPromptEnabled: boolean;
  locales: LocaleInfo;
  applicationTexts: ApplicationTextPayload[];
  currency: Currency;
  receiptDetails: any;
  isYumpingoEnabled: boolean;
  takeawaySchedulePeriods: Period[];
  preparationTime: number;
  scheduleLimitDays: number;
  dinerInfo: DinerInfo | undefined;
  smsNotificationsDineIn: boolean;
  smsNotificationsTakeAway: boolean;
  posConnected: boolean;
  gaTrackingNumber: string | null;
  ordamoGaTrackingNumber: string | null;
}

export interface OrdersChangedPayload extends Payload {
  orders: APIOrder[];
  items: APIOrderItem[];
}

export interface CurrentOrderChangedPayload extends OrdersChangedPayload {
  diner_session_id: number;
}

export interface PastOrdersChangedPayload extends OrdersChangedPayload {
  diner_session_ids: number[];
}

export interface PaymentPayload extends Payload {
  paidAmount: number;
  paidTipAmount: number;
  error?: PaymentError;
}

export interface DeviceConnectedPaymentPayload extends PaymentPayload {
  lastStatus: PaymentStatus;
  lastPaidAmount: number;
  lastPaidTipAmount: number;
}

export interface PaymentChangedPayload extends PaymentPayload {
  deviceIdentification?: number;
  paymentState: PaymentStatus;
}

export interface ServiceChargePayload extends Payload {
  isDineInServiceChargeActive: boolean;
  isTakeAwayServiceChargeActive: boolean;
  serviceChargeDefaultValue: number;
}

export interface SendEmailPayload extends Payload {
  to: string;
  subject: string;
  message: string;
  altText: string;
}

export interface ApplicationTextPayload extends Payload {
  id: number;
  messageId: string;
  text: I18nText;
}

export interface UpdateDinerInfoPayload extends Payload {
  deviceId: number;
  customerName?: string;
}

export interface UpdateDinerInfoResponse extends Response {
  deviceId: number;
  customerName: string;
  customerNumber: number;
}

export interface UpdateOrderVoucherPayload extends Payload {
  sessionId: number;
  voucherId?: number;
}
