import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { selectors as socketSelectors } from 'ducks/socket';
import useConfigurableText from 'hooks/useConfigurableText';
import { Button, OnClickHandler } from 'components/Button';


interface Props {
  /** Use hidden prop instead of removing the button from the DOM - needed by the JudoPay iframe */
  hidden: boolean;
  onClick: OnClickHandler;
  disabled?: boolean;
}

const JudoPayCardPaymentButton: FC<Props> = ({ hidden, disabled, onClick }) => {
  const ct = useConfigurableText();

  const isConnected = useSelector(socketSelectors.isConnected);

  return (
    <Button
      // Disabling the button with CSS, because the 'disabled' attribute is controlled by the JudoPay iframe
      className={cx(
        'payment-button',
        { disabled: disabled || !isConnected },
        { hidden }
      )}
      id="submit-payment-button"
      label={ct('shared.labels.pay')}
      type="button"
      variant="primary"
      size="large"
      onClick={onClick}
    />
  );
};

export default JudoPayCardPaymentButton;
