import React, { FC, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as sessionActions, selectors as sessionSelectors } from 'ducks/session';
import { selectors as socketSelectors } from 'ducks/socket';
import updateDinerInfo from 'effects/updateDinerInfo';
import { useWebSocket } from 'contexts/SocketManagerContext';
import useConfigurableText from 'hooks/useConfigurableText';
import { Modal } from 'components/Modal';
import { saveDinerName } from 'localStorage';


const EditDinerInfoModal: FC = () => {
  const ct = useConfigurableText();
  const webSocket = useWebSocket();
  const dispatch = useDispatch();

  const showEditDinerInfoModal = useSelector(sessionSelectors.getShowEditDinerInfoModal);
  const dinerInfo = useSelector(sessionSelectors.getDinerInfo);
  const deviceId = useSelector(sessionSelectors.getDeviceId);
  const isConnected = useSelector(socketSelectors.isConnected);

  const [dinerName, setDinerName] = useState<string | undefined>(undefined);

  const wasDinerNameEdited = dinerName !== undefined && dinerName !== dinerInfo?.customerName;
  const placeholderOnUnchangedName = dinerInfo?.customerName && dinerInfo?.customerNumber
    ? `${dinerInfo?.customerName} ${dinerInfo?.customerNumber}`
    : ct('dinerInfo.namePlaceholder');

  const handleCancelAction = () => {
    setDinerName(undefined);
    dispatch(sessionActions.setShowEditDinerInfoModal(false));
  };
  const handleConfirmAction = async (e: FormEvent) => {
    e.preventDefault();
    if (wasDinerNameEdited && deviceId) {
      saveDinerName(dinerName);
      await updateDinerInfo(webSocket, dispatch, deviceId, dinerName);
    }
    setDinerName(undefined);
    dispatch(sessionActions.setShowEditDinerInfoModal(false));
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setDinerName(event.currentTarget.value);
  const handleFocus = () => {
    if (dinerName === undefined) {
      setDinerName(dinerInfo?.customerName);
    }
  };
  const handleBlur = () => {
    if (!wasDinerNameEdited) {
      setDinerName(undefined);
    }
  };


  return (
    <Modal
      position="center"
      className="text-left Modal-dinerinfo"
      isShown={showEditDinerInfoModal}
      onSubmit={handleConfirmAction}
      buttons={[
        {
          label: ct('shared.buttons.cancel'),
          type: 'button',
          variant: 'secondary',
          size: 'medium',
          onClick: handleCancelAction,
        },
        {
          label: ct('shared.buttons.confirm'),
          type: 'submit',
          size: 'medium',
          disabled: !isConnected,
        },
      ]}
    >
      <h5 className="text-center">{ct('dinerInfo.changeYourName')}</h5>
      <div className="inputBox">
        <label htmlFor="dinerName">
          {ct('dinerInfo.name')}
          <input
            id="dinerName"
            type="text"
            value={dinerName ?? ''}
            onChange={handleNameChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={wasDinerNameEdited ? undefined : placeholderOnUnchangedName}
          />
        </label>
      </div>
    </Modal>
  );
};

export default EditDinerInfoModal;
