import React, { FC, RefObject, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { useDispatch, useSelector } from 'react-redux';

import { Section } from 'types/model';
import { compareMenuItems } from 'util/compare';
import { formatText } from 'util/i18n';
import { actions as menuSectionActions, selectors as menuSectionSelectors } from 'ducks/menuSection';
import { actions as ordersActions, selectors as ordersSelectors } from 'ducks/orders';
import { useScroll } from 'hooks/useScroll';
import MenuItemCard from 'components/MenuItemCard';


const SCROLL_DELAY_MS = 100;
interface Props {
  section: Section;
  parentId?: string;
  parentTitle?: string;
  parentRef: RefObject<HTMLUListElement>;
}

const MenuListSection: FC<Props> = ({ section, parentId, parentTitle, parentRef }) => {
  const dispatch = useDispatch();
  const orderedItemCount = useSelector(ordersSelectors.getCountOfOrderedItemByNodeId);
  const selectedSectionId = useSelector(menuSectionSelectors.getSelectedSectionId);
  const scrollSelectedSectionIntoView = useSelector(menuSectionSelectors.getScrollSelectedSectionIntoView);
  const [headingRef, scrollIntoView] = useScroll<HTMLHeadingElement>();

  useEffect(
    () => {
      if (section.id === selectedSectionId && scrollSelectedSectionIntoView) {
        scrollIntoView();
        // This is ugly as fuck, but I couldn't find any other safe way to prevent
        // scrolling to a heading on button press from triggering a section change when
        // there were multiple on the screen :(
        setTimeout(
          () => dispatch(menuSectionActions.setScrollSelectedSectionIntoView(false)),
          SCROLL_DELAY_MS * 1.5,
        );
      }
    },
    [section.id, selectedSectionId, scrollSelectedSectionIntoView, dispatch, scrollIntoView]
  );

  const id = `${parentId ? `${parentId}-` : ''}${section.id}`;
  const title = `${parentTitle ? `${parentTitle} \u003e ` : ''}${formatText(section.title)}`;

  return (
    <>
      {section.menuItems.length > 0 && (
        <li key={id}>
          <VisibilitySensor
            containment={parentRef.current?.id}
            intervalDelay={SCROLL_DELAY_MS}
            onChange={isVisible => {
              if (!scrollSelectedSectionIntoView && isVisible) {
                dispatch(menuSectionActions.setSelectedSectionId(section.id));
              }
            }}
          >
            <h3 ref={headingRef}>{title}</h3>
          </VisibilitySensor>

          {section.menuItems
            .sort(compareMenuItems)
            .map(menuItem =>
              <MenuItemCard
                key={`${id}-${menuItem.nodeId}`}
                menuItem={menuItem}
                numberOrdered={orderedItemCount({ menuItem, orderedModifiers: [] })}
                onClick={() => dispatch(ordersActions.setCurrentItem(menuItem))}
              />
            )}
        </li>
      )}
      {section.subSections.map(subSection =>
        <MenuListSection
          key={`${id}-${subSection.id}`}
          section={subSection}
          parentId={id}
          parentTitle={title}
          parentRef={parentRef}
        />
      )}
    </>
  );
};

export default MenuListSection;
