import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { msDurationToMinsSecs } from 'util/time';
import { selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import closeSession from 'effects/closeSession';
import { useWebSocket } from 'contexts/SocketManagerContext';
import useConfigurableText from 'hooks/useConfigurableText';
import { Modal } from 'components/Modal';
import { timeoutConfig } from 'config/timeoutConfig';


type ResetEvent = 'load' | 'click' | 'scroll' | 'wheel';
const resetEvents: ResetEvent[] = ['load', 'click', 'scroll', 'wheel'];

interface CountdownProps {
  durationMs: number;
}

const Countdown: FC<CountdownProps> = ({ durationMs }) => {
  const [timeoutRemaining, setTimeoutRemaining] = useState(durationMs);
  const logoutTimeoutCounterRef = useRef<NodeJS.Timeout | null>();
  useEffect(
    () => {
      logoutTimeoutCounterRef.current = setTimeout(
        () => setTimeoutRemaining(timeoutRemaining - 1000),
        1000
      );
      return () => {
        if (logoutTimeoutCounterRef.current) clearTimeout(logoutTimeoutCounterRef.current);
      };
    },
    [timeoutRemaining]
  );
  return <span>{msDurationToMinsSecs(timeoutRemaining)}</span>;
};

const TimeoutModal: FC = () => {
  const ct = useConfigurableText();
  const { msToWarning, msWarningToLogout } = timeoutConfig;

  const dispatch = useDispatch();
  const webSocket = useWebSocket();
  const paymentsDisabled = useSelector(settingsSelectors.getIsPaymentDisabled);
  const dineIn = useSelector(sessionSelectors.getDineIn);
  const sessionId = useSelector(sessionSelectors.getSessionId);
  const isTimeoutModalEnabled = useSelector(sessionSelectors.isTimeoutModalEnabled);
  const [isWarningShowing, setIsWarningShowing] = useState(false);

  const warningTimeoutRef = useRef<NodeJS.Timeout | null>();
  const logoutTimeoutRef = useRef<NodeJS.Timeout | null>();

  const isDineInWithDisabledPayment = dineIn && paymentsDisabled;

  const disableTimeoutModal = isDineInWithDisabledPayment || !isTimeoutModalEnabled;

  const clearTimeouts = useCallback(() => {
    if (!disableTimeoutModal) {
      if (warningTimeoutRef.current) clearTimeout(warningTimeoutRef.current);
      if (logoutTimeoutRef.current) clearTimeout(logoutTimeoutRef.current);
      setIsWarningShowing(false);
    }
  }, [disableTimeoutModal]);

  const resetTimeouts = useCallback(() => {
    clearTimeouts();
    if (!disableTimeoutModal && sessionId) {
      warningTimeoutRef.current = setTimeout(
        () => {
          setIsWarningShowing(true);
          logoutTimeoutRef.current = setTimeout(
            () => {
              closeSession(dispatch, webSocket, dineIn, sessionId!);
              dispatch(push('/'));
            },
            msWarningToLogout
          );
        },
        msToWarning
      );
    }
  }, [
    clearTimeouts,
    disableTimeoutModal,
    sessionId,
    msToWarning,
    msWarningToLogout,
    dispatch,
    webSocket,
    dineIn,
  ]);

  useEffect(
    () => {
      resetTimeouts();
      resetEvents.forEach(event =>
        window.addEventListener(event, resetTimeouts)
      );
      return () => {
        clearTimeouts();
        resetEvents.forEach(event =>
          window.removeEventListener(event, resetTimeouts)
        );
      };
    },
    [clearTimeouts, resetTimeouts]
  );

  return (
    <Modal
      position="center"
      isShown={isWarningShowing}
    >
      <h5>{ct('timeOutModal.areYouThere')}</h5>
      <p>{ct('timeOutModal.ifRemainInactive')} <Countdown durationMs={msWarningToLogout} />, {ct('timeOutModal.startAnother')}</p>
      <p>{ct('timeOutModal.tapAnywhereToRemain')}</p>
    </Modal>
  );
};

export default TimeoutModal;
