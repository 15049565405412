import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as ordersSelectors } from 'ducks/orders';
import { actions as yumpingoActions, selectors as yumpingoSelectors } from 'ducks/yumpingo';
import startReview from 'effects/startYumpingoReview';
import useConfigurableText from 'hooks/useConfigurableText';
import { Modal } from 'components/Modal';
import { ReactComponent as SVGCross } from 'style/svg/Cross-mark.svg';


const YumpingoReviewModal: FC = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();

  const isShowing = useSelector(yumpingoSelectors.getIsModalShowing);
  const mostRecentOrder = useSelector(ordersSelectors.getMostRecentPastOrder);

  const ButtonRemindText = ct('yumpingo.buttons.remind');
  const ButtonReviewText = ct('yumpingo.buttons.review');

  return (
    <Modal
      position="center"
      isShown={mostRecentOrder && isShowing}
      buttons={[
        {
          label: ButtonRemindText,
          variant: 'secondary',
          onClick: () => {
            dispatch(yumpingoActions.setIsModalShowing(false));
            dispatch(yumpingoActions.setIsReminderModalShowing(true));
          },
        },
        {
          label: ButtonReviewText,
          onClick: () => {
            dispatch(yumpingoActions.setIsModalShowing(false));
            startReview(mostRecentOrder!.id);
          },
        },
      ]}
    >
      <button
        className="review-skip"
        onClick={() => dispatch(yumpingoActions.setIsModalShowing(false))}
      >
        <SVGCross />
      </button>
      <p>
        {ct('yumpingo.texts.workingHard')}<br />{ct('yumpingo.texts.pleaseHelp')}
      </p>
    </Modal>
  );
};

export default YumpingoReviewModal;
