import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { MenuItem } from 'types/model';
import { formatCurrency, formatText } from 'util/i18n';
import { getMenuItemImageSrc } from 'util/image';
import { selectors as settingsSelectors } from 'ducks/settings';
import Avatar from 'components/Avatar';

import './upsell.scss';


interface Props {
  readonly orderedItem: MenuItem;
  onClick?: () => void;
}

export const UpsellItemCard: FC<Props> = ({ orderedItem, onClick }) => {
  const currency = useSelector(settingsSelectors.getCurrency);

  const hasPhoto = orderedItem.hasPhoto;
  const image = getMenuItemImageSrc(orderedItem.id);
  const title = formatText(orderedItem.title);
  const price = formatCurrency(orderedItem.price, currency);

  return (
    <div
      className={cx('card cardUpsell', { hasAvatar: hasPhoto })}
      onClick={onClick}
    >
      { hasPhoto &&
        <Avatar src={image} alt={`${title} image`} />
      }
      <div className="card-box">
        <div className="dish">
          <div className="box">
            <div className="head">
              <h4 className="title">{title}</h4>
            </div>
            <div className="footer">
              {orderedItem.price !== 0 && <span className="price">{price}</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
