import React, { FC, MouseEvent, TouchEvent, useEffect } from 'react';

import { useScroll } from 'hooks/useScroll';

import './navigation.scss';


export interface NavigationData {
  key: string;
  title: string;
  active: boolean;
  onClick?: (event: MouseEvent| TouchEvent) => void;
}

const Button: FC<NavigationData> = ({ title, active, onClick }) => {
  const [listItemRef, scrollIntoView] = useScroll<HTMLLIElement>();
  useEffect(
    () => {
      if (active) {
        scrollIntoView();
      }
    },
    [active, scrollIntoView]
  );
  return (
    <li className={active ? 'active' : ''} ref={listItemRef}>
      <span onClick={onClick}>{title}</span>
    </li>
  );
};

interface NavigationProps {
  data: NavigationData[];
}

export const Navigation: FC<NavigationProps> = ({ data }) => (
  <div className="Navigation-box">
    <ul>
      {data.map(({ key, title, active, onClick }) => (
        <Button key={key} title={title} active={active} onClick={onClick} />
      ))}
    </ul>
  </div>
);
