import { Dispatch } from 'redux';
import io from 'socket.io-client';

import { APIDeviceAssignedCallbackParams } from 'types/api';
import { Tag, Voucher } from 'types/model';
import {
  AddOrderedItemPayload,
  AuthDevicePayload,
  AuthDeviceResponse,
  ChangeOrderedItemResponse,
  CloseDinerSessionPayload,
  CloseSessionResponse,
  CloseTakeAwaySessionPayload,
  ConnectDinerDevicePayload,
  ConnectDinerDeviceResponse,
  CreateVisitorPayload,
  DeviceAssignedPayload,
  DinerSessionOpenPayload,
  Event,
  FreeDinerDevicePayload,
  FreeDinerDeviceResponse,
  KioskSessionOpenPayload,
  OrdersChangedPayload,
  Payload,
  PaymentChangedPayload,
  RemoveOrderedItemPayload,
  Response,
  SendDinerOrderPayload,
  SendDinerOrderToWaiterPayload,
  SendEmailPayload,
  SendOrderResponse,
  SendUnpaidTakeAwayOrderPayload,
  SessionOpenResponse,
  UpdateDinerInfoPayload,
  UpdateDinerInfoResponse,
  UpdateOpenOrderScheduledTakeawayPayload,
  UpdateOrderVoucherPayload,
} from 'types/sockets';
import { debug, error } from 'util/log';
import { actions as sessionActions } from 'ducks/session';
import { actions as socketActions } from 'ducks/socket';
import { restaurantConfig } from 'config/restaurantConfig';


const { ordamoServerBase, apiPath, webSocketPath } = restaurantConfig;

export default class SocketManager {
  socket: SocketIOClient.Socket;

  constructor(dispatch: Dispatch, hasLostConnection: boolean) {
    this.socket = io.connect(
      ordamoServerBase,
      {
        path: `${apiPath}${webSocketPath}`,
        transports: ['websocket'],
        upgrade: false,
      }
    );
    this.socket.on('disconnect', (payload: string) => {
      debug('SocketManager - disconnect', payload);
      dispatch(socketActions.setConnected(false));
      if (payload !== 'io client disconnect') {
        dispatch(socketActions.setShowConnectionNotification(true));
        dispatch(socketActions.setHasLostConnection(true));
      }
      dispatch(sessionActions.setNeedsReauthentication(true));
    });

    this.socket.on('reconnect_error', (payload: string) => error('SocketManager - reconnect_error', payload));
    this.socket.on('connect_error', (payload: string) => error('SocketManager - connect_error', payload));

    this.socket.on('connect', (payload: string) => {
      debug('SocketManager - connect', payload);
      dispatch(socketActions.setConnected(true));
      if (hasLostConnection) {
        dispatch(socketActions.setShowConnectionNotification(true));
        dispatch(socketActions.setHasLostConnection(false));
      }
    });
    this.socket.on('connect_timeout', (payload: string) => debug('SocketManager - connect_timeout', payload));
    this.socket.on('disconnecting', (payload: string) => debug('SocketManager - disconnecting', payload));

    this.socket.on('reconnect_attempt', (payload: string) => debug('SocketManager - reconnect_attempt', payload));
    this.socket.on('reconnecting', (payload: string) => debug('SocketManager - reconnecting', payload));
    this.socket.on('reconnect_failed', (payload: string) => debug('SocketManager - reconnect_failed', payload));

    this.socket.on(
      Event.deviceToAssignV1,
      (_: {}, callback: (params: APIDeviceAssignedCallbackParams) => void) => callback({
        itemsUpdated: new Date('1970-01-01'),
        menuUpdated: new Date('1970-01-01'),
      })
    );

    this.socket.on(Event.restaurantNameUpdatedV1, (payload: string) => dispatch(socketActions.restaurantNameChanged(payload)));
    this.socket.on(Event.deviceAssignedV1, (payload: DeviceAssignedPayload) => dispatch(socketActions.deviceAssigned(payload)));
    this.socket.on(Event.deviceUnassignedV1, () => dispatch(socketActions.deviceUnassigned()));
    this.socket.on(Event.ordersChangedV1, (payload: OrdersChangedPayload) => dispatch(socketActions.ordersUpdated(payload)));
    this.socket.on(Event.waitingForWaiterApprovalV1, () => dispatch(socketActions.waitingForWaiterApproval()));
    this.socket.on(Event.sendOrderSuccessV1, () => dispatch(socketActions.sendOrderSuccess()));
    this.socket.on(Event.sendOrderFailureV1, () => dispatch(socketActions.sendOrderFailure()));
    this.socket.on(Event.paymentChangedV1, (payload: PaymentChangedPayload) => dispatch(socketActions.paymentChanged(payload)));
    this.socket.on(Event.tagsChangedV1, (payload: Tag[]) => dispatch(socketActions.tagsUpdated(payload)));
    this.socket.on(Event.voucherUpdatedV1, (payload: Voucher) => dispatch(socketActions.voucherUpdated(payload)));

    // LEFT HERE FOR DEBUGGING PURPOSES
    // for (const event of Object.values(Event)) {
    //   debug('SocketManager - listening for', event);
    //   this.socket.on(event, (payload: Payload) => {
    //     debug('SocketManager.on', event, payload);
    //   });
    // };
  }

  emit(event: Event.authKioskDeviceV1 | Event.authDinerDeviceV1, payload: AuthDevicePayload): Promise<AuthDeviceResponse>;
  emit(event: Event.createVisitorV1, payload: CreateVisitorPayload): Promise<Response>;
  emit(event: Event.kioskSessionOpenV1, payload: KioskSessionOpenPayload): Promise<SessionOpenResponse>;
  emit(event: Event.dinerSessionOpenV1, payload: DinerSessionOpenPayload): Promise<SessionOpenResponse>;
  emit(event: Event.sessionCloseV1, payload: CloseDinerSessionPayload): Promise<CloseSessionResponse>;
  emit(event: Event.takeAwaySessionCloseV1, payload: CloseTakeAwaySessionPayload): Promise<CloseSessionResponse>;
  emit(event: Event.connectDinerDeviceV1, payload: ConnectDinerDevicePayload): Promise<ConnectDinerDeviceResponse>;
  emit(event: Event.freeDinerDeviceV1, payload: FreeDinerDevicePayload): Promise<FreeDinerDeviceResponse>;
  emit(event: Event.addOrderItemWithModifiersV1, payload: AddOrderedItemPayload): Promise<ChangeOrderedItemResponse>;
  emit(event: Event.removeOrderItemsV1, payload: RemoveOrderedItemPayload): Promise<ChangeOrderedItemResponse>;
  emit(event: Event.updateOpenOrderScheduledTakeawayV1, payload: UpdateOpenOrderScheduledTakeawayPayload): Promise<Response>;
  emit(event: Event.sendDinerOrderV1, payload: SendDinerOrderPayload): Promise<SendOrderResponse>;
  emit(event: Event.sendDinerOrderToWaiterV1, payload: SendDinerOrderToWaiterPayload): Promise<SendOrderResponse>;
  emit(event: Event.sendUnpaidTakeAwayOrderV1, payload: SendUnpaidTakeAwayOrderPayload): Promise<SendOrderResponse>;
  emit(event: Event.sendEmailV1, payload: SendEmailPayload): Promise<Response>;
  emit(event: Event.updateDinerInfoV1, payload: UpdateDinerInfoPayload): Promise<UpdateDinerInfoResponse>;
  emit(event: Event.updateOrderVoucherV1, payload: UpdateOrderVoucherPayload): Promise<Response>;
  emit(event: Event, payload: Payload): Promise<Response> {
    return new Promise(
      (resolve, reject) => {
        if (!this.socket) {
          /* eslint-disable prefer-promise-reject-errors */
          reject('No socket connection.');
          /* eslint-enable prefer-promise-reject-errors */
        } else {
          this.socket.emit(event, payload, (response: Response) => {
            debug('SocketManager.emit', event, payload, response);
            if (response && response.error) {
              error('Error response from socket', response.error);
              reject(response.error);
            } else {
              resolve(response);
            }
          });
        }
      }
    );
  }

  close = () => {
    debug('SocketManager.close');
    this.socket.close();
  };
}
