import { useEffect, useState } from 'react';

import { APIPaymentCreateSessionPayload, Path } from 'types/api';
import { JudoPaySession } from 'types/judoPay';
import { canMakeApplePayPayments } from 'util/device';
import { error } from 'util/log';
import { post } from 'effects/api';


interface UseJudoPaySessionProps {
  deviceId?: number;
  sessionId?: number;
  uuid?: string;
}

export default ({ deviceId, sessionId, uuid }: UseJudoPaySessionProps) => {
  const [paymentSession, setPaymentSession] = useState<JudoPaySession>();

  useEffect(() => {
    let canceled = false;
    const createSession = async () => {
      if (deviceId && sessionId && uuid) {
        try {
          const session = await post(
            Path.judoPayPaymentCreateSession,
            {
              deviceId,
              sessionId,
              uuid,
            } as APIPaymentCreateSessionPayload
          );

          if (!canceled) {
            setPaymentSession({
              ...session,
              deviceId,
              sessionId,
              uuid,
            });
          }
        } catch (e) {
          error('Judo Pay: Session creation failed.', e);
        }
      }
    };

    if (canMakeApplePayPayments()) {
      createSession();
    }

    return () => {
      canceled = true;
    };
  }, [deviceId, sessionId, uuid]);

  return paymentSession;
};
