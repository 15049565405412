import React, { FC, MouseEvent, TouchEvent } from 'react';
import cx from 'classnames';

import { ButtonSize, ButtonType, ButtonVariant } from './buttonTypes';

import './button.scss';


export type OnClickHandler = (event: MouseEvent | TouchEvent) => void;

export interface ButtonProps {
  id?: string;
  label?: string;
  form?: string;
  type: ButtonType;
  variant: ButtonVariant;
  size: ButtonSize;
  className?: string;
  disabled?: boolean;
  onClick?: OnClickHandler;
}

export const Button: FC<ButtonProps> = ({ id, label, form, type, variant, size, className, children, disabled, onClick }) => (
  <button id={id} form={form} disabled={disabled} className={cx(`btn-${variant}`, `btn-${size}`, className)} type={type} onClick={onClick}>
    {label}
    {children}
  </button>
);
