import React, { FC, useCallback, useMemo, useState } from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { ErrorCode } from 'constants/errorCodes';
import { routePath } from 'constants/routes';
import { getOrderDisplayNr } from 'util/getOrderDisplayNumber';
import { selectors as ordersSelectors } from 'ducks/orders';
import { selectors as sessionSelectors } from 'ducks/session';
import closeSession from 'effects/closeSession';
import { useWebSocket } from 'contexts/SocketManagerContext';
import useConfigurableText from 'hooks/useConfigurableText';
import useDisableTimeoutModal from 'hooks/useDisableTimeoutModal';
import useParamId from 'hooks/useParamId';
import ConfirmModal from 'containers/ConfirmModal';
import OrderSummary from 'containers/OrderSummary';
import { Button } from 'components/Button';
import { Header } from 'components/Header';
import { ReactComponent as SVGPaymentError } from 'style/svg/payment-error.svg';
import { ReactComponent as SVGOrderError } from 'style/svg/smileSad.svg';

import { ErrorInfo } from './ErrorInfo';


const ContactStaff: FC = () => {
  useDisableTimeoutModal();

  const ct = useConfigurableText();
  const dispatch = useDispatch();
  const webSocket = useWebSocket();

  const dineIn = useSelector(sessionSelectors.getDineIn);
  const sessionId = useSelector(sessionSelectors.getSessionId);
  const currentOrder = useSelector(ordersSelectors.getCurrentOrder);
  const orderDisplayNumber = getOrderDisplayNr(currentOrder?.id);

  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const onConfirm = useCallback(() => {
    setIsConfirmOpen(false);
    if (sessionId) {
      closeSession(dispatch, webSocket, dineIn, sessionId);
    }
  }, [dispatch, webSocket, dineIn, sessionId]);

  const errorId = useParamId();
  const failureHeader = useMemo(() => {
    switch (errorId) {
      case ErrorCode.SendOrderFailure:
        return <Header hideLogo title={ct('contactStaffScreen.sendOrderFailure.title')} />;
      case ErrorCode.UnknownPaymentError:
        return <Header hideLogo title={ct('contactStaffScreen.paymentFailure.title')} />;
      default:
        return null;
    }
  }, [errorId, ct]);

  const failureInfo = useMemo(() => {
    switch (errorId) {
      case ErrorCode.SendOrderFailure:
        return (
          <ErrorInfo
            head={<SVGOrderError />}
            message={ct('contactStaffScreen.sendOrderFailure.message')}
          />
        );
      case ErrorCode.UnknownPaymentError:
        return (
          <ErrorInfo
            head={<SVGPaymentError />}
            message={ct('contactStaffScreen.paymentFailure.message')}
          />
        );
      default:
        return null;
    }
  }, [errorId, ct]);

  if (!failureInfo || !failureHeader) {
    return <Redirect to={routePath.notFound} />;
  }

  return (
    <div className={cx('contactStaff', 'container-fixed')}>
      {failureHeader}
      <section>
        {failureInfo}
        {currentOrder && orderDisplayNumber
          ? (
            <OrderSummary
              orderNumber={orderDisplayNumber}
              orderedItems={currentOrder.orderedItems}
              isOpen={isDrawerOpened}
              unpaidTipAmount={currentOrder.tipAmount}
              onClose={() => setIsDrawerOpened(false)}
            />
          ) : null}
        <div className="buttons">
          <Button
            type="button"
            label={ct('contactStaffScreen.closeSession')}
            variant="secondary"
            size="medium"
            onClick={() => setIsConfirmOpen(true)}
          />
          <Button
            type="button"
            label={ct('shared.labels.showOrder')}
            variant="primary"
            size="medium"
            onClick={() => setIsDrawerOpened(true)}
          />
        </div>
      </section>
      <ConfirmModal
        onCancel={() => setIsConfirmOpen(false)}
        onConfirm={onConfirm}
        title={ct('contactStaffScreen.confirmSessionClose.title')}
        message={ct('contactStaffScreen.confirmSessionClose.message')}
        showModal={isConfirmOpen}
      />
    </div>
  );
};

export default ContactStaff;
