import { combineReducers } from 'redux';
import { fork } from 'redux-saga/effects';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { I18nState, initialState as i18n, reducer as i18nReducer } from './i18n';
import { initialState as menu, MenuState, reducer as menuReducer } from './menu';
import { initialState as menuSection, MenuSectionState, reducer as menuSectionReducer } from './menuSection';
import { initialState as orders, OrdersState, reducer as ordersReducer } from './orders';
import { initialState as payment, PaymentState, reducer as paymentReducer } from './payment';
import { initialState as schedule, reducer as scheduleReducer, ScheduleState } from './schedule';
import { initialState as session, reducer as sessionReducer, SessionState } from './session';
import { initialState as settings, reducer as settingsReducer, SettingsState } from './settings';
import { createSagas as socketSaga, initialState as socket, reducer as socketReducer, SocketState } from './socket';
import { initialState as tables, reducer as tablesReducer, TablesState } from './tables';
import { initialState as tags, reducer as tagsReducer, TagsState } from './tags';
import { initialState as taxRates, reducer as taxRatesReducer, TaxRatesState } from './taxRates';
import { initialState as vouchers, reducer as vouchersReducer, VouchersState } from './vouchers';
import { initialState as yumpingo, reducer as yumpingoReducer, YumpingoState } from './yumpingo';


export interface RootState {
  i18n: I18nState;
  menu: MenuState;
  menuSection: MenuSectionState;
  orders: OrdersState;
  payment: PaymentState;
  schedule: ScheduleState;
  session: SessionState;
  settings: SettingsState;
  tables: TablesState;
  tags: TagsState;
  taxRates: TaxRatesState;
  vouchers: VouchersState;
  yumpingo: YumpingoState;
  socket: SocketState;
}

export const initialState: RootState = {
  i18n,
  menu,
  menuSection,
  orders,
  payment,
  schedule,
  session,
  settings,
  tables,
  tags,
  taxRates,
  vouchers,
  yumpingo,
  socket,
};

export const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  i18n: i18nReducer,
  menu: menuReducer,
  menuSection: menuSectionReducer,
  orders: ordersReducer,
  payment: paymentReducer,
  schedule: scheduleReducer,
  session: sessionReducer,
  settings: settingsReducer,
  tables: tablesReducer,
  tags: tagsReducer,
  taxRates: taxRatesReducer,
  vouchers: vouchersReducer,
  yumpingo: yumpingoReducer,
  socket: socketReducer,
});

export function* rootSaga() {
  yield fork(socketSaga());
}
