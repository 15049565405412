import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions as sessionActions } from 'ducks/session';


export default () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sessionActions.setTimeoutModalEnabled(false));
    return () => {
      dispatch(sessionActions.setTimeoutModalEnabled(true));
    };
  }, [dispatch]);
};
