
import { useDispatch, useSelector } from 'react-redux';

import { actions as yumpingoActions, selectors as yumpingoSelectors } from 'ducks/yumpingo';
import useQuery from 'hooks/useQuery';


export default () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const reviewFinished = query.get('reviewFinished');

  const isYumpingoActive = useSelector(yumpingoSelectors.getIsEnabled) && !reviewFinished;
  const shouldShowYumpingoModal = useSelector(yumpingoSelectors.getShouldShowModal);

  const yumpingoOnFirstClick = (secondClickAction: () => void) => {
    if (isYumpingoActive && shouldShowYumpingoModal) {
      dispatch(yumpingoActions.setIsPromptShowing(false));
      dispatch(yumpingoActions.setShouldShowModal(false));
      dispatch(yumpingoActions.setIsModalShowing(true));
    } else {
      secondClickAction();
    }
  };

  return [isYumpingoActive, yumpingoOnFirstClick] as [boolean, ((secondClickAction: () => void) => void)];
};
