import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';

import { selectors as ordersSelectors } from 'ducks/orders';
import { selectors as scheduleSelectors } from 'ducks/schedule';
import useConfigurableText from 'hooks/useConfigurableText';
import { PickUpBox } from 'components/PickUpBox';


const TakeawayScheduleSelect: FC = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();
  const preparationTime = useSelector(scheduleSelectors.getPreparationTime);
  const schedule = useSelector(ordersSelectors.getSchedule);
  const asapReadyTime = useSelector(scheduleSelectors.getAsapReadyTime);

  const goToAppointTime = () => dispatch(push('/appoint-time'));

  const scheduledDate = schedule && schedule.scheduleTimestamp && moment.utc(schedule.scheduleTimestamp).toDate();

  return (
    <>
      <PickUpBox
        title={ct('takeawayScheduleSelect.collectionTime')}
        label={ct('takeawayScheduleSelect.scheduleTime')}
        onClick={goToAppointTime}
      >
        { scheduledDate
          ? ct('takeawaySchedulingOrders.texts.dateAtTime', {
            date: scheduledDate.toLocaleDateString(),
            time: scheduledDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          })
          : asapReadyTime && ct('takeawayScheduleSelect.pickupIn', { preparationTime })
        }
      </PickUpBox>
    </>
  );
};

export default TakeawayScheduleSelect;
