import { Dispatch } from 'redux';

import { Event } from 'types/sockets';
import { isDineIn } from 'util/dineIn';
import { actions as sessionActions } from 'ducks/session';

import SocketManager from './socketManager';


export default async (dispatch: Dispatch, webSocket: SocketManager, identification: string) => {
  const dineInDevice = isDineIn(identification);
  const { id: deviceId } = await webSocket.emit(
    dineInDevice ? Event.authDinerDeviceV1 : Event.authKioskDeviceV1,
    { identification }
  );
  dispatch(sessionActions.setIdentification(identification));
  dispatch(sessionActions.setDeviceId(deviceId));
};
