import { Modifier, ModifierOption, ModifierType, ModifierValue, OrderedItem } from 'types/model';
import { Event } from 'types/sockets';
import { error } from 'util/log';

import SocketManager from './socketManager';


const getModifierValue = (modifier: Modifier, value: ModifierValue) => {
  if (modifier.type === ModifierType.text) {
    return value as string;
  }
  return (value as ModifierOption[]).map((option: ModifierOption) => option.id);
};

export default async (
  webSocket: SocketManager,
  sessionId: number,
  deviceId: number,
  dineIn: boolean,
  { menuItem, orderedModifiers }: OrderedItem,
  quantity = 1,
): Promise<number> => {
  const modifierValues = orderedModifiers
    ? orderedModifiers.reduce(
      (accumulatedValues, { modifier, value }) => ({
        ...accumulatedValues,
        [modifier.id]: getModifierValue(modifier, value),
      }),
      {}
    ) : {};

  let actualChange = 0;
  while (actualChange < quantity) {
    try {
      actualChange += (await webSocket.emit(
        Event.addOrderItemWithModifiersV1,
        {
          sessionId,
          deviceId,
          dineIn,
          itemId: menuItem.id,
          nodeId: menuItem.nodeId,
          modifierValues,
          quantity,
        }
      )).change;
    } catch (err) {
      error(
        'Unable to add item to order',
        err,
        sessionId,
        deviceId,
        menuItem.id,
        menuItem.nodeId,
        modifierValues,
        `${actualChange} of ${quantity}`
      );
      break;
    }
  }
  return actualChange;
};
