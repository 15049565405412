import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { Path } from 'types/api';
import { formatCurrency } from 'util/i18n';
import { selectors as ordersSelectors } from 'ducks/orders';
import { selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import { post } from 'effects/api';
import ServerActionButton from 'containers/ServerActionButton';


const MockPaymentScreen: FC = () => {
  const [failPayment, setFailPayment] = useState(false);
  const userInfo = useSelector(sessionSelectors.getUserInfo);
  const note = useSelector(sessionSelectors.getNote);
  const sessionId = useSelector(sessionSelectors.getSessionId);
  const deviceId = useSelector(sessionSelectors.getDeviceId);
  const orderTotal = useSelector(ordersSelectors.getCurrentOrderTotal);
  const tipAmount = useSelector(ordersSelectors.getServiceChargeAmount);
  const currency = useSelector(settingsSelectors.getCurrency);

  const handleClick = async () => {
    if (sessionId && deviceId) {
      const { paymentId } = await post(Path.mockPaymentStart, { sessionId, deviceId, tipAmount, userInfo, note });
      await post(Path.mockPaymentFinish, { paymentId, fail: failPayment });
    }
  };

  return (
    <div className="PageSimple">
      <div className="container">
        <div className="body">
          <label htmlFor="failPayment">
            Fail payment
            <input id="failPayment" type="checkbox" checked={failPayment} onChange={e => setFailPayment(e.currentTarget.checked)} />
          </label>
        </div>
        <ServerActionButton
          label={`Pay ${formatCurrency(orderTotal, currency)}`}
          type="button"
          variant="primary"
          size="large"
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default MockPaymentScreen;
