import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { Voucher } from 'types/model';
import { formatCurrency } from 'util/i18n';
import { getUniqueKey, getUniqueOrderedItems, sortOrderedItems } from 'util/orderedItems';
import { getMenuHomePath } from 'util/path';
import { getDineInBypassPaymentType } from 'util/paymentBypass';
import { selectors as menuSelectors } from 'ducks/menu';
import { selectors as ordersSelectors } from 'ducks/orders';
import { actions as paymentActions, selectors as paymentSelectors } from 'ducks/payment';
import { selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import { selectors as vouchersSelectors } from 'ducks/vouchers';
import sendDinerOrder from 'effects/sendDinerOrder';
import updateOrderVoucher from 'effects/updateOrderVoucher';
import { useWebSocket } from 'contexts/SocketManagerContext';
import useConfigurableText from 'hooks/useConfigurableText';
import DineInUserHelp from 'containers/DineInUserHelp';
import { DinerInfoButton } from 'containers/DinerInfoButton';
import MenuItemDetails from 'containers/MenuItemDetails';
import ServerActionButton from 'containers/ServerActionButton';
import ServiceChargeCard from 'containers/ServiceChargeCard';
import { UpsellList } from 'containers/UpsellList';
import { Header } from 'components/Header';
import { InputWithButton } from 'components/InputWithButton';
import { Modal } from 'components/Modal';
import { OrderedItemCard } from 'components/OrderedItemCard';
import { ReactComponent as CrossIcon } from 'style/svg/cross.svg';


const OrderScreen: FC = () => {
  const dispatch = useDispatch();
  const webSocket = useWebSocket();
  const ct = useConfigurableText();
  const [orderSent, setOrderSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [voucherCode, setVoucherCode] = useState('');
  const [showVoucherModal, setShowVoucherModal] = useState(false);
  const prevVoucher = useRef<Voucher | null>();

  const menus = useSelector(menuSelectors.getSelectableMenus);
  const sessionId = useSelector(sessionSelectors.getSessionId)!;
  const deviceId = useSelector(sessionSelectors.getDeviceId);
  const dineIn = useSelector(sessionSelectors.getDineIn);
  const takeAway = useSelector(sessionSelectors.getTakeAway);
  const orderedItems = useSelector(ordersSelectors.getCurrentOrderedItems);
  const orderTotal = useSelector(ordersSelectors.getCurrentOrderTotal);
  const orderThreshold = useSelector(settingsSelectors.getOrderThreshold);
  const currency = useSelector(settingsSelectors.getCurrency);
  const smsNotificationDineIn = useSelector(settingsSelectors.getSmsNotificationDineIn);
  const isPaymentDisabled = useSelector(settingsSelectors.getIsPaymentDisabled);
  const isServiceChargeActive = useSelector(settingsSelectors.getIsServiceChargeActive);
  const isPaymentInProgress = useSelector(paymentSelectors.getIsPaymentInProgress);
  const goesWithItems = useSelector(ordersSelectors.getGoesWithItems);
  const schedule = useSelector(ordersSelectors.getSchedule);
  const vouchers = useSelector(vouchersSelectors.getAllVouchers);
  const voucher = useSelector(ordersSelectors.getCurrentVoucher);
  const discount = useSelector(ordersSelectors.getCurrentDiscount);

  const hasSchedule = !!schedule;
  const isOrderFree = orderTotal === 0;
  const dineInPaymentBypassType = getDineInBypassPaymentType(isPaymentDisabled, isOrderFree);

  const orderButtonLabelI18t = useMemo(
    () => {
      if (isPaymentInProgress) {
        return 'orderScreen.button.paymentInProgress';
      }
      if (dineIn && dineInPaymentBypassType) {
        return 'orderScreen.button.sendOrder';
      }
      if (takeAway && !hasSchedule) {
        return isOrderFree
          ? 'orderScreen.button.scheduleSendOrder'
          : 'orderScreen.button.schedulePay';
      }
      return isOrderFree
        ? 'orderScreen.button.confirmSendOrder'
        : 'orderScreen.button.confirmPay';
    },
    [dineIn, takeAway, isPaymentInProgress, dineInPaymentBypassType, hasSchedule, isOrderFree]
  );
  const orderButtonLabel = ct(orderButtonLabelI18t);

  const orderButtonDisabled = orderedItems.length === 0 || orderSent || isPaymentInProgress;

  // iOS Safari bug scroll require different height on body tag
  useEffect(() => {
    document.body.classList.add('Order-drawer');
    return () => {
      document.body.className = '';
    };
  });

  useEffect(() => {
    setVoucherCode('');
    if (prevVoucher.current === null && voucher) {
      setShowVoucherModal(true);
    }
    prevVoucher.current = voucher;
  }, [voucher]);

  const handleSendOrPay = async () => {
    if (takeAway) {
      if (schedule) {
        dispatch(push('/order-details'));
      } else {
        dispatch(push('/appoint-time', { continueTo: '/order-details' }));
      }
    } else {
      setOrderSent(true);
      if (dineInPaymentBypassType) {
        const bypassWaiter = orderThreshold === -1 || orderTotal < orderThreshold;
        dispatch(paymentActions.setIsVatReceiptAllowed(false));
        await sendDinerOrder(webSocket, bypassWaiter, sessionId, deviceId, dineInPaymentBypassType);
      } else if (smsNotificationDineIn) {
        dispatch(push('/phone-number'));
      } else {
        dispatch(paymentActions.setIsVatReceiptAllowed(true));
        dispatch(push('/payment'));
      }
    }
  };

  const handleVoucherCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVoucherCode(event.target.value);
    setErrorMessage('');
  };

  const handleVoucherSubmit = async () => {
    let voucherId = null;
    vouchers.forEach((v) => {
      if (v.name === voucherCode && v.active) {
        voucherId = v.id;
      }
    });

    if (voucherId) {
      await updateOrderVoucher(webSocket, sessionId, voucherId);
      setErrorMessage('');
    } else {
      setErrorMessage('Couldn\'t find promo code');
    }
  };

  return (
    <div className="container-fixed Order">
      <Header
        hideLogo
        title={ct('orderScreen.basket')}
        backTo={getMenuHomePath(menus)}
        buttons={dineIn && <DinerInfoButton />}
      />
      <div className="Order-container">
        {goesWithItems.length > 0 && <UpsellList items={goesWithItems} />}
        <h3>{ct('orderScreen.orderedItems')}</h3>
        {sortOrderedItems(getUniqueOrderedItems(orderedItems))
          .map(orderedItem => (
            <OrderedItemCard key={getUniqueKey(orderedItem)} orderedItem={orderedItem} currentlyOrderedItems={orderedItems} />
          ))
        }

        <div className="discountBox">
          { voucher ? (
            <div className="card">
              <div className="card-box">
                <div className="dish">
                  <h4 className="title">
                    {ct('voucher.Discount')}
                    <small>{voucher.description || ''}</small>
                  </h4>
                  <div>
                    <span className="price">
                      {formatCurrency(-discount, currency)}
                    </span>
                    <button className="btn-close" onClick={() => updateOrderVoucher(webSocket, sessionId, undefined)}>
                      <CrossIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <InputWithButton
              className={errorMessage && 'hasError'}
              title={ct('voucher.promoCode')}
              placeholder={ct('voucher.enterPromoCode')}
              label={ct('voucher.submit')}
              id="discount1"
              error={errorMessage &&
                <span className="error-message">{errorMessage}</span>
              }
              onChange={handleVoucherCodeChange}
              onClick={handleVoucherSubmit}
            />
          )}
        </div>

        <Modal
          position="center"
          className="text-left discountModal"
          isShown={showVoucherModal}
          buttons={[
            { label: ct('voucher.OkGotIt'),
              type: 'button',
              variant: 'oval',
              size: 'medium',
              onClick: () => setShowVoucherModal(false),
            },
          ]}
        >
          <h5 className="text-center">
            {ct('voucher.DiscountSuccessfull')}
          </h5>
        </Modal>

        {isServiceChargeActive && <ServiceChargeCard />}
        <DineInUserHelp />
      </div>
      <div className="OrderTotal">
        <MenuItemDetails />
        <ServerActionButton
          label={`${orderButtonLabel} ${formatCurrency(orderTotal, currency)}`}
          type="button"
          variant="primary"
          size="large"
          disabled={orderButtonDisabled}
          onClick={handleSendOrPay}
        />
      </div>
    </div>
  );
};

export default OrderScreen;
