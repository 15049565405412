export const DINE_IN_PREFIX = 'web_diner_';
export const TAKEAWAY_PREFIX = 'web_kiosk_';

export const isDineIn = (identification?: string) =>
  !!(identification?.startsWith(DINE_IN_PREFIX));

export const isTakeAway = (identification?: string) =>
  !!(identification?.startsWith(TAKEAWAY_PREFIX));

export const createIdentification = (dineIn?: boolean) =>
  `${dineIn ? DINE_IN_PREFIX : TAKEAWAY_PREFIX}${Date.now()}`;
