import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import { TextBox } from 'components/TextBox';


const DineInUserHelp: FC = () => {
  const dineInUserHelp = useSelector(settingsSelectors.getDineInUserHelp);
  const dineIn = useSelector(sessionSelectors.getDineIn);

  return dineIn && dineInUserHelp
    ? <TextBox>{dineInUserHelp}</TextBox>
    : null;
};

export default DineInUserHelp;
