import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import useConfigurableText from 'hooks/useConfigurableText';
import { Button } from 'components/Button';


const ErrorScreen: FC = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();

  return (
    <div className="PageSimple">
      <div className="container">
        <div className="head">
          <h2>{ct('shared.titles.oops')}</h2>
        </div>
        <div className="body">
          <p>{ct('shared.labels.error')}</p>
        </div>
        <div className="buttons">
          <Button
            type="button"
            label={ct('shared.labels.tryAgain')}
            variant="primary"
            size="large"
            onClick={() => dispatch(push('/'))}
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
