import {
  all, call,
  put, select, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/types';
import { push } from 'connected-react-router';
import { ActionType, createAction, getType } from 'typesafe-actions';

import {
  APIItemNode,
  APIMenu,
  APIMenuItem,
  APIMenuNode,
  APIMenuSection,
  APIMenuSectionChanges,
  APIModifier,
  APIModifierOption,
  APIModifierValues,
  APIOrder,
  APIOrderItem,
  instanceOfAPIItemNode,
} from 'types/api';
import {
  ChoiceModifier,
  DiningModeName,
  Menu,
  MenuItem,
  Modifier,
  ModifierOption,
  ModifierType,
  ModifierValue,
  OrderedItem,
  OrderedModifier,
  PaymentStatus,
  Section,
  Tag,
  TagType,
  Voucher,
} from 'types/model';
import { ApplicationTextPayload, DeviceAssignedPayload, Event, OrdersChangedPayload, PaymentChangedPayload } from 'types/sockets';
import { ErrorCode } from 'constants/errorCodes';
import { routePath } from 'constants/routes';
import { createIdentification } from 'util/dineIn';
import { isNotEmpty } from 'util/guards';
import { debug } from 'util/log';
import { RootState } from 'ducks';
import { actions as i18nActions } from 'ducks/i18n';
import { actions as menuActions } from 'ducks/menu';
import { actions as menuSectionActions, selectors as menuSectionSelectors } from 'ducks/menuSection';
import { actions as ordersActions } from 'ducks/orders';
import { actions as paymentActions } from 'ducks/payment';
import { actions as scheduleActions } from 'ducks/schedule';
import { actions as sessionActions, selectors as sessionSelectors } from 'ducks/session';
import { actions as settingsActions, ORDER_THRESHOLD_DISABLED } from 'ducks/settings';
import { actions as tablesActions } from 'ducks/tables';
import { actions as tagsActions } from 'ducks/tags';
import { actions as taxRatesActions } from 'ducks/taxRates';
import { actions as vouchersActions } from 'ducks/vouchers';
import { actions as yumpingoActions } from 'ducks/yumpingo';


export interface SocketState {
  connected: boolean;
  hasLostConnection: boolean;
  showConnectionNotification: boolean;
}

// initial state
export const initialState: SocketState = {
  connected: false,
  hasLostConnection: false,
  showConnectionNotification: false,
};

// Action Creators
export const actions = {
  restaurantNameChanged: createAction('socket/restaurantNameChanged')<string>(),
  deviceAssigned: createAction('socket/deviceAssigned')<DeviceAssignedPayload>(),
  deviceUnassigned: createAction('socket/deviceUnassigned')(),
  ordersUpdated: createAction('socket/ordersUpdated')<OrdersChangedPayload>(),
  paymentChanged: createAction('socket/paymentChanged')<PaymentChangedPayload>(),
  waitingForWaiterApproval: createAction('socket/waitingForWaiterApproval')(),
  sendOrderSuccess: createAction('socket/sendOrderSuccess')(),
  sendOrderFailure: createAction('socket/sendOrderFailure')(),
  tagsUpdated: createAction('socket/tagsUpdated')<Tag[]>(),
  setConnected: createAction('socket/setConnected')<boolean>(),
  setHasLostConnection: createAction('socket/hasLostConnection')<boolean>(),
  setShowConnectionNotification: createAction('socket/setShowConnectionNotification')<boolean>(),
  voucherUpdated: createAction('socket/voucherUpdated')<Voucher>(),
  posConnected: createAction('socket/posConnected')<boolean>(),
};

export type Actions = ActionType<typeof actions>;

// selectors
const isConnected = (state: RootState) => state.socket.connected;
const hasLostConnection = (state: RootState) => state.socket.hasLostConnection;
const shouldShowConnectionNotification = (state: RootState) => state.socket.showConnectionNotification;

export const selectors = {
  isConnected,
  hasLostConnection,
  shouldShowConnectionNotification,
};

// reducer
const setConnected = (state: SocketState, connected: boolean): SocketState => ({
  ...state,
  connected,
});

const setHasLostConnection = (state: SocketState, lostConnection: boolean): SocketState => ({
  ...state,
  hasLostConnection: lostConnection,
});

const setShowConnectionNotification = (state: SocketState, showConnectionNotification: boolean): SocketState => ({
  ...state,
  showConnectionNotification,
});

export const reducer = (state: SocketState = initialState, action: Actions): SocketState => {
  switch (action.type) {
    case getType(actions.setConnected):
      return setConnected(state, action.payload);
    case getType(actions.setHasLostConnection):
      return setHasLostConnection(state, action.payload);
    case getType(actions.setShowConnectionNotification):
      return setShowConnectionNotification(state, action.payload);
    default:
      return state;
  }
};

// Sagas
const mapTags = (tags: Tag[], tagIds: number[], tagType: TagType) => tagIds
  .reduce<Tag[]>(
  (allergens, tagId) => {
    const matchingTag = tags.find(tag => tag.id === tagId && tag.tagType === tagType);
    return matchingTag ? allergens.concat(matchingTag) : allergens;
  }, []
);

const mapModifierOptions = (options: APIModifierOption[], apiMenuItems: APIMenuItem[], tags: Tag[]): ModifierOption[] => options && options
  .reduce<ModifierOption[]>(
  (modifierOptions, apiOption) => {
    const item = apiMenuItems.find(apiMenuItem => apiMenuItem.id === apiOption.menuItemId);
    if (!item || apiOption.deleted) return modifierOptions;
    return modifierOptions.concat({
      id: apiOption.id,
      price: apiOption.price !== null ? apiOption.price : (item.price || 0),
      title: apiOption.title || item.title,
      rank: apiOption.rank,
      available: item.available,
      itemPosId: item.posId,
      allergens: mapTags(tags, item.tags, TagType.allergen),
      filters: mapTags(tags, item.tags, TagType.filter),
    });
  },
  []
);

const mapModifiers = (menuItem: APIMenuItem, modifiers: Modifier[]): Modifier[] => menuItem.modifiers
  .reduce<Modifier[]>(
  (mappedModifiers, id) => {
    const modifier = modifiers.find(mod => mod.id === id);
    return modifier ? mappedModifiers.concat(modifier) : mappedModifiers;
  },
  []
);

const mapMenuItem = (
  { itemId, nodeId, price, rank }: APIItemNode, menuItem: APIMenuItem, modifiers: Modifier[], tags: Tag[]
): MenuItem => ({
  id: itemId,
  nodeId,
  title: menuItem.title,
  description: menuItem.description,
  price: price ?? menuItem.price,
  hasPhoto: menuItem.hasPhoto,
  modifiers: mapModifiers(menuItem, modifiers),
  allergens: mapTags(tags, menuItem.tags, TagType.allergen),
  filters: mapTags(tags, menuItem.tags, TagType.filter),
  available: menuItem.available,
  rank,
  upsell: menuItem.upsell,
  pairs: menuItem.pairs,
  dineInTaxId: menuItem.dineInTaxId,
  takeAwayTaxId: menuItem.takeAwayTaxId,
  posId: menuItem.posId,
});

const mapMenuItems = (nodes: APIMenuNode[], menuItems: MenuItem[]) => nodes
  .filter(child => instanceOfAPIItemNode(child))
  .map(child => child as APIItemNode)
  .reduce<MenuItem[]>(
  (mappedMenuItems, apiItemNode) => {
    const mappedItems = menuItems.reduce<MenuItem[]>(
      (mapped, menuItem) => menuItem.nodeId === apiItemNode.nodeId
        ? mapped.concat(menuItem)
        : mapped,
      []
    );
    return mappedItems.length > 0 ? mappedMenuItems.concat(...mappedItems) : mappedMenuItems;
  },
  []
);

const mapSection = ({ nodeId, title, children }: APIMenuNode, menuItems: MenuItem[]): Section => ({
  id: nodeId,
  title,
  hasPhoto: false,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  subSections: children ? mapSections(children, menuItems) : [],
  menuItems: children ? mapMenuItems(children, menuItems) : [],
});

const mapSections = (nodes: APIMenuNode[], menuItems: MenuItem[]) => nodes
  .filter(child => !instanceOfAPIItemNode(child))
  .reduce<Section[]>(
  (subSections, apiMenuNode) => subSections.concat(mapSection(apiMenuNode, menuItems)),
  []
);

const mapMenuSection = ({ nodeId, title, description, hasPhoto, children }: APIMenuSection, menuItems: MenuItem[]) => ({
  id: Number(nodeId.substr(1)), // See Ordamo common/helpers/createMenuNodeId.js
  title,
  description,
  hasPhoto,
  subSections: children ? mapSections(children, menuItems) : [],
  menuItems: children ? mapMenuItems(children, menuItems) : [],
});

const mapMenu = ({
  menuSections,
  ...other
}: APIMenu): Menu => ({
  ...other,
  sectionIds: menuSections
    .slice()
    .sort((sectionOne, sectionTwo) => (sectionOne.rank - sectionTwo.rank))
    .map(({ menuSectionId }) => menuSectionId),
});

const modifierOptionIdToOption = (
  optionId: number, options: ModifierOption[]
): ModifierOption | undefined => options.find(option => option.id === optionId);

const findModifierValue = (modifier: Modifier, rawValue: string | number[]): ModifierValue | undefined => {
  if (modifier.type === ModifierType.text) {
    return rawValue as string;
  }
  const { options } = modifier as ChoiceModifier;
  const optionIds = rawValue as number[];
  return optionIds.reduce<ModifierOption[]>((values, optionId) => {
    const value = modifierOptionIdToOption(optionId, options);
    return value ? values.concat(value) : values;
  }, []);
};

const flattenNodes = (apiMenuNodes: APIMenuNode[]): APIMenuNode[] => apiMenuNodes
  .reduce(
    (nodes, apiMenuNode) => apiMenuNode.children
      ? nodes.concat(flattenNodes(apiMenuNode.children))
      : nodes,
    apiMenuNodes
  );

const flattenMenuSections = (apiMenuSections: APIMenuSection[]) => apiMenuSections
  .reduce<APIMenuNode[]>(
  (allNodes, menuSection) => menuSection.children
    ? allNodes.concat(flattenNodes(menuSection.children))
    : allNodes,
  []
);

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* restaurantNameChanged({ payload }: ReturnType<typeof actions.restaurantNameChanged>) {
    debug('SocketManager.on', Event.restaurantNameUpdatedV1, payload);
    yield put(settingsActions.setRestaurantName(payload));
  }

  function* setModifiers(apiMenuItems: APIMenuItem[], apiModifiers: APIModifier[], tags: Tag[]) {
    const modifiers = apiModifiers
      .filter((modifier) => !modifier.deleted)
      .map(modifier => modifier.modifierType === ModifierType.text
        ? {
          id: modifier.id,
          title: modifier.title,
          description: modifier.text,
          type: ModifierType.text,
          rank: modifier.rank,
        } : {
          id: modifier.id,
          title: modifier.title,
          description: modifier.text,
          type: ModifierType.choice,
          subType: modifier.subtype,
          options: mapModifierOptions(modifier.options, apiMenuItems, tags),
          rank: modifier.rank,
          minChoices: modifier.minChoices,
          maxChoices: modifier.maxChoices,
        } as ChoiceModifier
      );
    yield put(menuSectionActions.setModifiers(new Set(modifiers)));
    return modifiers;
  }

  function* setMenuItems(
    nodes: APIMenuNode[], apiMenuItems: APIMenuItem[], modifiers: Modifier[], tags: Tag[]
  ) {
    const menuItems = nodes
      .filter(child => instanceOfAPIItemNode(child))
      .map(child => child as APIItemNode)
      .reduce<MenuItem[]>(
      (accumulatedMenuItems, apiItemNode) => {
        const matchingApiMenuItems = apiMenuItems.reduce<APIMenuItem[]>(
          (matchingItems, apiMenuItem) => apiMenuItem.id === apiItemNode.itemId
            ? matchingItems.concat(apiMenuItem)
            : matchingItems,
          []
        );
        return matchingApiMenuItems.length > 0
          ? accumulatedMenuItems.concat(matchingApiMenuItems.map(apiMenuItem => mapMenuItem(
            apiItemNode,
            apiMenuItem,
            modifiers,
            tags,
          )))
          : accumulatedMenuItems;
      },
      []
    );
    yield put(menuSectionActions.setMenuItems(new Set(menuItems)));
    return menuItems;
  }

  function* setMenuSections(
    apiMenuSectionChanges: APIMenuSectionChanges, apiMenuItems: APIMenuItem[], apiModifiers: APIModifier[], tags: Tag[]
  ): SagaIterator {
    const modifiers = yield call(setModifiers, apiMenuItems, apiModifiers, tags);

    const allNodes = flattenMenuSections(apiMenuSectionChanges.insert);
    const menuItems = yield call(setMenuItems, allNodes, apiMenuItems, modifiers, tags);

    // LEAVING THIS COMMENTED OUT FOR DEBUG/ANALYSIS
    // debug('setMenuSections - data from WS event', apiMenuSectionChanges, apiMenuItems, apiModifiers);
    const menuSections = apiMenuSectionChanges.insert
      .reduce<Section[]>(
      (accumulatedMenuSections, apiMenuSection) => accumulatedMenuSections.concat(mapMenuSection(apiMenuSection, menuItems)),
      []
    );
    // debug('setMenu - menuSections', menuSections, menuItems, modifiers);
    yield put(menuSectionActions.setMenuSections(menuSections));
  }

  function* setDiningMode(diningModeName?: DiningModeName): SagaIterator {
    if (diningModeName) {
      yield put(settingsActions.setEnabledDiningMode(diningModeName));
      const isDineIn = yield select(sessionSelectors.getDineIn);
      if (diningModeName === 'takeAway') {
        // if we only support take away and device is diner, reset to kiosk
        if (isDineIn) {
          yield put(sessionActions.setIdentification(createIdentification(false)));
        }
      } else if (!isDineIn) {
        // if we only support dineIn or roomService and device is kiosk, reset to diner
        yield put(sessionActions.setIdentification(createIdentification(true)));
      }
    }
  }

  function* setCustomTexts(applicationTexts: ApplicationTextPayload[]): SagaIterator {
    const customTexts = applicationTexts.reduce((texts, applicationText) => ({
      ...texts,
      [applicationText.messageId]: applicationText.text,
    }), {});
    yield put(i18nActions.setCustomTexts(customTexts));
  }

  function* deviceAssigned({ payload }: ReturnType<typeof actions.deviceAssigned>) {
    debug('SocketManager.on', Event.deviceAssignedV1, payload);
    const {
      tables,
      tags,
      menus,
      menuSections,
      items,
      modifiers,
      taxRates,
      vouchers,
      restaurantName,
      webTheme = {},
      serviceCharge: { isDineInServiceChargeActive, isTakeAwayServiceChargeActive, serviceChargeDefaultValue },
      privacyPolicy,
      termsOfUse,
      dineInUserHelp,
      orderThreshold = ORDER_THRESHOLD_DISABLED,
      isVisitorCaptureActive,
      isEmailReceiptPromptEnabled,
      diningMode,
      isPaymentDisabled = false,
      isTakeAwayPayOnCollectionEnabled = false,
      locales,
      applicationTexts,
      diner_session_id,
      orders: { orders = [], items: orderItems = [] } = {},
      pastOrders: { orders: pastOrders = [], items: pastOrderItems = [] } = {},
      payment: { lastStatus, lastPaidAmount, lastPaidTipAmount },
      paymentConfig,
      currency,
      receiptDetails,
      isYumpingoEnabled,
      takeawaySchedulePeriods,
      preparationTime,
      scheduleLimitDays,
      dinerInfo,
      smsNotificationsDineIn,
      smsNotificationsTakeAway,
      posConnected: isPosConnected,
      gaTrackingNumber,
      ordamoGaTrackingNumber,
    } = payload;

    // general configuration
    yield put(tablesActions.setTables(tables));
    yield put(tagsActions.setTags(tags));
    yield put(menuActions.setMenus(menus.map(mapMenu)));
    yield call(setMenuSections, menuSections, items, modifiers, tags);
    yield put(taxRatesActions.setTaxRates(taxRates));
    yield put(vouchersActions.setVouchers(vouchers));

    // restaurant settings
    yield put(settingsActions.setRestaurantName(restaurantName));
    yield put(settingsActions.setTheme(webTheme));
    yield put(settingsActions.setIsDineInServiceChargeActive(isDineInServiceChargeActive));
    yield put(settingsActions.setIsTakeAwayServiceChargeActive(isTakeAwayServiceChargeActive));
    yield put(settingsActions.setDefaultServiceChargePercent(serviceChargeDefaultValue));
    yield put(settingsActions.setPrivacyPolicy(privacyPolicy));
    yield put(settingsActions.setTermsOfUse(termsOfUse));
    yield put(settingsActions.setSmsNotificationsDineIn(smsNotificationsDineIn));
    yield put(settingsActions.setSmsNotificationsTakeAway(smsNotificationsTakeAway));
    yield put(settingsActions.setDineInUserHelp(dineInUserHelp));
    yield call(setDiningMode, diningMode);
    yield put(settingsActions.setIsPaymentDisabled(isPaymentDisabled));
    yield put(settingsActions.setIsTakeAwayPayOnCollectionEnabled(isTakeAwayPayOnCollectionEnabled));
    yield put(settingsActions.setOrderThreshold(orderThreshold));
    yield put(settingsActions.setIsVisitorCaptureActive(isVisitorCaptureActive));
    yield put(settingsActions.setIsEmailReceiptPromptEnabled(isEmailReceiptPromptEnabled));
    yield put(settingsActions.setCurrency(currency));
    yield put(settingsActions.setReceiptDetails(receiptDetails));
    yield put(settingsActions.setGoogleAnalyticsIds([gaTrackingNumber, ordamoGaTrackingNumber].filter(isNotEmpty)));

    // takeaway scheduling
    yield put(scheduleActions.setTakeawayPeriods(takeawaySchedulePeriods));
    yield put(scheduleActions.setPreparationTime(preparationTime));
    yield put(scheduleActions.setScheduleLimitDays(scheduleLimitDays));

    yield put(i18nActions.setLocaleInfo(locales));
    yield call(setCustomTexts, applicationTexts);

    yield put(yumpingoActions.setIsEnabled(isYumpingoEnabled));

    // session and orders
    yield put(sessionActions.setSessionId(diner_session_id));
    yield put(sessionActions.setDinerInfo(dinerInfo));
    const allOrders = {
      orders: (orders).concat(pastOrders),
      items: (orderItems).concat(pastOrderItems)
        .reduce<APIOrderItem[]>(
        (uniqueOrderItems, orderItem) => uniqueOrderItems.find(otherOrderItem => otherOrderItem.id === orderItem.id)
          ? uniqueOrderItems
          : uniqueOrderItems.concat(orderItem),
        []
      ),
    };
    yield put(actions.ordersUpdated(allOrders));

    // payments
    yield put(paymentActions.setStatus(lastStatus));
    yield put(paymentActions.setPaidAmount(lastPaidAmount));
    yield put(paymentActions.setPaidTipAmount(lastPaidTipAmount));

    if (paymentConfig) {
      yield put(settingsActions.setPaymentConfig(paymentConfig));
    }

    yield put(settingsActions.setIsLoaded(true));

    yield put(settingsActions.setPosConnected(isPosConnected));
  }

  function* deviceUnassigned(): SagaIterator {
    const dineIn = yield select(sessionSelectors.getDineIn);
    if (dineIn) {
      yield put(tablesActions.setCurrentTable(undefined));
    }
    yield put(settingsActions.setServiceChargePercent(undefined));
    yield put(sessionActions.setSessionId(undefined));
    yield put(push('/'));
  }

  function* mapOrderedModifier(modifierValues: APIModifierValues, modifierId: number): SagaIterator {
    const modifier = yield select(menuSectionSelectors.getModifierById, modifierId);
    const value = modifier && findModifierValue(modifier, modifierValues[modifierId]);
    return { modifier, value };
  }

  function* mapOrderedModifiers(modifierValues: APIModifierValues): SagaIterator {
    const orderedModifiers = yield all(Object.keys(modifierValues)
      .map(modifierId => call(mapOrderedModifier, modifierValues, Number(modifierId)))
    );

    return orderedModifiers.reduce(
      (reducedOrderedModifiers: OrderedModifier[], orderedModifier?: OrderedModifier) => orderedModifier
        ? reducedOrderedModifiers.concat(orderedModifier)
        : reducedOrderedModifiers,
      []
    );
  }

  function* mapOrderedItem(apiOrderItem: APIOrderItem): SagaIterator {
    const menuItem = yield select(menuSectionSelectors.getMenuItemByNodeId, apiOrderItem.menu_node_id);
    const orderedModifiers = apiOrderItem.modifierValues
      ? yield call(mapOrderedModifiers, apiOrderItem.modifierValues)
      : [];
    return menuItem && ({ id: apiOrderItem.id, menuItem, orderedModifiers });
  }

  function* mapOrder({ id, state_id, ts_updated, ts_paid, ts_accepted, ts_ready, scheduled_takeaway, tip_amount, voucher_id }: APIOrder, items: APIOrderItem[]): SagaIterator {
    const orderedItems = yield all(items
      .filter(item => item.order_id === id)
      .map(item => call(mapOrderedItem, item))
    );
    return ({
      id,
      status: state_id,
      orderedItems: orderedItems.filter((orderedItem?: OrderedItem) => !!orderedItem),
      updated: ts_updated,
      timePaid: ts_paid,
      timeAccepted: ts_accepted,
      timeReady: ts_ready,
      timeScheduledTakeAway: scheduled_takeaway,
      tipAmount: tip_amount,
      voucherId: voucher_id || undefined,
    });
  }

  function* ordersUpdated({ payload }: ReturnType<typeof actions.ordersUpdated>): SagaIterator {
    debug('SocketManager.on', Event.ordersChangedV1, payload);
    const orders = yield all(
      payload.orders.map(order => call(mapOrder, order, payload.items))
    );
    yield put(ordersActions.updateOrders(orders));
  }

  function* paymentChanged({ payload }: ReturnType<typeof actions.paymentChanged>): SagaIterator {
    debug('SocketManager.on payment changed', payload);
    const { deviceIdentification, paymentState, paidAmount, paidTipAmount, error } = payload;
    const deviceId = yield select(sessionSelectors.getDeviceId);
    yield all([
      put(paymentActions.setStatus(paymentState)),
      put(paymentActions.setPaidAmount(paidAmount)),
      put(paymentActions.setPaidTipAmount(paidTipAmount)),
      put(paymentActions.setError(error)),
    ]);
    if (Number(deviceIdentification) === deviceId) {
      if (paymentState === PaymentStatus.finished) {
        yield put(push('/payment-success'));
      } else if (paymentState === PaymentStatus.failed) {
        yield put(push('/payment-failure'));
      } else if (paymentState === PaymentStatus.unknown) {
        yield put(push(`${routePath.contactStaff}/${ErrorCode.UnknownPaymentError}`));
      }
    }
  }

  function* waitingForWaiterApproval() {
    debug('SocketManager.on waiting for waiter approval');
    yield put(push('/waiting-waiter-approval'));
  }

  function* sendOrderSuccess() {
    debug('SocketManager.on order sent successfully');
    yield put(push('/send-order-success'));
  }

  function* sendOrderFailure() {
    debug('SocketManager.on failed sending order');
    yield put(push(`${routePath.contactStaff}/${ErrorCode.SendOrderFailure}`));
  }

  function* tagsUpdated({ payload }: ReturnType<typeof actions.tagsUpdated>) {
    debug('SocketManager.on', Event.tagsChangedV1, payload);
    yield put(tagsActions.setTags(payload));
  }

  function* voucherUpdated({ payload }: ReturnType<typeof actions.voucherUpdated>) {
    debug('SocketManager.on', Event.voucherUpdatedV1, payload);
    yield put(vouchersActions.voucherUpdated(payload));
  }

  function* posConnected({ payload }: ReturnType<typeof actions.posConnected>) {
    debug('SocketManager.on', Event.posConnectedV1, payload);
    yield put(settingsActions.setPosConnected(payload));
  }

  return function* saga() {
    yield all([
      takeEvery(actions.restaurantNameChanged, restaurantNameChanged),
      takeEvery(actions.deviceAssigned, deviceAssigned),
      takeEvery(actions.deviceUnassigned, deviceUnassigned),
      takeEvery(actions.ordersUpdated, ordersUpdated),
      takeEvery(actions.paymentChanged, paymentChanged),
      takeEvery(actions.waitingForWaiterApproval, waitingForWaiterApproval),
      takeEvery(actions.sendOrderSuccess, sendOrderSuccess),
      takeEvery(actions.sendOrderFailure, sendOrderFailure),
      takeEvery(actions.tagsUpdated, tagsUpdated),
      takeEvery(actions.voucherUpdated, voucherUpdated),
      takeEvery(actions.posConnected, posConnected),
    ]);
  };
};
