import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { OrderedItem } from 'types/model';
import { formatCurrency, formatText } from 'util/i18n';
import { getMatchingNodeIdAndModifierItems, summariseOrderedModifiers } from 'util/orderedItems';
import { selectors as settingsSelectors } from 'ducks/settings';
import OrderButtons from 'containers/OrderButtons';
import { Counter } from 'components/Counter';
import ModifiersCard from 'components/ModifiersCard';
import { ReactComponent as SVGArrowSimple } from 'style/svg/arrow-simple.svg';


interface Props {
  readonly orderedItem: OrderedItem;
  readonly currentlyOrderedItems: OrderedItem[];
  readonly showCounter?: boolean;
}

export const OrderedItemCard: FC<Props> = ({ orderedItem, currentlyOrderedItems, showCounter = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const currency = useSelector(settingsSelectors.getCurrency);
  const hasModifiers = orderedItem.orderedModifiers.length > 0;
  const [modifiersKey, modifiersTitle, modifiersTotalPrice] = summariseOrderedModifiers(orderedItem.orderedModifiers, undefined, currency);

  return (
    <div className="card">
      <div className="card-box">
        <div className={cx('dish', isOpen ? 'isOpen' : 'isClosed')}>
          <div className="box">
            <div className="head">
              <h4 className="title">{formatText(orderedItem.menuItem.title)}</h4>
            </div>
            {hasModifiers &&
              <div className="card-accordion">
                <div className="head" onClick={toggleOpen}>Contains modifiers <SVGArrowSimple className="icon-arrow" /></div>
                <div className="body">
                  <ModifiersCard orderedItem={orderedItem} modifiersKey={modifiersKey} modifiersTitle={modifiersTitle} />
                </div>
              </div>
            }
          </div>
          <div className="box">
            <div className="body">
              <h5 className="price">{formatCurrency(orderedItem.menuItem.price + modifiersTotalPrice, currency)}</h5>
            </div>
            {showCounter
              ? <Counter value={getMatchingNodeIdAndModifierItems(currentlyOrderedItems, orderedItem).length} />
              : <OrderButtons size="small" itemToOrder={orderedItem} />
            }
          </div>
        </div>
      </div>
    </div>
  );
};
