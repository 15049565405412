import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { MenuItem } from 'types/model';
import { actions as ordersActions } from 'ducks/orders';
import useConfigurableText from 'hooks/useConfigurableText';

import { UpsellItemCard } from '../../components/UpsellItemCard';


interface Props {
  readonly items: MenuItem[];
}

export const UpsellList: FC<Props> = ({ items }) => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();

  return (
    <div className="Upsell">
      <h3>{ct('upsell.popularWithOrder')}</h3>
      <div className="Upsell-box">
        {items
          .map(menuItem => (
            <UpsellItemCard
              key={menuItem.id}
              orderedItem={menuItem}
              onClick={() => dispatch(ordersActions.setCurrentItem(menuItem))}
            />
          ))
        }
      </div>
    </div>
  );
};
