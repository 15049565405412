import { useEffect, useState } from 'react';

import { ChoiceModifier, MenuItem, Modifier, ModifierOption, ModifierSubtype, ModifierType, OrderedModifier } from 'types/model';


export default (menuItem?: MenuItem) => {
  const [orderedModifiers, setOrderedModifiers] = useState<OrderedModifier[]>([]);

  useEffect(() => {
    if (menuItem) {
      setOrderedModifiers(menuItem.modifiers
        .map(modifier => ({
          modifier,
          value: modifier.type === ModifierType.text
            ? ''
            : [],
        }), [])
      );
    }
  }, [menuItem]);

  const setModifierValue = (modifier: Modifier, value: string | ModifierOption) =>
    setOrderedModifiers(orderedModifiers.map<OrderedModifier>(orderedModifier => {
      if (orderedModifier.modifier.id === modifier.id) {
        if (modifier.type === ModifierType.text) {
          return { modifier, value } as OrderedModifier;
        }
        if (modifier.subType === ModifierSubtype.selection) {
          return { modifier, value: [value] } as OrderedModifier;
        }
        const options = orderedModifier.value as ModifierOption[];
        const option = value as ModifierOption;
        const optionsValue = options.find(otherOption => otherOption.id === option.id)
          ? options.filter(otherOption => otherOption.id !== option.id)
          : options.concat(option);
        // Do not add the new option if maxChoice is exceeded.
        const choiceModifier = modifier as ChoiceModifier;
        if (choiceModifier.maxChoices && optionsValue.length > choiceModifier.maxChoices) {
          return orderedModifier;
        }
        return { modifier, value: optionsValue };
      } else {
        return orderedModifier;
      }
    }));

  const isChecked = (modifierType: ModifierType, modifierId: number, option: ModifierOption) => {
    if (modifierType === ModifierType.text) return false;

    const orderedModifierMatchingId = orderedModifiers
      .find(orderedModifier => orderedModifier.modifier.id === modifierId);

    if (!orderedModifierMatchingId) return false;

    const value = orderedModifierMatchingId.value as ModifierOption[];
    return !!value.find(otherOption => otherOption.id === option.id);
  };

  const minChoicesSelected = () => orderedModifiers.every(orderedModifier => {
    if (
      orderedModifier.modifier.subType === ModifierSubtype.selection &&
      orderedModifier.value.length !== 1
    ) {
      return false;
    }
    const minChoices = (orderedModifier.modifier as ChoiceModifier).minChoices;
    if (minChoices && orderedModifier.value.length < minChoices) {
      return false;
    }
    return true;
  });

  return { orderedModifiers, setModifierValue, isChecked, minChoicesSelected };
};
