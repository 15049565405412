import { PaymentBypassType, UserInfo } from 'types/model';
import { Event } from 'types/sockets';

import SocketManager from './socketManager';


export default async (
  webSocket: SocketManager,
  sessionId: number,
  deviceId: number | undefined,
  paymentBypass: PaymentBypassType,
  userInfo: UserInfo,
  tipAmount?: number,
  note?: string
) => {
  await webSocket.emit(Event.sendUnpaidTakeAwayOrderV1, { sessionId, deviceId, userInfo, tipAmount, note, paymentBypass });
};
