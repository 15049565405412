import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectors as sessionSelectors } from 'ducks/session';

import DineIn from './DineIn';
import TakeAway from './TakeAway';


const SendOrderSuccessScreen: FC = () => {
  const dineIn = useSelector(sessionSelectors.getDineIn);

  return (
    dineIn ? <DineIn /> : <TakeAway />
  );
};

export default SendOrderSuccessScreen;
