import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import SocketManagerProvider from 'contexts/SocketManagerContext';
import I18nApp from 'containers/I18nApp';

import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './store';

import 'style/index.scss';


const store = configureStore();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <SocketManagerProvider>
          <I18nApp />
        </SocketManagerProvider>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
