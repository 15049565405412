import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { getOrderDisplayNr } from 'util/getOrderDisplayNumber';
import { selectors as ordersSelectors } from 'ducks/orders';
import { selectors as paymentSelectors } from 'ducks/payment';
import { selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import closeSession from 'effects/closeSession';
import { useWebSocket } from 'contexts/SocketManagerContext';
import useConfigurableText from 'hooks/useConfigurableText';
import useDisableTimeoutModal from 'hooks/useDisableTimeoutModal';
import OrderSummary from 'containers/OrderSummary';
import SendReceiptButton from 'containers/SendReceiptButton';
import ServerActionButton from 'containers/ServerActionButton';
import { Button } from 'components/Button';
import { Header } from 'components/Header';
import { ReactComponent as SVGMarker } from 'style/svg/marker.svg';

// import { ReactComponent as SVGPhone } from 'style/svg/phone.svg';
import ScheduledOrderReadyTime from './ScheduledOrderReadyTime';
import TakeAwayProgressBar from './TakeAwayProgressBar';


const TimeLeft: FC = () => {
  useDisableTimeoutModal();
  const ct = useConfigurableText();
  const dispatch = useDispatch();
  const webSocket = useWebSocket();

  const [displayOrderSummary, setDisplayOrderSummary] = useState(false);

  const sessionId = useSelector(sessionSelectors.getSessionId);
  const receiptDetails = useSelector(settingsSelectors.getReceiptDetails);
  const mostRecentOrder = useSelector(ordersSelectors.getMostRecentPastOrder);
  const isVatReceiptAllowed = useSelector(paymentSelectors.getIsVatReceiptAllowed);
  const isEmailReceiptPromptEnabled = useSelector(settingsSelectors.getIsEmailReceiptPromptEnabled);

  const orderDisplayNumber = getOrderDisplayNr(mostRecentOrder?.id);
  const orderAcceptedTime = mostRecentOrder?.timeAccepted ? new Date(mostRecentOrder?.timeAccepted) : null;
  const orderScheduledTakeAwayTime = mostRecentOrder?.timeScheduledTakeAway ? new Date(mostRecentOrder?.timeScheduledTakeAway) : null;

  const isOrderReady = !!mostRecentOrder?.timeReady;

  const handleNewOrderClick = () => {
    if (sessionId !== undefined) {
      closeSession(dispatch, webSocket, false, sessionId!);
    }
    dispatch(push('/'));
  };
  const handleDisplayOrderSummaryClick = () => setDisplayOrderSummary(true);
  const handleOrderSummaryClosing = () => setDisplayOrderSummary(false);

  // TODO add a contact number
  // const contactNumber = undefined;
  const isAddressDefined = !!(receiptDetails.street && receiptDetails.number);

  return (
    <div className="container-fixed">
      <Header />

      <div className="TimeLeft-container">
        <address className="TimeLeft-contact">
          {isAddressDefined &&
            <div className="item">
              <SVGMarker />
              {receiptDetails.companyName}
              {receiptDetails.companyName && ', '}
              {receiptDetails.number}
              {' '}
              {receiptDetails.street}
            </div>
          }
          {/* {contactNumber && <a className="item" href={`tel:${{ contactNumber }}`}><SVGPhone />{ contactNumber }</a>} */}
        </address>
        {!!mostRecentOrder && orderDisplayNumber
          ? (
            <>
              {!!orderAcceptedTime && !!orderScheduledTakeAwayTime &&
                <TakeAwayProgressBar
                  orderScheduledTakeAwayTime={orderScheduledTakeAwayTime}
                  orderAcceptedTime={orderAcceptedTime}
                  isOrderReady={isOrderReady}
                />
              }
              <div className="TimeLeft-orderDetails">
                <div className="order">
                  {ct('sendReceiptButton.orderNumber')}
                  {' '}
                  <span className="orderID">
                    {orderDisplayNumber}
                  </span>
                </div>
                <div
                  className="title"
                >
                  {isOrderReady &&
                    ct('takeawaySchedulingOrders.texts.yourOrderIsReady')
                  }
                  {!isOrderReady && !!orderScheduledTakeAwayTime &&
                    <ScheduledOrderReadyTime orderScheduledTakeAwayTime={orderScheduledTakeAwayTime} />
                  }
                </div>
                {/* <Button
                  type="button"
                  variant="oval"
                  size="medium"
                  label={ct('takeawaySchedulingOrders.labels.review')}
                /> */}
                {isEmailReceiptPromptEnabled &&
                  <SendReceiptButton
                    orderNumber={orderDisplayNumber}
                    orderedItems={mostRecentOrder.orderedItems}
                    includeVatBreakdown={isVatReceiptAllowed}
                  />
                }
              </div>
              <OrderSummary
                orderNumber={orderDisplayNumber}
                orderedItems={mostRecentOrder.orderedItems}
                isOpen={displayOrderSummary}
                unpaidTipAmount={mostRecentOrder.tipAmount}
                onClose={handleOrderSummaryClosing}
              />
            </>
          )
          : (
            <div className="TimeLeft-orderDetails">
              <div className="title">{ct('sendOrderSuccessScreen.dontHaveOrders')}</div>
            </div>
          )}
        <div className="TimeLeft-footer">
          <div className="buttons">
            <Button
              type="button"
              variant="secondary"
              size="large"
              label={ct('shared.labels.showOrder')}
              onClick={handleDisplayOrderSummaryClick}
            />
            <ServerActionButton
              type="button"
              variant="primary"
              size="large"
              label={ct('takeawaySchedulingOrders.labels.makeOrder')}
              onClick={handleNewOrderClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeLeft;
