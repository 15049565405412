import React, { FC, useMemo } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { Menu, Section } from 'types/model';
import { formatText } from 'util/i18n';
import { actions as menuSectionActions, selectors as menuSectionSelectors } from 'ducks/menuSection';
import { Navigation, NavigationData } from 'components/Navigation';


const getDataForSection = (
  section: Section,
  dispatch: Dispatch,
  selectedSectionId?: number,
  parentKey?: string,
  parentTitle?: string,
): NavigationData[] => {
  const key = `${parentKey ? `${parentKey}-` : ''}${section.id}`;
  const title = `${parentTitle ? `${parentTitle} \u003e ` : ''}${formatText(section.title)}`;
  const active = selectedSectionId === section.id;
  return section.subSections.reduce<NavigationData[]>(
    (sectionData, subsection) => sectionData.concat(
      getDataForSection(subsection, dispatch, selectedSectionId, key, title)
    ),
    section.menuItems.length > 0
      ? [{ key,
        title,
        active,
        onClick:
          () => {
            dispatch(menuSectionActions.setScrollSelectedSectionIntoView(true));
            dispatch(menuSectionActions.setSelectedSectionId(section.id));
          },
      }]
      : []
  );
};


interface SectionNavigationProps {
  menu: Menu;
}

const SectionNavigation: FC<SectionNavigationProps> = ({ menu }) => {
  const dispatch = useDispatch();
  const getFilteredMenuSections = useMemo(() => menuSectionSelectors.createGetFilteredMenuSections(menu), [menu]);
  const menuSections = useSelector(getFilteredMenuSections);
  const selectedSectionId = useSelector(menuSectionSelectors.getSelectedSectionId);

  const navigationData = menuSections
    .reduce<NavigationData[]>(
    (sectionData, section) => sectionData.concat(
      getDataForSection(section, dispatch, selectedSectionId)
    ),
    []
  );
  return (
    <Navigation data={navigationData} />
  );
};

export default SectionNavigation;
