import { ChoiceModifier, Modifier, ModifierType } from 'types/model';

import { isModifierOptionAvailable } from './availability';


export const isChoiceModifier = (modifier: Modifier): modifier is ChoiceModifier => modifier.type === ModifierType.choice;

export const hasMinChoiceOfAvailableOptions = (modifier: ChoiceModifier, isPosConnected: boolean) => {
  const availableOptions = modifier.options.filter(option => isModifierOptionAvailable(option, isPosConnected));
  return availableOptions.length >= (modifier.minChoices ?? 0);
};

export const getModifierWithAvailableOptions = (modifier: Modifier, isPosConnected: boolean) => {
  if (!isChoiceModifier(modifier)) {
    return modifier;
  }
  const options = modifier.options.filter(option => isModifierOptionAvailable(option, isPosConnected));
  return modifier.minChoices || options.length > 0
    ? {
      ...modifier,
      options,
    }
    : [];
};
