import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { DayOfTheWeek } from 'types/model';
import { formatPeriodsAsOpeningHours, getCurrentDayOfTheWeek } from 'util/time';
import { selectors as scheduleSelectors } from 'ducks/schedule';
import useConfigurableText from 'hooks/useConfigurableText';
import { Accordion } from 'components/Accordion';


const TakeawayScheduleInfo: FC = () => {
  const ct = useConfigurableText();
  const takeawayPeriodsByDay = useSelector(scheduleSelectors.getTakeawayPeriodsGroupedByDay);

  const today = getCurrentDayOfTheWeek();
  const openingHours = (Object.keys(DayOfTheWeek) as DayOfTheWeek[])
    .reduce(
      (formattedHours, dayOfTheWeek) => formattedHours.set(
        dayOfTheWeek,
        formatPeriodsAsOpeningHours(
          takeawayPeriodsByDay.get(dayOfTheWeek),
          ct('takeawayScheduleInfo.closed'),
        ),
      ),
      new Map<DayOfTheWeek, string>()
    );

  const TodayInfo = <><span>{ct('takeawayScheduleInfo.today')}:</span> {openingHours.get(today)}</>;

  return (
    <>
      <Accordion
        title={ct('takeawayScheduleInfo.takeawayHours')}
        todayInfo={TodayInfo}
      >
        {(Object.keys(DayOfTheWeek) as DayOfTheWeek[])
          .map(dayOfTheWeek => {
            const shortDayName = ct(`takeawayScheduleInfo.shortDaysOfTheWeek.${dayOfTheWeek}`);
            const isToday = dayOfTheWeek === today;
            return (
              <div
                key={dayOfTheWeek}
                className={cx('item', isToday ? 'selected' : undefined)}
              >
                <span>{isToday ? shortDayName.toUpperCase() : shortDayName.toUpperCase()}:</span> {openingHours.get(dayOfTheWeek)}
              </div>
            );
          })
        }
      </Accordion>
    </>
  );
};

export default TakeawayScheduleInfo;
