import { Ref, useRef } from 'react';


export function useScroll<T extends HTMLElement>(args?: boolean | ScrollIntoViewOptions): [Ref<T>, () => void] {
  const htmlElementRef: Ref<T> = useRef(null);
  const scrollIntoView = () => {
    if (htmlElementRef.current) {
      htmlElementRef.current.scrollIntoView(args);
    }
  };
  return [htmlElementRef, scrollIntoView];
}
