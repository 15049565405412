import React, { FC, useMemo, useState } from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { routePath } from 'constants/routes';
import { getMenuHomePath } from 'util/path';
import { RootState } from 'ducks';
import { selectors as menuSelectors } from 'ducks/menu';
import { selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import { actions as tagsActions, selectors as tagsSelectors } from 'ducks/tags';
import useConfigurableText from 'hooks/useConfigurableText';
import useParamId from 'hooks/useParamId';
import { DinerInfoButton } from 'containers/DinerInfoButton';
import { Filters } from 'containers/FiltersDrawer';
import MenuItemDetails from 'containers/MenuItemDetails';
import MenuList from 'containers/MenuList';
import PaymentInProgressModal from 'containers/PaymentInProgressModal';
import SectionNavigation from 'containers/SectionNavigation';
import { BasketButton } from 'components/BasketButton';
import { Header } from 'components/Header';
import { TagSelectedList } from 'components/TagSelectedList';
import { ReactComponent as SVGFiltersIcon } from 'style/svg/filters.svg';


const MenuScreen: FC = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();
  const restaurantName = useSelector(settingsSelectors.getRestaurantName);
  const tags = useSelector(tagsSelectors.getTags);
  const selectedAllergensValues = useSelector(tagsSelectors.getSelectedAllergenValues);
  const selectedFiltersValues = useSelector(tagsSelectors.getSelectedFilterValues);
  const dineIn = useSelector(sessionSelectors.getDineIn);
  const menus = useSelector(menuSelectors.getSelectableMenus);
  const hasMoreThanOneMenu = useSelector(menuSelectors.hasMoreThanOneMenu);

  const menuId = useParamId();
  const getMenuSelector = useMemo(() => menuId !== undefined ? menuSelectors.createGetMenu(menuId) : undefined, [menuId]);
  const menu = useSelector((state: RootState) => getMenuSelector && getMenuSelector(state));

  const numberOfSelectedTags = selectedAllergensValues.length + selectedFiltersValues.length;

  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);

  const handleOpenFilters = () => setShowFiltersDrawer(true);
  const handleCloseFilters = () => setShowFiltersDrawer(false);

  const headerButtons = (dineIn || tags.length > 0) &&
    <>
      {dineIn && <DinerInfoButton />}
      {tags.length > 0 &&
        <button onClick={handleOpenFilters}>
          <SVGFiltersIcon />
          {numberOfSelectedTags > 0 &&
            <span className="counter">{numberOfSelectedTags}</span>
          }
        </button>
      }
    </>;

  return (
    <div className="container-fixed">
      <Header
        title={restaurantName}
        backTo={hasMoreThanOneMenu ? routePath.menus : routePath.root}
        buttons={headerButtons}
      >
        {menu && <SectionNavigation menu={menu} />}
      </Header>
      <section>
        {menu ?
          <>
            <TagSelectedList
              title={ct('filters.excludedAllergens')}
              selected={selectedAllergensValues}
              removeSelectedTag={(tag) => dispatch(tagsActions.removeSelectedAllergen(tag))}
            />
            <TagSelectedList
              title={ct('filters.includedFilters')}
              selected={selectedFiltersValues}
              removeSelectedTag={(tag) => dispatch(tagsActions.removeSelectedFilter(tag))}
            />
            <MenuList menu={menu} />
            <MenuItemDetails />
          </>
          : <Redirect to={{ pathname: getMenuHomePath(menus) }} />
        }
        <BasketButton />
        <PaymentInProgressModal />
      </section>
      <Filters
        isOpen={showFiltersDrawer}
        onClose={handleCloseFilters}
      />
    </div>
  );
};

export default MenuScreen;
