import { Dispatch } from 'redux';

import { Event } from 'types/sockets';
import { error } from 'util/log';
import { actions as sessionActions } from 'ducks/session';

import SocketManager from './socketManager';


export default async (webSocket: SocketManager,
  dispatch: Dispatch, deviceId: number, customerName?: string) => {
  try {
    const dinerInfo = await webSocket.emit(
      Event.updateDinerInfoV1, { deviceId, customerName }
    );

    dispatch(sessionActions.setDinerInfo(dinerInfo));
  } catch (err) {
    error(`Can't update diner info for device ${deviceId}`);
  }
};
