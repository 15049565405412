export const restaurantConfig = {
  ordamoServerBase: process.env.REACT_APP_ORDAMO_SERVER_BASE || '',
  apiPath: process.env.REACT_APP_ORDAMO_API_PATH || '/api',
  apiVersion: process.env.REACT_APP_ORDAMO_API_VERSION || 'v1',
  webSocketPath: process.env.REACT_APP_ORDAMO_WS_PATH || '/ws',

  waiterId: Number(process.env.REACT_APP_ORDAMO_WAITER_ID) || 1, // 1 should be owner

  isApplePayMocked: process.env.REACT_APP_MOCK_APPLE_PAY === 'true',
};
