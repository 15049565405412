import { RawDraftContentState } from 'draft-js';


export enum SupportedLocale {
  EN = 'en',
  CS = 'cs',
}

export interface Currency {
  code: string;
  display: boolean;
}

export interface LocaleInfo {
  defaultLocale: SupportedLocale;
  availableLocales: SupportedLocale[];
}

export interface CustomTexts {
  [key: string]: I18nText;
}

export type I18nText = { [locale in SupportedLocale]?: string };
export type I18nRichText = { [locale in SupportedLocale]?: RawDraftContentState };

export interface VerifiableValue<T> {
  hash: string;
  value: T;
}

export enum PaymentMethod {
  Connect = 'CONNECT',
  JudoPay = 'JUDO_PAY',
  Mock = 'MOCK',
}

export interface PaymentConfig {
  type: PaymentMethod;
}

export interface CurrencySettings {
  code: string;
  display: boolean;
}

export interface ConnectPaymentConfig extends PaymentConfig {
  type: PaymentMethod.Connect;
  storeName: string;
  url: string;
}

export interface JudoPayPaymentConfig extends PaymentConfig {
  type: PaymentMethod.JudoPay;
  apiToken: string;
  isSandbox: boolean;
}

export interface Table {
  id: number;
  title: string;
  group: number;
  posId: string | null;
  rank: number;
}

export interface MenuNode {
  id: number;
  title: I18nText;
  description?: I18nText;
  hasPhoto: boolean;
}

export enum MenuType {
  DineIn = 'DINE_IN',
  TakeAway = 'TAKE_AWAY',
}

export interface Menu {
  id: number;
  title: I18nText;
  description: string | undefined;
  hasPhoto: boolean;
  active: boolean;
  type: MenuType;
  typeI18nKey: string;
  deleted: null | Date;
  rank: number;
  sectionIds: number[];
}

export interface Section extends MenuNode {
  subSections: Section[];
  menuItems: MenuItem[];
}

export interface MenuItem extends MenuNode {
  nodeId: number;
  price: number;
  modifiers: Modifier[];
  allergens: Tag[];
  filters: Tag[];
  available: boolean;
  rank: number;
  upsell: number;
  pairs: number[];
  dineInTaxId: number;
  takeAwayTaxId: number;
  posId: string | null;
}

export interface ModifierOption {
  id: number;
  price: number;
  title: I18nText;
  rank: number;
  allergens: Tag[];
  filters: Tag[];
  available: boolean;
  itemPosId: string | null;
}

export enum ModifierType {
  choice = 1,
  text = 3,
}

export enum ModifierSubtype {
  selection = 'selection',
  addition = 'addition',
  removal = 'removal',
}

export interface Modifier {
  id: number;
  title: I18nText;
  description: I18nText;
  type: ModifierType;
  subType?: ModifierSubtype;
  rank: number;
}

export interface ChoiceModifier extends Modifier {
  type: ModifierType.choice;
  options: ModifierOption[];
  minChoices?: number;
  maxChoices?: number;
}

export enum TagType {
  allergen = 1,
  filter = 2,
}

export interface Tag {
  id: number;
  title: I18nText;
  tagType: TagType;
  shortcut: I18nText;
  color: number;
}

export interface Orderable {
  id?: number; // orderable items do not have id before sent to server
}

export interface OrderedItem extends Orderable {
  menuItem: MenuItem;
  orderedModifiers: OrderedModifier[];
}

export type ModifierValue = string | ModifierOption[];

export interface OrderedModifier extends Orderable {
  modifier: Modifier;
  value: ModifierValue;
}

export enum OrderStatus {
  open = 1,
  sendingToPos = 2,
  accepted = 3,
  paid = 4,
}

export interface Order {
  id: number;
  status: OrderStatus;
  orderedItems: OrderedItem[];
  updated: string;
  timePaid: string | null;
  timeAccepted: string | null;
  timeReady: string | null;
  timeScheduledTakeAway: string | null;
  tipAmount?: number;
  voucherId?: number;
}

export interface Theme {
  logo?: string;
  primaryColour?: string;
  secondaryColour?: string;
  textColour?: string;
  titleTextColour?: string;
  backgroundColour?: string;
  sectionBackgroundColour?: string;
  cardBackgroundColour?: string;
  textSize?: number;
}

export enum LogLevel {
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
}

export type DiningModeName = 'dineIn' | 'takeAway' | 'roomService';
export const DiningMode: { [key in DiningModeName]: number } = {
  dineIn: 0b001,
  takeAway: 0b010,
  roomService: 0b100,
};

export enum PaymentStatus {
  started = 'STARTED',
  finished = 'FINISHED',
  failed = 'FAILED',
  unknown = 'UNKNOWN',
  processing = 'PROCESSING',
  canceled = 'CANCELED',
}

export interface Visitor {
  [prop: string]: string | number;
  firstNames: string;
  lastName: string;
  contactPhone: string;
  numberOfGuests: number;
}

export interface UserInfo {
  fullName?: string;
  email?: string;
  phoneNumber: string;
}

export interface DinerInfo {
  customerName: string;
  customerNumber: number;
}

export interface TaxRate {
  id: number;
  endDate?: Date;
  taxCategory: string;
  taxRate: number;
}

export enum DayOfTheWeek {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export interface DayAndTime {
  day: DayOfTheWeek;
  time: number;
}

export interface Period {
  id: number;
  day: DayOfTheWeek;
  startTime: number;
  endTime: number;
}

export interface Schedule {
  isAsap: boolean;
  scheduleTimestamp?: number;
}

export interface ReceiptDetailsSettings {
  companyName?: string;
  VAT?: string;
  street?: string;
  number?: string;
  city?: string;
  country?: string;
}

export interface Voucher {
  id: number;
  name: string;
  description?: string;
  amount: number;
  isPercentage: boolean;
  active: boolean;
  uses: number;
}

export enum PaymentBypassType {
  UNKNOWN = 'UNKNOWN',
  FREE_ORDER = 'FREE_ORDER',
  WEB_PAY_ON_COLLECTION = 'WEB_PAY_ON_COLLECTION',
  WEB_NO_DINE_IN_PREPAYMENT = 'WEB_NO_DINE_IN_PREPAYMENT',
}

export interface PaymentError {
  message: string;
  code: number;
}
