import { MenuItem, Modifier, ModifierOption, Order, Tag } from 'types/model';

import { formatText } from './i18n';


export const compareOrders = (order1: Order, order2: Order) =>
  new Date(order2.updated).getTime() - new Date(order1.updated).getTime();

const compareOptionalString = (string1?: string, string2?: string) => string1 && string2
  ? string1.localeCompare(string2)
  : Number(!!string1) - Number(!!string2);

export const compareMenuItems = (menuItem1: MenuItem, menuItem2: MenuItem) => {
  const rankDiff = menuItem1.rank - menuItem2.rank; // Sort by rank in ascending order
  const upsellDiff = menuItem2.upsell - menuItem1.upsell; // Sort by upsell in descending order
  if (upsellDiff === 0) {
    if (rankDiff === 0) {
      // Should not happen
      return compareOptionalString(formatText(menuItem1.title), formatText(menuItem2.title));
    } else {
      return rankDiff;
    }
  } else {
    return upsellDiff;
  }
};

export const compareModifiers = (modifier1: Modifier, modifier2: Modifier) =>
  modifier1.rank - modifier2.rank;

export const compareModifierOptions = (option1: ModifierOption, option2: ModifierOption) =>
  option1.rank - option2.rank;

export const compareTags = (tag1: Tag, tag2: Tag) => compareOptionalString(formatText(tag1.title), formatText(tag2.title));
