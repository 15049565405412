export default {
  nav: {
    continue: 'Continue',
  },
  model: {
    table: 'Table',
    room: 'Room',
    diningMode: {
      dineIn: 'Dine in',
      takeAway: 'Take away',
      roomService: 'Room service',
    },
  },
  shared: {
    titles: {
      orderDetails: 'Order details',
      requiredInformation: 'Required information',
      oops: 'Oops!',
    },
    buttons: {
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    labels: {
      allergens: 'Allergens',
      filters: 'Filters',
      allergensFilters: 'Allergens & Filters',
      soldOut: 'SOLD OUT',
      addToOrder: 'Add to order',
      openBasket: 'Open basket',
      backToBasket: 'Back to basket',
      backToMenu: 'Back to menu',
      showOrder: 'Show order',
      tryAgain: 'Try again',
      finish: 'Finish',
      pay: 'Pay',
      payOnCollect: 'Pay on collection',
      payNow: 'Pay now',
      unavailable: 'Unavailable',
      selectOption: 'Select an option',
      error: 'It looks like there has been an error',
    },
    email: {
      yourEmail: 'Your e-mail',
      placeholder: 'your_email@example.com',
      invalid: 'Invalid email address',
    },
  },
  contactStaffScreen: {
    orderId: 'Order number',
    noOrder: 'You don’t have a current order',
    contactStaff: 'Please contact the staff.',
    confirmSessionClose: {
      title: 'Leave session',
      message: 'Before leaving, please contact the staff. Otherwise you will lose access to all order details.',
    },
    sendOrderFailure: {
      title: 'Order error',
      message: 'Order did not go through.',
    },
    paymentFailure: {
      title: 'Payment error',
      message: 'Payment verification failed.',
    },
    closeSession: 'Leave session',
    errorDetails: 'Error details',
  },
  connectionBox: {
    networkIsDown: 'Network is down, you can still browse the app',
    networkIsUp: 'You\'re online',
  },
  welcomeScreen: {
    welcomeMessage: 'Make an order and your meal will be ready soon',
    number: '{tableOrRoom} number',
    numberPrompt: 'Please enter your {tableOrRoom} number...',
    error: {
      invalidNumber: '{tableOrRoom} number not found.',
    },
  },
  loadingScreen: {
    loading: 'Please wait, loading...',
  },
  menuSelectScreen: {
    chooseMenu: 'Choose menu',
    noMenusAvailable: 'Sorry, there are no menus currently available',
  },
  menuListScreen: {
    noDishesAvailable: 'Sorry, this menu is currently empty',
  },
  newSession: {
    button: {
      cancel: 'Cancel and Start again',
      continue: 'Continue',
    },
    areYouDeciding: 'Are you still deciding on your order?',
    continueOrStart: 'Please choose to continue or cancel and start a new order',
  },
  notFound: {
    button: {
      tryAgain: 'Try again',
    },
    notFound: 'Page not found',
    pleaseTryAgain: 'We are sorry that we can’t find the page, please try again',
  },
  orderScreen: {
    button: {
      sendOrder: 'Send order',
      confirmPay: 'Confirm and pay',
      confirmSendOrder: 'Confirm and send order',
      schedulePay: 'Schedule and pay',
      scheduleSendOrder: 'Schedule and send order',
      paymentInProgress: 'Payment in progress',
    },
    totalCost: 'Total cost',
    basket: 'Basket',
    orderedItems: 'Ordered items',
  },
  orderSummary: {
    orderNumber: 'Your order - {orderNumber}',
    serviceCharges: 'Service charges',
    optional: '(Optional)',
    discount: 'Discount',
    totalPrice: 'Total price',
  },
  orderDetailPage: {
    addNote: 'Add note',
  },
  paymentScreen: {
    choosePaymentOption: 'Choose payment option:',
    ApplePay: 'Apple Pay',
    ByCard: 'By Card',
    title: 'Payment',
    secureCheckout: 'Secure Checkout',
    cancel: 'Cancel',
  },
  paymentFailureScreen: {
    title: 'Payment failure',
    oops: 'Oops!',
    error: 'It looks like there has been an error while processing your payment',
  },
  paymentIsInProgress: {
    pleaseWait: 'Please wait while we are processing your payment',
  },
  paymentSuccessScreen: {
    received: 'Payment received',
    pleaseWait: 'Please wait until your order is sent to the kitchen',
  },
  sendOrderSuccessScreen: {
    thankYou: 'Thank you!',
    orderHasBeenSent: 'Your order has been sent',
    orderNumber: 'Your order number',
    dontHaveOrders: 'You don`t have any orders',
  },
  sendReceiptButton: {
    buttons: {
      cancel: 'Cancel',
      continue: 'Continue',
    },
    receiptOrderFrom: 'Receipt for your order from {restaurantName}',
    orderNumber: 'Order number',
    serviceCharges: 'Service charges',
    totalPrice: 'Total price',
    includesVat: 'Includes VAT at {taxRate} %',
    getEmailVatReceipt: 'Get email VAT receipt',
    emailVatReceipt: 'Email VAT Receipt',
    getEmailReceipt: 'Get email receipt',
    emailReceipt: 'Email Receipt',
    receiptDate: 'Receipt date',
    item: 'ITEM',
    price: 'PRICE',
    companyVatNumber: 'COMPANY VAT NUMBER - {VAT}',
    totalPriceBeforeDiscount: 'Total price before discount',
    discount: 'Discount',
  },
  serviceChargeCard: {
    serviceCharge: 'Service charges',
    optional: '(Optional)',
  },
  termOfUseScreen: {
    header: 'Terms of use',
    unavailable: 'Unavailable',
  },
  timeOutModal: {
    areYouThere: 'Are you still there?',
    ifRemainInactive: 'If you remain inactive, your current order will be closed in',
    startAnother: 'so you can start another.',
    tapAnywhereToRemain: 'Tap anywhere to remain on this screen and keep your current order open.',
  },
  paymentInProgressModal: {
    paymentInProgress: 'Payment in progress',
  },
  upsell: {
    popularWithOrder: 'Popular with your order',
  },
  waitingForWaiter: {
    orderForApproval: 'Order sent to the restaurant for approval',
    pleaseWait: 'Please wait',
  },
  userInfo: {
    yourName: 'Your name',
    yourNumber: 'Your number',
    yourNumberPlaceholder: '07123456789',
    yourNote: 'Your comment',
    addNote: 'Add comment for the restaurant',
    addNoteHeadline: 'Add comment',
    addNoteLabel: '(Special requests, allergies etc.)',
    addNotePlaceholder: 'I have an allergy on nuts ..',
    done: 'Done',
  },
  dinerInfo: {
    changeYourName: 'Change your name',
    namePlaceholder: 'Your name (optional)',
    name: 'Your name',
  },
  visitorModal: {
    firstNames: 'First name(s)',
    lastName: 'Last name',
    contactPhone: 'Contact phone number',
    numberOfGuests: 'Total number of guests',
    error: {
      noFirstNames: 'Please provide your first name(s)',
      noLastName: 'Please provide your last name',
      noContactPhone: 'Please provide your contact phone number',
      noNumberOfGuests: 'Please provide the total number of guests',
    },
  },
  takeawayScheduleInfo: {
    takeawayHours: 'Takeaway available hours',
    today: 'Today',
    closed: 'Closed',
    daysOfTheWeek: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    shortDaysOfTheWeek: {
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
      sunday: 'Sun',
    },
  },
  takeawayScheduleSelect: {
    collectionTime: 'Collection time',
    pickupIn: 'Pickup in {preparationTime} min',
    scheduleTime: 'Schedule time',
  },
  takeawaySchedulingOrders: {
    texts: {
      headerCollectionTime: 'Collection time',
      scheduleTime: 'Schedule time',
      asSoonAsPossible: 'As soon as possible',
      yourOrderWillBeReadyWeekday: 'Your order will be ready {day} at {time}',
      yourOrderWillBeReadyDate: 'Your order will be ready on {date} at {time}',
      yourOrderIsReady: 'Your order is ready and waiting for you',
      openingHours: 'Opening hours',
      today: 'today',
      tomorrow: 'tomorrow',
      timeUntilReady: '{units} until order is ready',
      minute: 'minute',
      minutes: 'minutes',
      hour: 'hour',
      hours: 'hours',
      day: 'day',
      days: 'days',
      orderWillBeReady: 'Your order will be ready at {readyTime}',
      neitherDayIsAvailable: 'Sorry, neither of the days is available for scheduling.',
      dateAtTime: '{date} at {time}',
    },
    labels: {
      hours: 'Hours',
      minutes: 'Minutes',
      selectHours: 'Select hours',
      selectMinutes: 'Select minutes',
      day: 'Day',
      review: 'Leave a review',
      makeOrder: 'Make a new order',
      done: 'Done',
      continue: 'Continue',
    },
  },
  yumpingo: {
    texts: {
      workingHard: 'We are working hard to make you happy.',
      pleaseHelp: 'Please, help us to help you.',
    },
    buttons: {
      remind: 'Remind me later',
      review: 'Leave a review',
    },
  },
  filters: {
    hideItemsContaining: 'Hide items containing',
    dietaryRequirement: 'Dietary requirement',
    apply: 'Apply filters',
    clear: 'Clear all',
    excludedAllergens: 'Excluded allergens',
    includedFilters: 'Included filters',
    allModifierOptionsFilteredOut: 'Your current filters exclude all modifier options. Please adjust the filters to view more items.',
    allMenuItemsFilteredOut: 'Your current filters exclude all menu items. Please adjust the filters to view more items.',
  },
  phoneNumberScreen: {
    textWillBeSent: 'A text message will be sent to you when your order is ready',
  },
  voucher: {
    promoCode: 'PROMO CODE',
    enterPromoCode: 'Enter promo code',
    submit: 'Submit',
    DiscountSuccessfull: 'Discount was successfully added to your order!',
    OkGotIt: 'Ok, got it',
    Discount: 'Discount',
  },
};
