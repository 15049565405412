import { AnyAction, applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { LogLevel } from 'types/model';
import { logConfig } from 'config/logConfig';
import { saveIdentification, saveVisitor } from 'localStorage';

import { createRootReducer, initialState, rootSaga, RootState } from './ducks';


export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
  serialize: true,
});

const middleware = [
  routerMiddleware(history),
  sagaMiddleware,
];

if (logConfig.logLevel === LogLevel.debug) {
  middleware.push(logger);
}

const configureStore = (): Store<RootState, AnyAction> => {
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
    )
  );

  store.subscribe(() => {
    const { session: { identification, visitor } } = store.getState();
    saveIdentification(identification);
    saveVisitor(visitor);
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
