import React, { FC, FormEvent, useCallback } from 'react';

import useConfigurableText from 'hooks/useConfigurableText';
import { Modal } from 'components/Modal';


interface ConfirmModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  message: string;
  showModal: boolean;
}

const ConfirmModal: FC<ConfirmModalProps> = ({ showModal, onConfirm, onCancel, title, message }) => {
  const ct = useConfigurableText();

  const handleCancelAction = useCallback(() => {
    onCancel();
  }, [onCancel]);
  const handleConfirmAction = useCallback((e: FormEvent) => {
    e.preventDefault();
    onConfirm();
  }, [onConfirm]);

  return (
    <Modal
      position="center"
      className="text-left"
      isShown={showModal}
      onSubmit={handleConfirmAction}
      buttons={[
        {
          label: ct('shared.buttons.cancel'),
          type: 'button',
          variant: 'secondary',
          size: 'medium',
          onClick: handleCancelAction,
        },
        {
          label: ct('shared.buttons.confirm'),
          type: 'submit',
          size: 'medium',
        },
      ]}
    >
      <h5 className="text-center">{title}</h5>
      <p>{message}</p>
    </Modal>
  );
};

export default ConfirmModal;
