import { OrderedItem } from 'types/model';
import { Event } from 'types/sockets';
import { error, warn } from 'util/log';

import SocketManager from './socketManager';


export default async (
  webSocket: SocketManager,
  sessionId: number,
  deviceId: number,
  dineIn: boolean,
  { menuItem }: OrderedItem,
  orderedItemId: number,
): Promise<number> => {
  if (orderedItemId > 0) {
    try {
      const { change } = await webSocket.emit(
        Event.removeOrderItemsV1,
        {
          sessionId,
          deviceId,
          dineIn,
          itemId: menuItem.id,
          nodeId: menuItem.nodeId,
          orderItemIds: [orderedItemId],
        }
      );
      return change;
    } catch (err) {
      error(
        'Unable to remove items from the order',
        err,
        sessionId,
        deviceId,
        menuItem.id,
        menuItem.nodeId
      );
      return 0;
    }
  }
  warn('removeOrderedItem - Unable to remove item - invalid orderedItemId');
  return 0;
};
