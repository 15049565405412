import React, { FC } from 'react';

import useConfigurableText from 'hooks/useConfigurableText';


const WaitingForWaiterApprovalScreen: FC = () => {
  const ct = useConfigurableText();

  return (
    <div className="PageSimple">
      <div className="container">
        <div className="head">
          <h2>{ct('waitingForWaiter.orderForApproval')}</h2>
          <p>{ct('waitingForWaiter.pleaseWait')}</p>
        </div>
      </div>
    </div>
  );
};

export default WaitingForWaiterApprovalScreen;
