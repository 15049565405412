export const routePath = {
  root: '/',
  menus: '/menus',
  order: '/order',
  orderDetails: '/order-details',
  phoneNumber: '/phone-number',
  payment: '/payment',
  paymentSuccess: '/payment-success',
  paymentFailure: '/payment-failure',
  waitingWaiterApproval: '/waiting-waiter-approval',
  sendOrderSuccess: '/send-order-success',
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use',
  appointTime: '/appoint-time',
  error: '/error',
  contactStaff: '/contact-staff',
  notFound: '/not-found',
};
