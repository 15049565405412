import React, { FC, ReactNode } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import { noop } from 'util/noop';

import './drawer.scss';


interface DrawerProps {
  className?: string;
  title?: string;
  isOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;
  anchor?: 'bottom' | 'right';
  head?: ReactNode;
}

export const Drawer: FC<DrawerProps> = ({ className, title, head, children, isOpen, onOpen = noop, onClose, anchor = 'bottom' }) => (
  <SwipeableDrawer
    className={className}
    anchor={anchor}
    disableSwipeToOpen
    open={isOpen}
    onClose={onClose}
    onOpen={onOpen}
  >
    <div className="Drawer">
      <div className="head">
        { title &&
          <h3>{title}</h3>
        }
        {head}
      </div>
      <div className="body">
        {children}
      </div>
    </div>
  </SwipeableDrawer>
);
