import { createSelector } from 'reselect';
import { ActionType, createAction, getType } from 'typesafe-actions';

import { Menu, MenuType } from 'types/model';
import { RootState } from 'ducks';
import { selectors as sessionSelectors } from 'ducks/session';


export interface MenuState {
  menus: Menu[];
}

export const initialState: MenuState = {
  menus: [],
};

const getAllMenus = (state: RootState) => state.menu.menus;

const getActiveMenus = createSelector(
  getAllMenus,
  menus => menus.filter(menu => menu.active)
);

const getSelectableMenus = createSelector(
  getActiveMenus,
  sessionSelectors.getDineIn,
  (menus, isDineIn) => menus
    .filter(menu => {
      const isAtLeastOneTakeAwayMenu = !!menus.find(({ type }) => type === MenuType.TakeAway);

      // if take away is selected but there are no take away menus, we show all menus
      if (!isDineIn && !isAtLeastOneTakeAwayMenu) {
        return true;
      }

      return isDineIn ? menu.type === MenuType.DineIn : menu.type === MenuType.TakeAway;
    })
    .filter(menu => menu.sectionIds.length > 0)
    .sort((menuOne, menuTwo) => menuOne.rank - menuTwo.rank)
);

const hasMoreThanOneMenu = createSelector(
  getSelectableMenus,
  menus => menus.length > 1
);

type GetMenu = (state: RootState) => Menu | undefined;

const createGetMenu = (menuId: number): GetMenu => createSelector(
  getSelectableMenus,
  menus => menus.find(menu => menu.id === menuId)
);

export const selectors = {
  getSelectableMenus,
  hasMoreThanOneMenu,
  createGetMenu,
};

export const actions = {
  setMenus: createAction('menu/setMenus')<Menu[]>(),
};

export type Actions = ActionType<typeof actions>;

const setMenus = (state: MenuState, menus: Menu[]): MenuState => ({
  ...state,
  menus,
});

export const reducer = (state: MenuState = initialState, action: Actions): MenuState => {
  switch (action.type) {
    case getType(actions.setMenus):
      return setMenus(state, action.payload);
    default:
      return state;
  }
};
