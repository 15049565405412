/* eslint-disable @typescript-eslint/indent, max-len */

import React, { FC, FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import EmailValidator from 'email-validator';
import moment from 'moment';

import { OrderedItem } from 'types/model';
import { formatCurrency, formatText } from 'util/i18n';
import { getMatchingNodeIdAndModifierItems, getUniqueOrderedItems, sortOrderedItems, summariseOrderedModifiers } from 'util/orderedItems';
import { stripIndents } from 'util/stringTags';
import { selectors as ordersSelectors } from 'ducks/orders';
import { selectors as paymentSelectors } from 'ducks/payment';
import { selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import { selectors as taxRatesSelectors } from 'ducks/taxRates';
import sendEmail from 'effects/sendEmail';
import { useWebSocket } from 'contexts/SocketManagerContext';
import useConfigurableText from 'hooks/useConfigurableText';
import ServerActionButton from 'containers/ServerActionButton';
import { Modal } from 'components/Modal';
import { ReactComponent as SvgRecipe } from 'style/svg/recipe.svg';


interface SendReceiptButtonProps {
  orderNumber: string;
  orderedItems: OrderedItem[];
  includeVatBreakdown: boolean;
}

const SendReceiptButton: FC<SendReceiptButtonProps> = ({ orderNumber, orderedItems, includeVatBreakdown }) => {
  const webSocket = useWebSocket();
  const ct = useConfigurableText();
  const orderTotal = useSelector(() => ordersSelectors.getOrderTotal(orderedItems));
  const mostRecentOrder = useSelector(ordersSelectors.getMostRecentPastOrder);
  const voucher = useSelector(ordersSelectors.getMostRecentVoucher);
  const discount = useSelector(ordersSelectors.getMostRecentDiscount);
  const paidTipAmount = useSelector(paymentSelectors.getPaidTipAmount) || 0;
  const unpaidOrderTipAmount = mostRecentOrder?.tipAmount;
  const tipAmount = unpaidOrderTipAmount ?? paidTipAmount;
  const orderTotalWithTip = Math.max(orderTotal - discount, 0) + tipAmount;
  const isServiceChargeActive = useSelector(settingsSelectors.getIsServiceChargeActive);
  const restaurantName = useSelector(settingsSelectors.getRestaurantName);
  const currency = useSelector(settingsSelectors.getCurrency);
  const isDineIn = useSelector(sessionSelectors.getDineIn);
  const taxRates = useSelector(taxRatesSelectors.getAllTaxRates);
  const taxAmountsByRate = useSelector(() => taxRatesSelectors.calculateTaxAmountByRate(taxRates, orderedItems, isDineIn));
  const receiptDetails = useSelector(settingsSelectors.getReceiptDetails);
  const hasCompanyVAT = !!receiptDetails.VAT && includeVatBreakdown;

  const [email, setEmail] = useState('');
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const orderedItemRow = (orderedItem: OrderedItem) => {
    const quantity = getMatchingNodeIdAndModifierItems(orderedItems, orderedItem).length;
    const [, modifiersTitle, modifiersTotalPrice] = summariseOrderedModifiers(orderedItem.orderedModifiers, undefined, currency);
    return (
      `<tr>
        <td style="padding:5px 0px 5px 0px;">${formatText(orderedItem.menuItem.title)}${modifiersTitle ? ` (${modifiersTitle})` : ''}${quantity > 1 ? ` (x${quantity})` : ''}</td>
        <td style="padding:5px 0px 5px 0px;">${formatCurrency((orderedItem.menuItem.price + modifiersTotalPrice) * quantity, currency)}</td>
      </tr>`
    );
  };

  const orderedItemPlain = (orderedItem: OrderedItem) => {
    const quantity = getMatchingNodeIdAndModifierItems(orderedItems, orderedItem).length;
    const [, modifiersTitle, modifiersTotalPrice] = summariseOrderedModifiers(orderedItem.orderedModifiers, undefined, currency);
    return (
      `
      ${formatText(orderedItem.menuItem.title)}${modifiersTitle ? ` (${modifiersTitle})` : ''}${quantity > 1 ? ` (x${quantity})` : ''}     ${formatCurrency((orderedItem.menuItem.price + modifiersTotalPrice) * quantity, currency)}
      `
    );
  };

  const taxRateRowPlain = (rate: number, total: number) => {
    const textIncludesVat = ct('sendReceiptButton.includesVat', { taxRate: rate.toFixed(2) });
    return `${textIncludesVat} ${formatCurrency(total, currency)}`;
  };

  const taxRateRow = (rate: number, total: number) => {
    const textIncludesVat = ct('sendReceiptButton.includesVat', { taxRate: rate.toFixed(2) });
    return (
      `<tr>
        <td style="padding:0px 0px 5px 0px;"><small>${textIncludesVat}</small></td>
        <td style="padding:0px 0px 5px 0px;"><small>${formatCurrency(total, currency)}</small></td>
      </tr>`
    );
  };

  const voucherRows = () => {
    const textTotalPriceBeforeDiscount = ct('sendReceiptButton.totalPriceBeforeDiscount');
    const textDiscount = ct('sendReceiptButton.discount');

    return voucher ? (
      `<tr>
        <td style="padding:0px 0px 5px 0px;"><small>${textTotalPriceBeforeDiscount}</small></td>
        <td style="padding:0px 0px 5px 0px;"><small>${formatCurrency(orderTotal, currency)}</small></td>
      </tr>
      <tr>
        <td style="border-bottom: 1px solid black; padding:0px 0px 10px 0px;"><small>${voucher.description || textDiscount}</small></td>
        <td style="border-bottom: 1px solid black; padding:0px 0px 10px 0px;"><small>${formatCurrency(-discount, currency)}</small></td>
      </tr>`
    ) : '';
  };

  const voucherRowsPlain = () => {
    const textTotalPriceBeforeDiscount = ct('sendReceiptButton.totalPriceBeforeDiscount');
    const textDiscount = ct('sendReceiptButton.discount');

    return voucher ? (`
      ${textTotalPriceBeforeDiscount} ${formatCurrency(orderTotal, currency)}
      ${voucher.description || textDiscount} ${formatCurrency(-discount, currency)}
    `) : '';
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const currentErrors = [];
    const currentDate = moment().format('DD.MM.YYYY HH:mm:ss');

    const textReceiptOrderFrom = ct('sendReceiptButton.receiptOrderFrom', { restaurantName });
    const textReceiptOrderNumber = ct('sendReceiptButton.orderNumber');
    const textServiceCharges = ct('sendReceiptButton.serviceCharges');
    const textTotalPrice = ct('sendReceiptButton.totalPrice');
    const textDate = ct('sendReceiptButton.receiptDate');
    const textItem = ct('sendReceiptButton.item');
    const textPrice = ct('sendReceiptButton.price');
    const textCompanyVAT = ct('sendReceiptButton.companyVatNumber', { VAT: receiptDetails.VAT || '' });
    const companyInfo = [
      ...receiptDetails.companyName ? [`${receiptDetails.companyName}`] : [],
      ...receiptDetails.country ? [`${receiptDetails.country}`] : [],
      ...receiptDetails.city ? [`${receiptDetails.city}`] : [],
      ...!!receiptDetails.street && !!receiptDetails.number
        ? [`${receiptDetails.street} ${receiptDetails.number}`]
        : [],
    ];

    if (!EmailValidator.validate(email)) {
      currentErrors.push(ct('shared.email.invalid'));
    }
    setErrors(currentErrors);
    if (currentErrors.length === 0) {
      setIsShowingModal(false);
      await sendEmail(webSocket, {
        to: email,
        subject: `${textReceiptOrderFrom}`,
        message: `
          <div style="width: 300px; margin: 10px auto 10px auto; border: 1px solid black;">
            <div style="margin: 10px;">
              <h3 style="text-align: center;">${textReceiptOrderFrom}</h3>
              ${companyInfo.length
                ? `<p>${companyInfo.map(infoLine => `${infoLine}<br />`).join('')}</p>`
                : ''
              }
              <div style="text-align: center;">
                <h4 style="margin-bottom: 0px;">${textReceiptOrderNumber}</h4>
                <h2 style="margin-top: 0px;">${orderNumber}</h2>
              </div>
              <table style="width: 100%; border-collapse: collapse;">
                <tr>
                  <td style="padding-bottom: 20px;">${textDate}</td>
                  <td style="padding-bottom: 20px;">${currentDate}</td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black;"><small>${textItem}</small></td>
                  <td style="border-bottom: 1px solid black;"><small>${textPrice}</small></td>
                </tr>
                ${orderedItems && sortOrderedItems(getUniqueOrderedItems(orderedItems)).map(orderedItemRow).join('')}
                <tr>
                  <td style="padding: 20px 0 0 0"></td>
                </tr>
                ${voucherRows()}
                <tr>
                  ${isServiceChargeActive
                    ? `<td style="border-bottom: 1px solid black; padding:10px 0px 10px 0px;"><small>${textServiceCharges}</small></td>
                      <td style="border-bottom: 1px solid black; padding:10px 0px 10px 0px;"><small>${formatCurrency(tipAmount, currency)}</small></td>
                    `
                    : ''
                  }
                </tr>
                <tr>
                  <td style="padding:10px 0px 10px 0px;"><b>${textTotalPrice}</b></td>
                  <td style="padding:10px 0px 10px 0px;"><b>${formatCurrency(orderTotalWithTip, currency)}</b></td>
                </tr>
                  ${hasCompanyVAT ? Array.from(taxAmountsByRate.entries()).map(kv => taxRateRow(kv[0], kv[1])).join('') : ''}
              </table>
              ${hasCompanyVAT ? `<p style="text-align: center;">${textCompanyVAT}</p>` : ''}
            </div>
          </div>
        `,
        altText: stripIndents`
          ${textReceiptOrderFrom}
          ${companyInfo.length
            ? stripIndents`
              ${companyInfo.join('\n')}`
            : undefined
          }

          ${textReceiptOrderNumber} ${orderNumber}

          ${textDate} ${currentDate}

          ${textItem} ${textPrice}

          ${orderedItems && sortOrderedItems(getUniqueOrderedItems(orderedItems)).map(orderedItemPlain).join('')}

          ${voucherRowsPlain()}

          ${isServiceChargeActive
            ? `
          ${textServiceCharges} ${formatCurrency(tipAmount, currency)}
              `
            : ''
          }
          ${textTotalPrice} ${formatCurrency(orderTotalWithTip, currency)}
          ${hasCompanyVAT ?
          `${Array.from(taxAmountsByRate.entries()).map(kv => taxRateRowPlain(kv[0], kv[1])).join('')}

          ${textCompanyVAT}`
          : ''}
          `,
      });
    }
  };

  return (
    <>
      <div className="buttons">
        <ServerActionButton
          className="btn-recipe"
          type="button"
          label={ct(hasCompanyVAT ? 'sendReceiptButton.getEmailVatReceipt' : 'sendReceiptButton.getEmailReceipt')}
          variant="secondary"
          size="large"
          onClick={() => setIsShowingModal(true)}
        >
          <SvgRecipe />
        </ServerActionButton>
      </div>

      {isShowingModal && (
        <Modal
          position="center"
          className="text-left Modal-email"
          isShown={isShowingModal}
          onSubmit={handleSubmit}
          buttons={[
            { label: ct('sendReceiptButton.buttons.cancel'),
              type: 'button',
              variant: 'secondary',
              size: 'medium',
              onClick: () => {
                setEmail('');
                setErrors([]);
                setIsShowingModal(false);
              },
            },
            { label: ct('sendReceiptButton.buttons.continue'), type: 'submit', size: 'medium' },
          ]}
          errorMessages={errors}
        >
          <h5 className="text-center">{ct(hasCompanyVAT ? 'sendReceiptButton.emailVatReceipt' : 'sendReceiptButton.emailReceipt')}</h5>
          <div className="inputBox">
            <label htmlFor="receiptEmail">
              {ct('shared.email.yourEmail')}
              <input
                id="receiptEmail"
                type="text"
                value={email}
                onChange={e => setEmail(e.currentTarget.value)}
                placeholder={ct('shared.email.placeholder')}
              />
            </label>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SendReceiptButton;
