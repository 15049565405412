export enum CurrenciesConnect {
  AUD = '036',
  BRL = '986',
  EUR = '978',
  INR = '356',
  GBP = '826',
  USD = '840',
  ZAR = '710',
  CHF = '756',
  BHD = '048',
  CAD = '124',
  CNY = '156',
  HRK = '191',
  CZK = '203',
  DKK = '208',
  HKD = '344',
  HUF = '348',
  ISL = '376',
  JPY = '392',
  KWD = '414',
  LTL = '440',
  MXN = '484',
  NZD = '554',
  NOK = '578',
  OMR = '512',
  PLN = '985',
  RON = '946',
  SAR = '682',
  SGD = '702',
  KRW = '410',
  SEK = '752',
  TRY = '949',
  AED = '784'
}
