import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectors as paymentSelectors } from 'ducks/payment';
import useConfigurableText from 'hooks/useConfigurableText';
import { Modal } from 'components/Modal';


const PaymentInProgressModal: FC = () => {
  const ct = useConfigurableText();
  const isPaymentInProgress = useSelector(paymentSelectors.getIsPaymentInProgress);
  const [isWarningShowing, setIsWarningShowing] = useState(isPaymentInProgress);

  useEffect(
    () => {
      const hideTheModal = () => setIsWarningShowing(false);
      setIsWarningShowing(isPaymentInProgress);
      if (isPaymentInProgress) {
        window.addEventListener('click', hideTheModal, { once: true });
      }

      return () => window.removeEventListener('click', hideTheModal);
    },
    [isPaymentInProgress, setIsWarningShowing]
  );

  return (
    <Modal
      position="bottom"
      isShown={isWarningShowing}
    >
      <h5>{ct('paymentInProgressModal.paymentInProgress')}</h5>
    </Modal>
  );
};

export default PaymentInProgressModal;
