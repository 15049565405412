import { PaymentBypassType } from 'types/model';
import { Event } from 'types/sockets';

import SocketManager from './socketManager';


export default async (webSocket: SocketManager, sendDirectly: boolean, sessionId: number, deviceId: number | undefined, paymentBypass = PaymentBypassType.UNKNOWN) => {
  if (sendDirectly) {
    await webSocket.emit(Event.sendDinerOrderV1, { sessionId, deviceId, paymentBypass });
  } else {
    await webSocket.emit(Event.sendDinerOrderToWaiterV1, { sessionId, deviceId });
  }
};
