import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatCurrency } from 'util/i18n';
import { selectors as ordersSelectors } from 'ducks/orders';
import { selectors as paymentSelectors } from 'ducks/payment';
import { actions as settingsActions, selectors as settingsSelectors } from 'ducks/settings';
import useConfigurableText from 'hooks/useConfigurableText';
import ServerActionButton from 'containers/ServerActionButton';
import { ReactComponent as SvgMinus } from 'style/svg/minus.svg';
import { ReactComponent as SvgPlus } from 'style/svg/plus.svg';


const servicePercentIncrement = 2.5;

const ServiceChargeCard: FC = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();
  const servicePercent = useSelector(settingsSelectors.getServiceChargePercent);
  const currency = useSelector(settingsSelectors.getCurrency);
  const serviceChargeAmount = useSelector(ordersSelectors.getServiceChargeAmount);
  const isPaymentInProgress = useSelector(paymentSelectors.getIsPaymentInProgress);

  return (
    <div className="card">
      <div className="card-box">
        <div className="dish">
          <div className="box">
            <div className="head">
              <h4 className="title">{ct('serviceChargeCard.serviceCharge')} <small>{ct('serviceChargeCard.optional')}</small></h4>
            </div>
          </div>
          <div className="box">
            <div className="body">
              <span className="price">{formatCurrency(serviceChargeAmount, currency)}</span>
            </div>
            <div className="buttons">
              <ServerActionButton
                size="small"
                variant="circle"
                type="button"
                disabled={isPaymentInProgress}
                onClick={() => dispatch(
                  settingsActions.setServiceChargePercent(servicePercent + servicePercentIncrement)
                )}
              >
                <SvgPlus />
              </ServerActionButton>
              <span className="counter">{servicePercent}%</span>
              <ServerActionButton
                size="small"
                variant="circle"
                type="button"
                disabled={isPaymentInProgress}
                onClick={() => dispatch(
                  settingsActions.setServiceChargePercent(servicePercent - servicePercentIncrement)
                )}
              >
                <SvgMinus />
              </ServerActionButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceChargeCard;
