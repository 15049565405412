import { Table } from 'types/model';
import { Event } from 'types/sockets';
import { restaurantConfig } from 'config/restaurantConfig';

import SocketManager from './socketManager';


export default async (webSocket: SocketManager, deviceId: number, table: Table) => {
  const { waiterId } = restaurantConfig;
  const { diner_session_id } = await webSocket.emit(
    Event.dinerSessionOpenV1,
    {
      allowTableSharing: true,
      deviceIds: [deviceId],
      tableIds: [table.id],
      waiterId,
    }
  );
  return { sessionId: diner_session_id };
};
