import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { MenuItem } from 'types/model';
import { isItemAvailable } from 'util/availability';
import { formatAndShortenText, formatCurrency, formatText } from 'util/i18n';
import { getMenuItemImageSrc } from 'util/image';
import { selectors as settingsSelectors } from 'ducks/settings';
import useConfigurableText from 'hooks/useConfigurableText';
import Avatar from 'components/Avatar';


interface Props {
  readonly menuItem: MenuItem;
  readonly numberOrdered?: number;
  onClick?: () => void;
}

const MenuItemCard: FC<Props> = ({ menuItem, numberOrdered = 0, onClick }) => {
  const currency = useSelector(settingsSelectors.getCurrency);
  const isPosConnected = useSelector(settingsSelectors.getIsPosConnected);
  const title = formatText(menuItem.title);
  const ct = useConfigurableText();

  const isAvailable = useMemo(
    () => isItemAvailable(menuItem, isPosConnected),
    [menuItem, isPosConnected]
  );

  return (
    <div
      className={cx('card', {
        soldout: !isAvailable,
        inBasket: (numberOrdered > 0),
        hasAvatar: menuItem.hasPhoto })
      }
      onClick={onClick}
    >
      {menuItem.hasPhoto && <Avatar src={getMenuItemImageSrc(menuItem.id)} alt={`${title} image`} /> }
      <div className="card-box">
        <div className="dish">
          <div className="box">
            <div className="head">
              <h4 className="title">{title}</h4>
            </div>
            <div className="body">
              <div>
                {numberOrdered > 0 && <span className="counter">{numberOrdered}</span>}
                {menuItem.description && <p className="description">{formatAndShortenText(menuItem.description)}</p>}
              </div>
            </div>
            <div className="footer">
              {menuItem.price !== 0 && <span className="price">{formatCurrency(menuItem.price, currency)}</span>}
              {menuItem.allergens.length > 0 && menuItem.filters.length > 0 && <span className="allergens">{ct('shared.labels.allergensFilters')}</span>}
              {menuItem.allergens.length > 0 && menuItem.filters.length < 1 && <span className="allergens">{ct('shared.labels.allergens')}</span>}
              {menuItem.allergens.length < 1 && menuItem.filters.length > 0 && <span className="allergens">{ct('shared.labels.filters')}</span>}
            </div>
          </div>
        </div>
      </div>
      { !isAvailable && <span className="label">{ct('shared.labels.soldOut')}</span>}
    </div>
  );
};

export default MenuItemCard;
