import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { actions as sessionActions } from 'ducks/session';
import EditDinerInfoModal from 'containers/EditDinerInfoModal';
import { ReactComponent as SVGUserIcon } from 'style/svg/userIcon.svg';


export const DinerInfoButton: FC = () => {
  const dispatch = useDispatch();
  return (
    <>
      <button
        type="button"
        className="profileIcon"
        onClick={() => dispatch(sessionActions.setShowEditDinerInfoModal(true))}
      >
        <SVGUserIcon />
      </button>
      <EditDinerInfoModal />
    </>);
};
