import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { selectors as ordersSelectors } from 'ducks/orders';
import useConfigurableText from 'hooks/useConfigurableText';
import { Button } from 'components/Button';
import { Counter } from 'components/Counter';


export const BasketButton: React.FC = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();
  const orderItemCount = useSelector(ordersSelectors.getCountOfAllOrderedItems);

  return (
    orderItemCount > 0 ?
      <Button
        type="button"
        variant="primary"
        size="large"
        label={ct('shared.labels.openBasket')}
        onClick={() => dispatch(push('/order'))}
      >
        <Counter value={orderItemCount} />
      </Button>
      : null
  );
};
