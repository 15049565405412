import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { PaymentMethod } from 'types/model';
import { selectors as settingsSelectors } from 'ducks/settings';

import ConnectPaymentScreen from './ConnectPaymentScreen';
import JudoPayPaymentStart from './JudoPayPaymentStart';
import MockPaymentScreen from './MockPaymentScreen';


const PaymentScreen: FC = () => {
  const paymentConfig = useSelector(settingsSelectors.getPaymentConfig);

  switch (paymentConfig && paymentConfig.type) {
    case PaymentMethod.Connect:
      return <ConnectPaymentScreen />;
    case PaymentMethod.JudoPay:
      return <JudoPayPaymentStart />;
    default:
      return <MockPaymentScreen />;
  }
};

export default PaymentScreen;
