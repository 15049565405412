import React, { FC } from 'react';

import { HOURS_PER_DAY, MIN_PER_HOUR, MS_PER_SEC, SEC_PER_MIN } from 'util/time';
import useConfigurableText from 'hooks/useConfigurableText';
import useTimer from 'hooks/useTimer';
import { ProgressBar } from 'components/ProgressBar';


export const getIntervalTillOrderReady = (remainingSeconds: number): [number, string] => {
  const remainingMinutes = Math.ceil(remainingSeconds / SEC_PER_MIN);
  const remainingHours = Math.round((remainingSeconds) / (MIN_PER_HOUR * SEC_PER_MIN));
  const remainingFullDays = Math.round(remainingSeconds / (HOURS_PER_DAY * MIN_PER_HOUR * SEC_PER_MIN));

  if (remainingMinutes === 1) {
    return [remainingMinutes, 'takeawaySchedulingOrders.texts.minute'];
  } else if (remainingMinutes < 60) {
    return [remainingMinutes, 'takeawaySchedulingOrders.texts.minutes'];
  } else if (remainingHours === 1) {
    return [remainingHours, 'takeawaySchedulingOrders.texts.hour'];
  } else if (remainingHours < 24) {
    return [remainingHours, 'takeawaySchedulingOrders.texts.hours'];
  } else if (remainingFullDays === 1) {
    return [remainingFullDays, 'takeawaySchedulingOrders.texts.day'];
  } else {
    return [remainingFullDays, 'takeawaySchedulingOrders.texts.days'];
  }
};

interface Props {
  orderScheduledTakeAwayTime: Date;
  orderAcceptedTime: Date;
  isOrderReady: boolean;
}

const TakeAwayProgressBar: FC<Props> = ({ orderScheduledTakeAwayTime, orderAcceptedTime, isOrderReady }) => {
  const currentTime = useTimer(1000);
  const ct = useConfigurableText();

  const elapsedTime = currentTime.getTime() - orderAcceptedTime.getTime();
  const remainingTime = orderScheduledTakeAwayTime.getTime() - currentTime.getTime();
  const orderPrepareTime = orderScheduledTakeAwayTime.getTime() - orderAcceptedTime.getTime();

  const fulfilledPercentage = !isOrderReady && elapsedTime < orderPrepareTime ? ((elapsedTime * 100) / orderPrepareTime) : 100;
  const remainingSeconds = !isOrderReady && elapsedTime < orderPrepareTime ? Math.ceil(remainingTime / MS_PER_SEC) : 0;
  const [remainingInterval, units] = getIntervalTillOrderReady(remainingSeconds);

  return (
    <div className="TimeLeft-progressBar">
      <ProgressBar
        fulfilledPercentage={fulfilledPercentage}
        remainingInterval={remainingInterval}
        remainingIntervalDescription={ct('takeawaySchedulingOrders.texts.timeUntilReady', { units: ct(units) })}
      />
    </div>
  );
};

export default TakeAwayProgressBar;
