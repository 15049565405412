import React, { FC, useState } from 'react';
import cx from 'classnames';

import { ReactComponent as SVGArrowSimple } from 'style/svg/arrow-simple.svg';
import { ReactComponent as SVGClock } from 'style/svg/clock.svg';

import './accordion.scss';


interface AccordionProps {
  title?: string;
  todayInfo: JSX.Element;
}

export const Accordion: FC<AccordionProps> = ({ title, todayInfo, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="Accordion">
      { title &&
        <div className="Accordion-header">
          {title}
        </div>
      }
      <div className={cx('Accordion-selected', isOpen ? 'isOpen' : 'isClosed')} onClick={toggleOpen}>
        <div><SVGClock /></div>
        <div className="item">
          {todayInfo}
        </div>
        <div><SVGArrowSimple className="icon-arrow" /></div>
      </div>
      <div className={cx('Accordion-list', isOpen ? 'isOpen' : 'isClosed')}>
        {children}
      </div>
    </div>
  );
};
