import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { actions as ordersActions, selectors as ordersSelectors } from 'ducks/orders';
import { selectors as scheduleSelectors } from 'ducks/schedule';
import { selectors as sessionSelectors } from 'ducks/session';
import updateOpenOrderScheduledTakeaway from 'effects/updateOpenOrderScheduledTakeaway';
import { useWebSocket } from 'contexts/SocketManagerContext';
import useConfigurableText from 'hooks/useConfigurableText';
import ServerActionButton from 'containers/ServerActionButton';
import TakeawayScheduleInfo from 'containers/TakeawayScheduleInfo';
import { DatePicker } from 'components/DatePicker';
import { Header } from 'components/Header';
import { ReactComponent as SvgPickUp } from 'style/svg/pickup.svg';


interface LocationState {
  continueTo?: string;
}

const AppointTime: FC = () => {
  const webSocket = useWebSocket();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const ct = useConfigurableText();

  const continueTo = location.state?.continueTo;

  const sessionId = useSelector(sessionSelectors.getSessionId);
  const preparationTime = useSelector(scheduleSelectors.getPreparationTime);
  const asapReadyTime = useSelector(scheduleSelectors.getAsapReadyTime);
  const schedule = useSelector(ordersSelectors.getSchedule);

  const [isSchedule, setSchedule] = useState<boolean>(!!(schedule?.scheduleTimestamp || !asapReadyTime));
  const [scheduleTimestamp, setScheduleTimestamp] = useState<number | undefined>(schedule?.scheduleTimestamp);

  const appointTime = async () => {
    const transition = () => {
      if (continueTo) {
        dispatch(push(continueTo));
      } else {
        history.goBack();
      }
    };

    const timestamp = isSchedule ? scheduleTimestamp : undefined;

    dispatch(ordersActions.setSchedule({
      isAsap: !isSchedule,
      scheduleTimestamp: timestamp,
    }));

    if (sessionId) {
      const success = await updateOpenOrderScheduledTakeaway(dispatch, webSocket, sessionId, timestamp);
      if (success) {
        transition();
      } else {
        // TODO: inform the user about failure
      }
    } else {
      transition();
    }
  };

  return (
    <div className="container-fixed AppointTime">
      <Header
        hideLogo
        title={ct('takeawaySchedulingOrders.texts.headerCollectionTime')}
        onClick={() => history.goBack()}
      />
      <section>
        <div className="AppointTime-box">
          <TakeawayScheduleInfo />
          <div className="inputs">
            <div className="Radiobox">
              <label className="Radio" htmlFor="asap-radio">
                <input
                  id="asap-radio"
                  type="radio"
                  checked={!isSchedule}
                  disabled={!asapReadyTime}
                  onChange={() => setSchedule(false)}
                />
                <div className="State">
                  <div>
                    { ct('takeawaySchedulingOrders.texts.asSoonAsPossible') }
                  </div>
                </div>
              </label>
              <div className="AppointTime-pickUp">
                { asapReadyTime && (
                  <div className="AppointTime-time">
                    <SvgPickUp />
                    { ct('takeawayScheduleSelect.pickupIn', { preparationTime }) }
                  </div>
                )}
                <div>
                  { !isSchedule && ct('takeawaySchedulingOrders.texts.orderWillBeReady', { readyTime: asapReadyTime }) }
                </div>
              </div>
              <label className="Radio" htmlFor="schedule-radio">
                <input
                  id="schedule-radio"
                  type="radio"
                  checked={isSchedule}
                  onChange={() => setSchedule(true)}
                />
                <div className="State">
                  <div>{ct('takeawaySchedulingOrders.texts.scheduleTime')}</div>
                </div>
              </label>
            </div>
          </div>
          { isSchedule && (
            <DatePicker
              title={ct('takeawaySchedulingOrders.labels.day')}
              setTimestamp={setScheduleTimestamp}
            />
          )}
          <ServerActionButton
            type="button"
            variant="primary"
            size="large"
            disabled={isSchedule && !scheduleTimestamp}
            onClick={appointTime}
          >
            {continueTo ? ct('takeawaySchedulingOrders.labels.continue') : ct('takeawaySchedulingOrders.labels.done')}
          </ServerActionButton>
        </div>
      </section>
    </div>
  );
};

export default AppointTime;
