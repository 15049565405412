import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { noop } from 'util/noop';


export default (onChange: (() => void) | undefined) => {
  const history = useHistory();

  useEffect(() => {
    let lastPathName: string | undefined;

    const unlisten = onChange
      ? history.listen(({ pathname }) => {
        if (pathname !== lastPathName) {
          lastPathName = pathname;
          onChange();
        }
      })
      : noop;

    return unlisten;
  }, [onChange, history]);
};
