import { GTagConfig } from 'types/ga';

import { debug } from './log';


function gtag(name: 'js', data: any): void;
function gtag(name: 'config', gaId: string, config?: GTagConfig): void;
function gtag(name: string, data: any, extras?: GTagConfig): void {
  debug('gtag call', name, data, extras);
  if (!window.dataLayer) {
    window.dataLayer = [];
    debug('gtag: window.dataLayer does not exist yet');
  }

  /* eslint-disable prefer-rest-params */
  // we have to use exact same line from the docs example to make it work
  window.dataLayer.push(arguments);
}

export const gtagJs = (data: any) => gtag('js', data);

export const gtagConfig = (gaId: string, config?: GTagConfig) => gtag('config', gaId, config);
