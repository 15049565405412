import React, { FC } from 'react';

import useConfigurableText from 'hooks/useConfigurableText';
import useDisableTimeoutModal from 'hooks/useDisableTimeoutModal';


const PaymentSuccessScreen: FC = () => {
  useDisableTimeoutModal();
  const ct = useConfigurableText();

  return (
    <div className="PageSimple">
      <div className="container">
        <>
          <div className="head">
            <h1>{ct('paymentSuccessScreen.received')}</h1>
            <p>{ct('paymentSuccessScreen.pleaseWait')}</p>
          </div>
        </>
      </div>
    </div>
  );
};

export default PaymentSuccessScreen;
