import React, { useEffect, useState } from 'react';


export const useDetectOutsideClick = (
  el: React.MutableRefObject<HTMLDivElement | null>,
  initialState: boolean
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isOpen, setIsOpen] = useState(initialState);

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (el.current && !el.current.contains(e.target as Node)) {
        setIsOpen(!isOpen);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isOpen) {
      window.addEventListener('click', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [isOpen, el]);

  return [isOpen, setIsOpen];
};
