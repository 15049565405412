import React, { FC } from 'react';

import { OrderedItem } from 'types/model';


interface Props {
  readonly orderedItem: OrderedItem;
  readonly modifiersKey: string;
  readonly modifiersTitle: string;
}

const ModifiersCard: FC<Props> = ({ orderedItem, modifiersKey, modifiersTitle }) => {
  const { menuItem } = orderedItem;

  return menuItem.modifiers.length > 0
    ? (
      <div className="modifiers" key={modifiersKey}>
        <p>{modifiersTitle}</p>
      </div>
    ) : null;
};

export default ModifiersCard;
