import { useEffect } from 'react';

import { cssEscape } from 'util/escape';
import { noop } from 'util/noop';


interface ScriptProps {
  scriptUrl?: string;
  onLoad?: () => void;
  onError?: (e: any) => void;
}


export default (props: ScriptProps) => {
  const { onLoad = noop, onError = noop, scriptUrl } = props;

  useEffect(() => {
    if (scriptUrl && !document.querySelector(`script[src="${cssEscape(scriptUrl)}"]`)) {
      const script = document.createElement('script');
      script.onload = onLoad;
      script.onerror = onError;
      script.src = scriptUrl;
      script.async = true;
      document.head.appendChild(script);
    }
  }, [scriptUrl, onError, onLoad]);
};
