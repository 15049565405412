import { Visitor } from 'types/model';
import { warn } from 'util/log';


export enum Key {
  identification = '__identification',
  visitor = '__visitor',
  dinerName = '__dinerName',
}

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
export const isLocalStorageAvailable = () => {
  let storage;
  try {
    storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0);
  }
};

const loadKey = (key: string) => {
  try {
    const identification = localStorage.getItem(key);
    if (identification === null) {
      return undefined;
    }
    return identification;
  } catch (err) {
    return undefined;
  }
};

const saveString = (key: string, value?: string) => {
  const oldValue = loadKey(key);
  if (oldValue !== value) {
    try {
      value ? localStorage.setItem(key, value) : localStorage.removeItem(key);
    } catch (err) {
      warn(`Error saving ${key} to local storage: `, err);
    }
  }
};


export const loadIdentification = () => loadKey(Key.identification);

export const saveIdentification = (identification?: string) => saveString(Key.identification, identification);

export const loadVisitor = () => {
  const visitorString = loadKey(Key.visitor);
  return visitorString && JSON.parse(visitorString);
};

export const saveVisitor = (visitor?: Visitor) => {
  const oldValue = loadVisitor();
  if (oldValue !== visitor) {
    try {
      visitor ? localStorage.setItem(Key.visitor, JSON.stringify(visitor)) : localStorage.removeItem(Key.visitor);
    } catch (err) {
      warn('Error saving visitor to local storage: ', err);
    }
  }
};

export const loadDinerName = () => loadKey(Key.dinerName);

export const saveDinerName = (dinerName?: string) => saveString(Key.dinerName, dinerName);

const createGoogleAnalyticsClientIdKey = (gaId: string) => `__googleAnalyticsClientId_${gaId}`;

export const loadGoogleAnalyticsClientId = (gaId: string) => loadKey(createGoogleAnalyticsClientIdKey(gaId));

export const saveGoogleAnalyticsClientId = (gaId: string, clientId: string) => saveString(createGoogleAnalyticsClientIdKey(gaId), clientId);
