import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { isLocalStorageAvailable, loadGoogleAnalyticsClientId, saveGoogleAnalyticsClientId } from 'localStorage';


interface ClientIdsByGaIds {
  [key: string]: string;
}

interface GoogleAnalyticsConfig {
  scriptURL: string;
  clientIdsByGaIds: ClientIdsByGaIds;
}
const GTAG_DOWNLOAD_URL = 'https://www.googletagmanager.com/gtag/js';
const STORAGE_UNAVAILABLE_CLIENT_ID = '00001';

const getClientIdByGaId = (gaId: string) => {
  if (!isLocalStorageAvailable()) {
    return STORAGE_UNAVAILABLE_CLIENT_ID;
  }
  let clientId = loadGoogleAnalyticsClientId(gaId);
  if (!clientId) {
    clientId = uuidv4();
    saveGoogleAnalyticsClientId(gaId, clientId);
  }
  return clientId;
};

export default (gaIds: string[]): GoogleAnalyticsConfig | undefined => {
  const config = useMemo(() => {
    if (!gaIds.length) {
      return undefined;
    }
    const clientIdsByGaIds = gaIds.reduce<ClientIdsByGaIds>((result, gaId) => ({
      ...result,
      [gaId]: getClientIdByGaId(gaId),
    }), {});
    const scriptURL = new URL(GTAG_DOWNLOAD_URL);
    scriptURL.searchParams.append('id', gaIds[0]);
    return {
      clientIdsByGaIds,
      scriptURL: scriptURL.href,
    };
  }, [gaIds]);

  return config;
};
