import { ActionType, createAction, getType } from 'typesafe-actions';

import { OrderedItem, TaxRate } from 'types/model';
import { summariseOrderedModifiers } from 'util/orderedItems';

import { RootState } from '.';


export interface TaxRatesState {
  taxRates: Map<number, TaxRate>;
}

// initial state
export const initialState: TaxRatesState = {
  taxRates: new Map<number, TaxRate>(),
};

// actions
export const actions = {
  setTaxRates: createAction('taxRates/setTaxRates')<TaxRate[]>(),
};

export type Actions = ActionType<typeof actions>;

// selectors
const getAllTaxRates = (state: RootState) => state.taxRates.taxRates;

const calculateTaxAmountByRate = (taxRates: Map<number, TaxRate>, items: OrderedItem[], isDineIn: boolean) => {
  const taxAmountsByRate = new Map<number, number>();

  items.forEach((item) => {
    const rateId = isDineIn
      ? item.menuItem.dineInTaxId
      : item.menuItem.takeAwayTaxId;
    const rate = taxRates.get(rateId);

    if (rate) {
      const taxRate = rate.taxRate / 100;
      const [,, modifiersPrice] = summariseOrderedModifiers(item.orderedModifiers);
      const price = item.menuItem.price + modifiersPrice;
      const netPrice = price / (1 + (taxRate / 100));
      const total = taxAmountsByRate.get(taxRate) || 0;
      taxAmountsByRate.set(taxRate, total + price - netPrice);
    }
  });

  return taxAmountsByRate;
};

export const selectors = {
  getAllTaxRates,
  calculateTaxAmountByRate,
};

// reducer
const setTaxRates = (state: TaxRatesState, taxRates: TaxRate[]): TaxRatesState => ({
  taxRates: taxRates.reduce(
    (taxRatesMap, taxRate) => taxRatesMap.set(taxRate.id, taxRate),
    state.taxRates
  ),
});

export const reducer = (state: TaxRatesState = initialState, action: Actions): TaxRatesState => {
  switch (action.type) {
    case getType(actions.setTaxRates):
      return setTaxRates(state, action.payload);

    default:
      return state;
  }
};
