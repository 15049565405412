import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tag } from 'types/model';
import { actions as tagsActions, selectors as tagsSelectors } from 'ducks/tags';
import useConfigurableText from 'hooks/useConfigurableText';
import { Button } from 'components/Button';
import { Drawer } from 'components/Drawer';
import { TagSettingSection } from 'components/TagSettingSection';
import { ReactComponent as CrossIcon } from 'style/svg/cross.svg';


const toggleTag = (prevValue: Set<number>, tag: Tag, checked: boolean) => {
  const newValue = new Set(prevValue);
  if (checked) {
    newValue.add(tag.id);
  } else {
    newValue.delete(tag.id);
  }
  return newValue;
};

interface Props {
  isOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;
}

export const Filters: FC<Props> = ({ isOpen, onClose, onOpen }) => {
  const ct = useConfigurableText();
  const dispatch = useDispatch();
  const allergens = useSelector(tagsSelectors.getAllergensSorted);
  const filters = useSelector(tagsSelectors.getFiltersSorted);
  const selectedAllergens = useSelector(tagsSelectors.getSelectedAllergens);
  const selectedFilters = useSelector(tagsSelectors.getSelectedFilters);

  const [newSelectedAllergens, setNewSelectedAllergens] = useState<Set<number>>(selectedAllergens);
  const [newSelectedFilters, setNewSelectedFilters] = useState<Set<number>>(selectedFilters);

  useEffect(() => {
    setNewSelectedAllergens(selectedAllergens);
    setNewSelectedFilters(selectedFilters);
  }, [selectedAllergens, selectedFilters]);

  const handleClearAction = () => {
    dispatch(tagsActions.setSelectedAllergens(new Set<number>()));
    dispatch(tagsActions.setSelectedFilters(new Set<number>()));
  };
  const handleApplyAction = () => {
    dispatch(tagsActions.setSelectedAllergens(newSelectedAllergens));
    dispatch(tagsActions.setSelectedFilters(newSelectedFilters));
    onClose();
  };
  const handleCancelAction = () => {
    setNewSelectedAllergens(selectedAllergens);
    setNewSelectedFilters(selectedFilters);
    onClose();
  };

  const toggleAllergen = (tag: Tag, checked: boolean) => setNewSelectedAllergens(prevValue => toggleTag(prevValue, tag, checked));
  const toggleFilter = (tag: Tag, checked: boolean) => setNewSelectedFilters(prevValue => toggleTag(prevValue, tag, checked));

  const handleDrawerClose = () => {
    handleCancelAction();
    onClose();
  };

  return (
    <Drawer
      className="big"
      title={ct('shared.labels.filters')}
      anchor="right"
      isOpen={isOpen}
      onClose={handleDrawerClose}
      onOpen={onOpen}
      head={
        <button className="btn-close" onClick={handleCancelAction}>
          <CrossIcon />
        </button>
      }
    >
      <form>
        <TagSettingSection
          title={ct('filters.hideItemsContaining')}
          tags={allergens}
          selectedTags={newSelectedAllergens}
          toggleTag={toggleAllergen}
        />
        <TagSettingSection
          title={ct('filters.dietaryRequirement')}
          tags={filters}
          selectedTags={newSelectedFilters}
          toggleTag={toggleFilter}
        />
      </form>
      <div className="buttons">
        <Button
          type="button"
          variant="secondary"
          size="large"
          label={ct('filters.clear')}
          onClick={handleClearAction}
        />
        <Button
          type="button"
          variant="primary"
          size="large"
          label={ct('filters.apply')}
          onClick={handleApplyAction}
        />
      </div>
    </Drawer>
  );
};
