import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { actions as socketActions, selectors as socketSelectors } from 'ducks/socket';
import useConfigurableText from 'hooks/useConfigurableText';
import { ReactComponent as SvgCloudDown } from 'style/svg/cloud-down.svg';
import { ReactComponent as SvgCloudUp } from 'style/svg/cloud-up.svg';


const ConnectionBox: FC<{}> = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();
  const hasLostConnection = useSelector(socketSelectors.hasLostConnection);
  const shouldShowConnectionNotification = useSelector(socketSelectors.shouldShowConnectionNotification);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (shouldShowConnectionNotification && !hasLostConnection) {
        dispatch(socketActions.setShowConnectionNotification(false));
      }
    }, 4000);
    return () => clearTimeout(timer);
  }, [dispatch, shouldShowConnectionNotification, hasLostConnection]);

  const connectionText = hasLostConnection
    ? <><SvgCloudDown />{ct('connectionBox.networkIsDown')}</>
    : <><SvgCloudUp />{ct('connectionBox.networkIsUp')}</>;

  return (
    shouldShowConnectionNotification
      ? (
        <div className={cx('ConnectionBox', hasLostConnection ? 'disconnected' : 'connected')}>
          {connectionText}
        </div>
      ) : null
  );
};

export default ConnectionBox;
