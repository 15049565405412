import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { SupportedLocale } from 'types/model';
import { selectors as i18nSelectors } from 'ducks/i18n';


const replaceTokens = (src?: string, tokens?: { [key: string]: string }) => src && tokens
  ? Object
    .keys(tokens)
    .reduce(
      (value, key) => value.replace(`{${key}}`, tokens[key]),
      src
    )
  : src;

export default () => {
  const intl = useIntl();
  const getText = useSelector(i18nSelectors.getCustomText);

  return (id: string, tokens?: {}): string => {
    const customText = getText(id);
    let tokenisedText;
    if (customText) {
      tokenisedText = customText[intl.locale as SupportedLocale];
    } else if (intl.messages) {
      tokenisedText = (intl.messages[intl.locale] as any as Record<string, string>)[id] as string;
    }
    return replaceTokens(tokenisedText, tokens) || id;
  };
};
