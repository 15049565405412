import { APIPaymentCreateSessionResponse } from './api';


export enum PaymentMethod {
  Card = 'CARD',
}

export interface CardDetails {
  paymentMethod: PaymentMethod;
}

export interface CreateTokenResponse {
  oneUseToken: string;
}

export interface SessionConfig {
  amount: string;
  currency: string;
  yourConsumerReference: string;
  yourPaymentReference: string;
  judoId: string;
}

export interface ApplePayButtonProps {
  height: number;
  width: number;
  language?: 'EN';
  type?: 'buy';
  style?: 'black';
  onClick: () => void;
}

export interface DigitalWallets {
  getApplePayButton(props: ApplePayButtonProps): HTMLElement | null;
  invokePaymentWithApplePay(reference: string, config: SessionConfig): Promise<void>;
}

export interface JudoPay {
  createCardDetails(id: string, config: object): CardDetails;
  createToken(cardDetails: CardDetails): Promise<CreateTokenResponse>;
  getPaymentMethod(): PaymentMethod;
  digitalWallets: DigitalWallets;
}

interface JudoPayConstructor {
  new (apiToken: string, isSandbox: boolean): JudoPay;
}

export interface JudoPaySession extends APIPaymentCreateSessionResponse {
  deviceId: number;
  sessionId: number;
  uuid: string;
}

declare global {
  interface Window { JudoPay: JudoPayConstructor }
}
