import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as sessionSelectors } from 'ducks/session';
import authorizeDevice from 'effects/authorizeDevice';
import { useWebSocket } from 'contexts/SocketManagerContext';


export default () => {
  const dispatch = useDispatch();
  const webSocket = useWebSocket();
  const identification = useSelector(sessionSelectors.getIdentification);

  useEffect(() => {
    (async () => {
      authorizeDevice(dispatch, webSocket, identification);
    })();
  }, [dispatch, webSocket, identification]);
};
