import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectors as socketSelectors } from 'ducks/socket';
import { Button, ButtonProps } from 'components/Button';


const ServerActionButton: FC<ButtonProps> = ({ children, disabled, ...props }) => {
  const isConnected = useSelector(socketSelectors.isConnected);
  return (
    <Button {...props} disabled={disabled || !isConnected}>
      {children}
    </Button>
  );
};

export default ServerActionButton;
