import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { getOrderDisplayNr } from 'util/getOrderDisplayNumber';
import { selectors as menuSelectors } from 'ducks/menu';
import { selectors as ordersSelectors } from 'ducks/orders';
import { selectors as paymentSelectors } from 'ducks/payment';
import { selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import closeSession from 'effects/closeSession';
import { useWebSocket } from 'contexts/SocketManagerContext';
import useConfigurableText from 'hooks/useConfigurableText';
import useYumpingo from 'hooks/useYumpingo';
import OrderSummary from 'containers/OrderSummary';
import SendReceiptButton from 'containers/SendReceiptButton';
import Yumpingo from 'containers/Yumpingo';
import { Button } from 'components/Button';


const SendOrderSuccessScreen: FC = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();
  const webSocket = useWebSocket();
  const [isYumpingoActive, yumpingoButtonHandler] = useYumpingo();

  const dineIn = useSelector(sessionSelectors.getDineIn);
  const sessionId = useSelector(sessionSelectors.getSessionId);
  const currentOrder = useSelector(ordersSelectors.getCurrentOrder);
  const mostRecentOrder = useSelector(ordersSelectors.getMostRecentPastOrder);
  const orderDisplayNumber = getOrderDisplayNr(mostRecentOrder?.id);

  const isEmailReceiptPromptEnabled = useSelector(settingsSelectors.getIsEmailReceiptPromptEnabled);
  const isVatReceiptAllowed = useSelector(paymentSelectors.getIsVatReceiptAllowed);
  const hasMoreThanOneMenu = useSelector(menuSelectors.hasMoreThanOneMenu);

  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const openDrawer = () => setIsDrawerOpened(true);
  const handleDrawerClose = () => setIsDrawerOpened(false);

  const backToMenu = async () => {
    if (dineIn) {
      dispatch(push(hasMoreThanOneMenu ? '/menus' : '/'));
    } else {
      closeSession(dispatch, webSocket, dineIn, sessionId!);
    }
  };

  const backButtonLabel = dineIn ? ct('shared.labels.backToMenu') : ct('shared.labels.finish');
  const showOrderLabel = ct('shared.labels.showOrder');

  return (
    <div className="PageSimple">
      <div className="container">
        {isYumpingoActive && <Yumpingo />}
        {!currentOrder && !!mostRecentOrder && orderDisplayNumber ? (
          <>
            <div className="head">
              <h1>{ct('sendOrderSuccessScreen.thankYou')}</h1>
              <p>{ct('sendOrderSuccessScreen.orderHasBeenSent')}</p>
            </div>
            <div className="body">
              <p>{ct('sendOrderSuccessScreen.orderNumber')}</p>
              <h1>{orderDisplayNumber}</h1>
              {isEmailReceiptPromptEnabled &&
                <SendReceiptButton
                  orderNumber={orderDisplayNumber}
                  orderedItems={mostRecentOrder.orderedItems}
                  includeVatBreakdown={isVatReceiptAllowed}
                />
              }
            </div>
            <OrderSummary
              orderNumber={orderDisplayNumber}
              orderedItems={mostRecentOrder.orderedItems}
              isOpen={isDrawerOpened}
              unpaidTipAmount={mostRecentOrder.tipAmount}
              onClose={handleDrawerClose}
            />
          </>
        ) : (
          <div className="head">{ct('sendOrderSuccessScreen.dontHaveOrders')}</div>
        )}
        <div className="buttons">
          <Button
            type="button"
            label={backButtonLabel}
            variant="secondary"
            size="medium"
            onClick={() => yumpingoButtonHandler(backToMenu)}
          />
          <Button
            type="button"
            label={showOrderLabel}
            variant="primary"
            size="medium"
            onClick={() => yumpingoButtonHandler(openDrawer)}
          />
        </div>
      </div>
    </div>
  );
};

export default SendOrderSuccessScreen;
