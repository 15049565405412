import React, { FC, ReactNode } from 'react';

import './overlay.scss';


export interface OverlayProps {
  illustration: ReactNode;
}

export const Overlay: FC<OverlayProps> = ({ illustration, children }) => (
  <div className="overlay">
    { illustration }
    <div className="body">
      { children }
    </div>
  </div>
);
