import { Event } from 'types/sockets';

import SocketManager from './socketManager';


export default async (
  webSocket: SocketManager,
  sessionId: number,
  voucherId?: number,
) => {
  await webSocket.emit(Event.updateOrderVoucherV1, { sessionId, voucherId });
};
