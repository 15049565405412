import { ActionType, createAction, getType } from 'typesafe-actions';

import { MenuItem, Order, OrderedItem, OrderStatus, Schedule, Voucher } from 'types/model';
import { compareOrders } from 'util/compare';
import { getMatchingNodeIdAndModifierItems, getMatchingNodeIdItems, summariseOrderedItem } from 'util/orderedItems';

import { selectors as menuSectionSelectors } from './menuSection';
import { selectors as settingsSelectors } from './settings';
import { selectors as vouchersSelectors } from './vouchers';
import { RootState } from '.';


export interface OrdersState {
  currentOrder?: Order;
  pastOrders?: Order[];
  currentItem?: MenuItem;
  currentOrderSchedule?: Schedule;
}

// initial state
export const initialState: OrdersState = {};

// actions
export const actions = {
  updateOrders: createAction('orders/updateOrders')<Order[]>(),
  setCurrentItem: createAction('orders/setCurrentItem')<MenuItem | undefined>(),
  setSchedule: createAction('orders/setSchedule')<Schedule | undefined>(),
};

export type Actions = ActionType<typeof actions>;

// selectors
const getCurrentOrder = (state: RootState) => state.orders.currentOrder;

const getCurrentOrderedItems = (state: RootState) => state.orders.currentOrder?.orderedItems || [];

const getCountOfAllOrderedItems = (state: RootState) => {
  const currentOrderedItems = getCurrentOrderedItems(state);
  return currentOrderedItems.length;
};

const getCountOfOrderedItem = (state: RootState) => (orderedItem: OrderedItem): number => {
  const currentOrderedItems = getCurrentOrderedItems(state);
  return getMatchingNodeIdAndModifierItems(currentOrderedItems, orderedItem).length;
};

const getCountOfOrderedItemByNodeId = (state: RootState) => (orderedItem: OrderedItem): number => {
  const currentOrderedItems = getCurrentOrderedItems(state);
  return getMatchingNodeIdItems(currentOrderedItems, orderedItem).length;
};

const getLastOrderedItemId = (state: RootState) => (orderedItem?: OrderedItem): number => {
  const currentOrderedItems = getCurrentOrderedItems(state);
  const matchingOrderedItems = orderedItem
    ? getMatchingNodeIdAndModifierItems(currentOrderedItems, orderedItem)
    : currentOrderedItems;
  return matchingOrderedItems.reverse()[0]?.id || -1;
};

const getCurrentItem = (state: RootState) => state.orders.currentItem;
const getMostRecentPastOrder = (state: RootState) =>
  state.orders.pastOrders && state.orders.pastOrders.sort(compareOrders)[0];

const getOrderTotal = (orderedItems?: OrderedItem[]) => orderedItems
  ? orderedItems.reduce((total, orderedItem) => {
    const price = summariseOrderedItem(orderedItem)[2];
    return total + (price || 0);
  }, 0) : 0;

const getCurrentOrderedItemsTotal = (state: RootState) => {
  const orderedItems = getCurrentOrderedItems(state);
  return getOrderTotal(orderedItems);
};

const getVoucherForOrder = (state: RootState, order?: Order) => {
  if (order?.voucherId) {
    const voucher = vouchersSelectors.getVoucherById(state, order.voucherId);
    return voucher || null;
  }
  return null;
};

const getCurrentVoucher = (state: RootState) => {
  const order = getCurrentOrder(state);
  return getVoucherForOrder(state, order);
};

const getMostRecentVoucher = (state: RootState) => {
  const order = getMostRecentPastOrder(state);
  return getVoucherForOrder(state, order);
};

const calculateDiscount = (order?: Order, voucher?: Voucher | null) => {
  if (!voucher) {
    return 0;
  }

  const itemsTotal = getOrderTotal(order?.orderedItems);
  // We save percentage * 100 to be consistent with how we save money. I.e. 20% -> 2000.
  return voucher.isPercentage ? Math.round(itemsTotal * voucher.amount / 10000) : voucher.amount;
};

const getCurrentDiscount = (state: RootState) => {
  const voucher = getCurrentVoucher(state);
  const order = getCurrentOrder(state);
  return calculateDiscount(order, voucher);
};

const getMostRecentDiscount = (state: RootState) => {
  const voucher = getMostRecentVoucher(state);
  const order = getMostRecentPastOrder(state);
  return calculateDiscount(order, voucher);
};

const getServiceChargeAmount = (state: RootState) => {
  const orderSubtotal = Math.max(getCurrentOrderedItemsTotal(state) - getCurrentDiscount(state), 0);
  const serviceChargeEnabled = settingsSelectors.getIsServiceChargeActive(state);
  const serviceCharge = serviceChargeEnabled
    ? settingsSelectors.getServiceChargePercent(state)
    : 0;
  return Math.round(orderSubtotal * (serviceCharge / 100));
};

const getMostRecentPastOrderTotal = (state: RootState) => {
  const mostRecentOrder = getMostRecentPastOrder(state);
  const discount = getMostRecentDiscount(state);
  return Math.max(getOrderTotal(mostRecentOrder?.orderedItems) - discount, 0) + getServiceChargeAmount(state);
};

const getCurrentOrderTotal = (state: RootState) =>
  Math.max(getCurrentOrderedItemsTotal(state) - getCurrentDiscount(state), 0) + getServiceChargeAmount(state);

const getOrderedMenuItems = (state: RootState) => {
  const orderedItems = getCurrentOrderedItems(state);
  return orderedItems.map(orderedItem => orderedItem.menuItem.id);
};

const getGoesWithItems = (state: RootState) => {
  const menuItems = menuSectionSelectors.getMenuItems(state);
  const orderedMenuItems = getOrderedMenuItems(state);
  const orderedItems = getCurrentOrderedItems(state);
  const pairedMenuItemIds = new Set<number>();
  orderedItems.forEach(orderedItem => orderedItem.menuItem.pairs.forEach(pair => pairedMenuItemIds.add(pair)));
  return Array.from(pairedMenuItemIds)
    .map(menuItemId => menuItems.find(menuItem => menuItem.id === menuItemId))
    .filter((value): value is Exclude<MenuItem, undefined> => value !== undefined)
    .filter(menuItem => menuItem.available && !orderedMenuItems.includes(menuItem.id));
};

const getSchedule = (state: RootState) => state.orders.currentOrderSchedule;

export const selectors = {
  getOrderTotal,
  getCurrentOrder,
  getMostRecentPastOrder,
  getCurrentOrderedItems,
  getCountOfAllOrderedItems,
  getCountOfOrderedItem,
  getCountOfOrderedItemByNodeId,
  getLastOrderedItemId,
  getCurrentItem,
  getServiceChargeAmount,
  getCurrentOrderTotal,
  getMostRecentPastOrderTotal,
  getOrderedMenuItems,
  getGoesWithItems,
  getSchedule,
  getCurrentVoucher,
  getCurrentDiscount,
  getMostRecentVoucher,
  getMostRecentDiscount,
};

const updateOrders = (state: OrdersState, orders: Order[]): OrdersState => ({
  ...state,
  currentOrder: orders.find(order => order.status === OrderStatus.open),
  pastOrders: orders.filter(order => order.status !== OrderStatus.open),
});

const setCurrentItem = (state: OrdersState, menuItem?: MenuItem): OrdersState => ({
  ...state,
  currentItem: menuItem,
});

const setSchedule = (state: OrdersState, schedule?: Schedule): OrdersState => ({
  ...state,
  currentOrderSchedule: schedule,
});

export const reducer = (state: OrdersState = initialState, action: Actions): OrdersState => {
  switch (action.type) {
    case getType(actions.updateOrders):
      return updateOrders(state, action.payload);
    case getType(actions.setCurrentItem):
      return setCurrentItem(state, action.payload);
    case getType(actions.setSchedule):
      return setSchedule(state, action.payload);

    default:
      return state;
  }
};
