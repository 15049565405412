import React, { FC } from 'react';

import YumpingoReminderModal from './YumpingoReminderModal';
import YumpingoReviewModal from './YumpingoReviewModal';
import YumpingoReviewPrompt from './YumpingoReviewPrompt';


const Yumpingo: FC = () => (
  <>
    <YumpingoReviewPrompt />
    <YumpingoReviewModal />
    <YumpingoReminderModal />
  </>
);

export default Yumpingo;
