import { Dispatch } from 'redux';

import { Event } from 'types/sockets';
import { error } from 'util/log';

import SocketManager from './socketManager';


export default async (
  dispatch: Dispatch,
  webSocket: SocketManager,
  sessionId: number,
  scheduledTakeaway?: number,
) => {
  try {
    await webSocket.emit(Event.updateOpenOrderScheduledTakeawayV1, { sessionId, scheduledTakeaway });
    return true;
  } catch (err) {
    error(
      'Unable to update open order scheduled takeaway',
      err,
      sessionId,
      scheduledTakeaway,
    );
    return false;
  }
};
