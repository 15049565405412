import { ActionType, createAction, getType } from 'typesafe-actions';

import { Voucher } from 'types/model';

import { RootState } from '.';


export interface VouchersState {
  vouchers: Map<number, Voucher>;
}

// initial state
export const initialState: VouchersState = {
  vouchers: new Map<number, Voucher>(),
};

// actions
export const actions = {
  setVouchers: createAction('vouchers/setVouchers')<Voucher[]>(),
  voucherUpdated: createAction('vouchers/voucherUpdated')<Voucher>(),
};

export type Actions = ActionType<typeof actions>;

// selectors
const getAllVouchers = (state: RootState) => state.vouchers.vouchers;
const getVoucherById = (state: RootState, id: number) =>
  state.vouchers.vouchers.get(id);

export const selectors = {
  getAllVouchers,
  getVoucherById,
};

// reducer
const setVouchers = (state: VouchersState, vouchers: Voucher[]): VouchersState => ({
  vouchers: vouchers.reduce(
    (vouchersMap, voucher) => vouchersMap.set(voucher.id, voucher),
    state.vouchers
  ),
});

const voucherUpdated = (state: VouchersState, voucher: Voucher): VouchersState =>
  ({ vouchers: state.vouchers.set(voucher.id, voucher) });

export const reducer = (state: VouchersState = initialState, action: Actions): VouchersState => {
  switch (action.type) {
    case getType(actions.setVouchers):
      return setVouchers(state, action.payload);
    case getType(actions.voucherUpdated):
      return voucherUpdated(state, action.payload);
    default:
      return state;
  }
};
