import { Currency, ModifierOption, ModifierType, OrderedItem, OrderedModifier } from 'types/model';

import { formatCurrency, formatText } from './i18n';

// Get unique key
export const getUniqueKey = (orderedItem: OrderedItem) => orderedItem.id ||
  `${orderedItem.menuItem.nodeId}-${JSON.stringify(orderedItem.orderedModifiers)}`;

export const summariseOrderedModifiers = (
  orderedModifiers: OrderedModifier[],
  initialValue: [string, string, number] = ['', '', 0],
  currency?: Currency,
) =>
  orderedModifiers.reduce<[string, string, number]>(
    (modifierDetails, { modifier, value: modifierValue }) => {
      let optionDetails: [string, string, number];
      if (modifier.type === ModifierType.text) {
        const stringValue = (modifierValue as string) || '<no text>';
        optionDetails = [stringValue, stringValue, 0];
      } else {
        optionDetails = (modifierValue as ModifierOption[]).reduce<[string, string, number]>(
          (details, option) => {
            const { id, title, price } = option;
            return [
              [details[0], id].filter(value => !!value).join('-'),
              [details[1], `${formatText(title)}${(currency && price) ? ' '.concat(formatCurrency(price, currency)) : ''}`].filter(value => !!value).join(', '),
              details[2] + (price || 0),
            ];
          }, ['', '', 0]
        );
      }
      return [
        [modifierDetails[0], optionDetails[0]].filter(value => !!value).join('-'),
        [modifierDetails[1], optionDetails[1]].filter(value => !!value).join('; '),
        modifierDetails[2] + optionDetails[2],
      ];
    }, initialValue
  );

// Summarise orderedItem
export const summariseOrderedItem = (orderedItem: OrderedItem) => {
  const menuItemSummary = [
    `${orderedItem.menuItem.id}`,
    formatText(orderedItem.menuItem.title),
    orderedItem.menuItem.price || 0,
  ] as [string, string, number];

  return (orderedItem.orderedModifiers.length > 0)
    ? summariseOrderedModifiers(orderedItem.orderedModifiers, menuItemSummary)
    : menuItemSummary;
};

// Count total number of orderedItems
export const countOrderedItems = (orderedItems: OrderedItem[]) => orderedItems.length;

const compareOrderedItems = (
  { menuItem: menuItem1 }: OrderedItem, { menuItem: menuItem2 }: OrderedItem,
) => menuItem1.nodeId - menuItem2.nodeId;

const hashOrderedModifiers = (orderedModifiers: OrderedModifier[]) =>
  JSON.stringify(
    orderedModifiers
      .map(om => {
        const value = typeof om.value === 'string'
          ? om.value
          : om.value.map(option => option.id).sort();
        return [om.modifier.id, value];
      })
      .sort(([a], [b]) => a === b ? 0 : (a > b ? 1 : -1))
  );

const compareOrderedModifiers = (om1: OrderedModifier[], om2: OrderedModifier[]) => {
  const hash1 = hashOrderedModifiers(om1);
  const hash2 = hashOrderedModifiers(om2);

  if (hash1 < hash2) {
    return -1;
  }
  if (hash1 > hash2) {
    return 1;
  }
  return 0;
};

const makeCompareOrderedItem = (includeModifiers = true) => (
  item1: OrderedItem, item2: OrderedItem
) => {
  const comparison = compareOrderedItems(item1, item2);
  return comparison === 0 && includeModifiers
    ? compareOrderedModifiers(item1.orderedModifiers, item2.orderedModifiers)
    : comparison;
};

// Sort ordered items
export const sortOrderedItems = (orderedItems: OrderedItem[], includeModifiers = true) => {
  const comparator = makeCompareOrderedItem(includeModifiers);
  return orderedItems.sort(comparator);
};

// find matching ordered items
const getMatchingItems = (
  orderedItems: OrderedItem[],
  orderedItem: OrderedItem,
  comparator: (item1: OrderedItem, item2: OrderedItem) => number,
) => orderedItems.filter(otherItem => !comparator(orderedItem, otherItem));

export const getMatchingNodeIdItems = (
  orderedItems: OrderedItem[],
  orderedItem: OrderedItem,
) => getMatchingItems(orderedItems, orderedItem, makeCompareOrderedItem(false));

export const getMatchingNodeIdAndModifierItems = (
  orderedItems: OrderedItem[],
  orderedItem: OrderedItem,
) => getMatchingItems(orderedItems, orderedItem, makeCompareOrderedItem());

export const getUniqueOrderedItems = (orderedItems: OrderedItem[]) =>
  orderedItems.reduce<OrderedItem[]>(
    (uniqueMatches, orderedItem) => getMatchingNodeIdAndModifierItems(uniqueMatches, orderedItem).length > 0
      ? uniqueMatches
      : uniqueMatches.concat(orderedItem),
    []
  );

export const getUniqueOrderedItemsByNodeId = (orderedItems: OrderedItem[]) =>
  orderedItems.reduce<OrderedItem[]>(
    (uniqueMatches, orderedItem) => getMatchingNodeIdItems(uniqueMatches, orderedItem).length > 0
      ? uniqueMatches
      : uniqueMatches.concat(orderedItem),
    []
  );

export const getModifierOrderedItems = (
  orderedItem: OrderedItem, currentlyOrderedItems: OrderedItem[],
) => getUniqueOrderedItems(
  getMatchingNodeIdItems(currentlyOrderedItems, orderedItem)
    .filter(otherOrderedItem => otherOrderedItem.orderedModifiers.length > 0)
);
