import React, { FC } from 'react';

import './textBox.scss';


export const TextBox: FC = ({ children }) => (
  <div className="TextBox">
    {children}
  </div>
);
