import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { convertFromRaw, Editor, EditorState } from 'draft-js';

import { formatRichText } from 'util/i18n';
import { selectors as settingsSelectors } from 'ducks/settings';
import useConfigurableText from 'hooks/useConfigurableText';
import { Header } from 'components/Header';


const PrivacyPolicyScreen: FC = () => {
  const history = useHistory();
  const ct = useConfigurableText();
  const privacyPolicy = useSelector(settingsSelectors.getPrivacyPolicy);
  const formattedText = formatRichText(privacyPolicy?.value);

  const unavailableText = ct('shared.labels.unavailable');
  return (
    <div className="container-fixed">
      <Header title="Privacy Policy" onClick={() => history.goBack()} />
      <section className="PageText">
        {formattedText
          ? (
            <Editor
              editorState={EditorState.createWithContent(convertFromRaw(formattedText))}
              onChange={() => {}}
              readOnly
            />
          )
          : unavailableText
        }
      </section>
    </div>
  );
};

export default PrivacyPolicyScreen;
