import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { APIConnectPaymentStartResponse, Path } from 'types/api';
import { CurrenciesConnect } from 'types/connect';
import { ConnectPaymentConfig, CurrencySettings } from 'types/model';
import { selectors as ordersSelectors } from 'ducks/orders';
import { selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import { post } from 'effects/api';


const ConnectPaymentScreen: FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState<APIConnectPaymentStartResponse>();
  const userInfo = useSelector(sessionSelectors.getUserInfo);
  const note = useSelector(sessionSelectors.getNote);
  const sessionId = useSelector(sessionSelectors.getSessionId);
  const deviceId = useSelector(sessionSelectors.getDeviceId);
  const tipAmount = useSelector(ordersSelectors.getServiceChargeAmount);
  const paymentConfig = useSelector(settingsSelectors.getPaymentConfig) as ConnectPaymentConfig;
  const currencySettings = useSelector(settingsSelectors.getCurrency) as CurrencySettings;

  const { url, storeName } = paymentConfig;
  const currency = (CurrenciesConnect as any)[currencySettings.code];

  useEffect(() => {
    (async () => {
      if (deviceId && sessionId) {
        const result = await post(Path.connectPaymentStart, { deviceId, sessionId, tipAmount, userInfo, note });
        setFormData(result);
      }
    })();
  }, [deviceId, sessionId, tipAmount, userInfo, note]);

  useEffect(() => {
    if (formData) {
      formRef.current?.submit();
    }
  }, [formRef, formData]);

  return (
    <>
      <iframe src={url} title="connect" name="connect" id="connect" style={{ width: '100%', height: '100%' }} />
      <form ref={formRef} action={url} target="connect" method="post" style={{ display: 'none' }}>
        <input type="hidden" name="txntype" value="sale" />
        <input type="hidden" name="timezone" value="Europe/London" />
        <input type="hidden" name="hash_algorithm" value="SHA256" />
        <input type="hidden" name="checkoutoption" value="combinedpage" />
        <input type="hidden" name="storename" value={storeName} />
        <input type="hidden" name="currency" value={currency} />
        <input type="hidden" name="chargetotal" value={formData?.chargeTotal} />
        <input type="hidden" name="responseSuccessURL" value={formData?.successUrl} />
        <input type="hidden" name="responseFailURL" value={formData?.failureUrl} />
        <input type="hidden" name="hash" value={formData?.hash} />
        <input type="hidden" name="merchantTransactionId" value={formData?.paymentId} />
        <input type="hidden" name="txndatetime" value={formData?.txnDateTime} />
      </form>
    </>
  );
};

export default ConnectPaymentScreen;
