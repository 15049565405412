import React, { FC } from 'react';


interface AvatarProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
}

const Avatar: FC<AvatarProps> = ({ src, alt, width = 132, height = 164 }) => (
  <div className="avatar">
    <img src={`${src}?w=${width}&h=${height}`} alt={alt} />
  </div>
);

export default Avatar;
