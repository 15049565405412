import currencyFormatter from 'currency-formatter';

import { Currency, I18nRichText, I18nText, SupportedLocale } from 'types/model';
import { i18nConfig } from 'config/i18nConfig';
import { stylingConfig } from 'config/stylingConfig';


const { locale, currencyFractionDigits, priceMultiplier } = i18nConfig;

// See: http://www.jacklmoore.com/notes/rounding-in-javascript/
const round = (num: number, precision = 0) =>
  precision > 0
    ? Number(`${Math.round(Number(`${num}e${precision}`))}e-${precision}`)
    : Math.round(num);

export const asCurrency = (price: number) => round(price / priceMultiplier, currencyFractionDigits);

export function formatCurrency(value = 0, currency: Currency) {
  const { code, display } = currency;
  const amount = asCurrency(value);
  return display ?
    currencyFormatter.format(amount, { code }) :
    amount.toFixed(i18nConfig.currencyFractionDigits);
}

export const formatText = (text?: I18nText) => text && text[locale as SupportedLocale];

export const formatRichText = (richText?: I18nRichText) => richText && richText[locale as SupportedLocale];

export const formatAndShortenText = (text: I18nText, maxLength: number = stylingConfig.maxTextLength) => {
  const formattedText = formatText(text);
  return formattedText && (formattedText.length > maxLength)
    ? `${formattedText.substring(0, maxLength)}...`
    : formattedText;
};
