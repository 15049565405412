import { ActionType, createAction, getType } from 'typesafe-actions';

import { DinerInfo, UserInfo, Visitor } from 'types/model';
import { createIdentification, isDineIn, isTakeAway } from 'util/dineIn';
import { loadIdentification, loadVisitor } from 'localStorage';

import { RootState } from '.';


export interface SessionState {
  identification: string;
  visitor?: Visitor;
  dinerInfo?: DinerInfo;
  sessionId?: number;
  deviceId?: number;
  userInfo?: UserInfo;
  note?: string;
  showAddNoteModal: boolean;
  showEditDinerInfoModal: boolean;
  needsReauthentication: boolean;
  timeoutModalEnabled: boolean;
}

// initial state
export const initialState: SessionState = {
  identification: loadIdentification() || createIdentification(true),
  visitor: loadVisitor(),
  needsReauthentication: false,
  showAddNoteModal: false,
  showEditDinerInfoModal: false,
  timeoutModalEnabled: true,
  dinerInfo: undefined,
};

// actions
export const actions = {
  setIdentification: createAction('session/setIdentification')<string>(),
  setVisitor: createAction('session/setVisitor')<Visitor | undefined>(),
  setDinerInfo: createAction('session/setDinerInfo')<DinerInfo | undefined>(),
  setDineIn: createAction('session/setDineIn')<boolean>(),
  setDeviceId: createAction('session/setDeviceId')<number | undefined>(),
  setSessionId: createAction('session/setSessionId')<number | undefined>(),
  setNeedsReauthentication: createAction('session/setNeedsReauthentication')<boolean>(),
  setUserInfo: createAction('session/setUserInfo')<UserInfo | undefined>(),
  setNote: createAction('session/setNote')<string | undefined>(),
  setShowAddNoteModal: createAction('session/setShowAddNoteModal')<boolean>(),
  setShowEditDinerInfoModal: createAction('session/setShowEditDinerInfoModal')<boolean>(),
  setTimeoutModalEnabled: createAction('session/setTimeoutModalEnabled')<boolean>(),
};

export type Actions = ActionType<typeof actions>;

// selectors
const getIdentification = (state: RootState) => state.session.identification;
const getVisitor = (state: RootState) => state.session.visitor;
const getDinerInfo = (state: RootState) => state.session.dinerInfo;
const getDeviceId = (state: RootState) => state.session.deviceId;
const getSessionId = (state: RootState) => state.session.sessionId;
const getDineIn = (state: RootState) => isDineIn(getIdentification(state));
const getTakeAway = (state: RootState) => isTakeAway(getIdentification(state));
const getNeedsReauthentication = (state: RootState) => state.session.needsReauthentication;
const getUserInfo = (state: RootState) => state.session.userInfo;
const getNote = (state: RootState) => state.session.note;
const getShowAddNoteModal = (state: RootState) => state.session.showAddNoteModal;
const getShowEditDinerInfoModal = (state: RootState) => state.session.showEditDinerInfoModal;
const isTimeoutModalEnabled = (state: RootState) => state.session.timeoutModalEnabled;

export const selectors = {
  getIdentification,
  getVisitor,
  getDinerInfo,
  getDeviceId,
  getSessionId,
  getDineIn,
  getTakeAway,
  getNeedsReauthentication,
  getUserInfo,
  getNote,
  getShowAddNoteModal,
  getShowEditDinerInfoModal,
  isTimeoutModalEnabled,
};

// reducer
const setIdentification = (state: SessionState, identification: string): SessionState => ({
  ...state,
  identification,
});

const setVisitor = (state: SessionState, visitor?: Visitor): SessionState => ({
  ...state,
  visitor,
});

const setDinerInfo = (state: SessionState, dinerInfo?: DinerInfo): SessionState => ({
  ...state,
  dinerInfo,
});

const setDineIn = (state: SessionState, dineIn: boolean): SessionState => ({
  ...state,
  identification: createIdentification(dineIn),
});

const setDeviceId = (state: SessionState, deviceId?: number): SessionState => ({
  ...state,
  deviceId,
});

const setSessionId = (state: SessionState, sessionId?: number): SessionState => ({
  ...state,
  sessionId,
});

const setNeedsReauthentication = (state: SessionState, needsReauthentication: boolean): SessionState => ({
  ...state,
  needsReauthentication,
});

const setUserInfo = (state: SessionState, userInfo?: UserInfo): SessionState => ({
  ...state,
  userInfo,
});

const setNote = (state: SessionState, note?: string): SessionState => ({
  ...state,
  note,
});

const setShowAddNoteModal = (state: SessionState, showAddNoteModal: boolean): SessionState => ({
  ...state,
  showAddNoteModal,
});

const setShowEditDinerInfoModal = (state: SessionState, showEditDinerInfoModal: boolean): SessionState => ({
  ...state,
  showEditDinerInfoModal,
});

const setTimeoutModalEnabled = (state: SessionState, timeoutModalEnabled: boolean): SessionState => ({
  ...state,
  timeoutModalEnabled,
});

export const reducer = (state: SessionState = initialState, action: Actions): SessionState => {
  switch (action.type) {
    case getType(actions.setIdentification):
      return setIdentification(state, action.payload);
    case getType(actions.setVisitor):
      return setVisitor(state, action.payload);
    case getType(actions.setDinerInfo):
      return setDinerInfo(state, action.payload);
    case getType(actions.setDineIn):
      return setDineIn(state, action.payload);
    case getType(actions.setDeviceId):
      return setDeviceId(state, action.payload);
    case getType(actions.setSessionId):
      return setSessionId(state, action.payload);
    case getType(actions.setNeedsReauthentication):
      return setNeedsReauthentication(state, action.payload);
    case getType(actions.setUserInfo):
      return setUserInfo(state, action.payload);
    case getType(actions.setNote):
      return setNote(state, action.payload);
    case getType(actions.setShowAddNoteModal):
      return setShowAddNoteModal(state, action.payload);
    case getType(actions.setShowEditDinerInfoModal):
      return setShowEditDinerInfoModal(state, action.payload);
    case getType(actions.setTimeoutModalEnabled):
      return setTimeoutModalEnabled(state, action.payload);
    default:
      return state;
  }
};
