import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrderedItem } from 'types/model';
import { actions as ordersActions, selectors as ordersSelectors } from 'ducks/orders';
import { selectors as paymentSelectors } from 'ducks/payment';
import { selectors as sessionSelectors } from 'ducks/session';
import addOrderedItem from 'effects/addOrderedItem';
import removeOrderedItem from 'effects/removeOrderedItem';
import { useWebSocket } from 'contexts/SocketManagerContext';
import ServerActionButton from 'containers/ServerActionButton';
import { ButtonSize } from 'components/Button/buttonTypes';
import { Counter } from 'components/Counter';
import { ReactComponent as SvgMinus } from 'style/svg/minus.svg';
import { ReactComponent as SvgPlus } from 'style/svg/plus.svg';


interface Props {
  readonly itemToOrder: OrderedItem;
  size: ButtonSize;
}

const OrderButtons: FC<Props> = ({ itemToOrder, size }) => {
  const dispatch = useDispatch();
  const sessionId = useSelector(sessionSelectors.getSessionId)!;
  const deviceId = useSelector(sessionSelectors.getDeviceId)!;
  const dineIn = useSelector(sessionSelectors.getDineIn);
  const orderedItemId = useSelector(ordersSelectors.getLastOrderedItemId)(itemToOrder);
  const orderedItemCount = useSelector(ordersSelectors.getCountOfOrderedItem)(itemToOrder);
  const isPaymentInProgress = useSelector(paymentSelectors.getIsPaymentInProgress);

  const { menuItem } = itemToOrder;
  const mustChooseModifiers = menuItem.modifiers.length &&
    itemToOrder.orderedModifiers &&
    menuItem.modifiers.length !== itemToOrder.orderedModifiers.length;

  const webSocket = useWebSocket();
  const addToOrder = () => {
    if (mustChooseModifiers) {
      dispatch(ordersActions.setCurrentItem(menuItem));
    } else {
      addOrderedItem(webSocket, sessionId, deviceId, dineIn, itemToOrder);
    }
  };
  const removeFromOrder = () => removeOrderedItem(webSocket, sessionId, deviceId, dineIn, itemToOrder, orderedItemId);

  return (
    <div className="buttons">
      <ServerActionButton
        type="button"
        variant="circle"
        size={size}
        onClick={addToOrder}
        disabled={isPaymentInProgress}
      >
        <SvgPlus />
      </ServerActionButton>
      <Counter value={orderedItemCount} />
      { (orderedItemCount > 0) &&
        <ServerActionButton
          type="button"
          variant="circle"
          size={size}
          onClick={removeFromOrder}
          disabled={isPaymentInProgress}
        >
          <SvgMinus />
        </ServerActionButton>
      }
    </div>
  );
};

export default OrderButtons;
