import { restaurantConfig } from 'config/restaurantConfig';


const { ordamoServerBase, apiPath, apiVersion } = restaurantConfig;
const apiUrl = `${ordamoServerBase}${apiPath}/${apiVersion}`;

export const getMenuItemImageSrc = (menuItemId: number) =>
  `${apiUrl}/menuItem/${menuItemId}/image`;

export const getMenuImageSrc = (menuId: number) =>
  `${apiUrl}/menu/${menuId}/image`;
