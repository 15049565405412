import React, { FC, useCallback } from 'react';

import { Path } from 'types/api';
import { JudoPay, JudoPaySession } from 'types/judoPay';
import { post } from 'effects/api';
import ServerActionButton from 'containers/ServerActionButton';


interface MockJudoPayAppleButtonProps {
  readonly session: JudoPaySession;
  readonly judo: JudoPay;
}

const MockJudoPayAppleButton: FC<MockJudoPayAppleButtonProps> = ({ session }) => {
  const handleClick = useCallback(() => {
    const { deviceId, sessionReference, sessionId, uuid } = session;

    post(
      Path.judoPayPaymentEndSession,
      {
        deviceId,
        sessionId,
        uuid,
        sessionReference,
      }
    );
  }, [session]);

  return (
    <ServerActionButton
      label="Apple Pay Mock"
      type="button"
      variant="primary"
      size="large"
      onClick={handleClick}
    />
  );
};

export default MockJudoPayAppleButton;
