import { Dispatch } from 'redux';

import { Event } from 'types/sockets';
import { actions as ordersActions } from 'ducks/orders';
import { actions as sessionActions } from 'ducks/session';
import { actions as settingsActions } from 'ducks/settings';
import { actions as tablesActions } from 'ducks/tables';
import { actions as tagsActions } from 'ducks/tags';
import { restaurantConfig } from 'config/restaurantConfig';

import SocketManager from './socketManager';


export default async (
  dispatch: Dispatch, webSocket: SocketManager, dineIn: boolean, sessionId: number
) => {
  const { waiterId } = restaurantConfig;
  if (dineIn) {
    await webSocket.emit(Event.sessionCloseV1, { sessionId, waiterId });
    dispatch(tablesActions.setCurrentTable(undefined));
  } else {
    await webSocket.emit(Event.takeAwaySessionCloseV1, { sessionId });
  }
  dispatch(settingsActions.setServiceChargePercent(undefined));
  dispatch(sessionActions.setSessionId(undefined));
  dispatch(sessionActions.setDinerInfo(undefined));
  dispatch(ordersActions.setSchedule(undefined));
  dispatch(tagsActions.setSelectedAllergens(new Set<number>()));
  dispatch(tagsActions.setSelectedFilters(new Set<number>()));
};
