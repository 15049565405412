import React, { FC, FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import EmailValidator from 'email-validator';

import { selectors as ordersSelectors } from 'ducks/orders';
import { selectors as sessionSelectors } from 'ducks/session';
import { actions as yumpingoActions, selectors as yumpingoSelectors } from 'ducks/yumpingo';
import startReview from 'effects/startYumpingoReview';
import useConfigurableText from 'hooks/useConfigurableText';
import { Modal } from 'components/Modal';
import { ReactComponent as SVGCross } from 'style/svg/Cross-mark.svg';


interface Errors {
  email?: string;
}

const YupingoReminderModal: FC = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();

  const mostRecentOrder = useSelector(ordersSelectors.getMostRecentPastOrder);
  const isShowing = useSelector(yumpingoSelectors.getIsReminderModalShowing);
  const defaultEmail = useSelector(yumpingoSelectors.getReminderEmail);
  const userInfo = useSelector(sessionSelectors.getUserInfo);

  const [errors, setErrors] = useState<Errors>();
  const [email, setEmail] = useState(defaultEmail ?? userInfo?.email ?? '');
  const [agree, setAgree] = useState(false);

  const ButtonRemindText = ct('yumpingo.buttons.remind');

  const isValid = () => email.length > 0 && agree;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const currentErrors: Errors = {
      email: ct('shared.email.invalid'),
    };
    if (EmailValidator.validate(email)) {
      delete currentErrors.email;
    }
    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors);
    } else {
      dispatch(yumpingoActions.setIsReminderModalShowing(false));
      dispatch(yumpingoActions.setReminderEmail(email));
      startReview(mostRecentOrder!.id, email);
    }
  };

  return (
    <Modal
      position="bottom"
      className="Modal-reminder"
      isShown={mostRecentOrder && isShowing}
      onSubmit={handleSubmit}
      buttons={[
        { label: ButtonRemindText, size: 'large', type: 'submit', variant: 'primary', disabled: !isValid() },
      ]}
    >
      <button
        className="review-skip"
        onClick={() => dispatch(yumpingoActions.setIsReminderModalShowing(false))}
      >
        <SVGCross />
      </button>
      <h3>{ct('shared.titles.requiredInformation')}</h3>
      <div className={cx('inputBox', errors?.email && 'hasError')}>
        <label htmlFor="userInfoEmail">
          {ct('shared.email.yourEmail')}*
          <input
            id="userInfoEmail"
            type="text"
            value={email}
            placeholder={ct('shared.email.placeholder')}
            onChange={e => setEmail(e.currentTarget.value)}
          />
        </label>
        {errors?.email && (
          <div className="error-message">*{errors.email}</div>
        )}
      </div>
      <div className="inputs">
        <div className="inputBox">
          <label className="Checkbox" htmlFor="agreeCheckbox">
            <input
              id="agreeCheckbox"
              type="checkbox"
              checked={agree}
              onChange={e => setAgree(e.currentTarget.checked)}
            />
            <div className="State">
              <div>
                I have read the <Link to="/privacy-policy">App Data Privacy Policy</Link> and the <Link to="/terms-of-use">Terms of Use</Link>.
                By ticking this checkbox, you consent to us using your data in accordance with these policies.
                We or our restaurant and marketing partners may send you updates, special offers and other marketing materials.
              </div>
            </div>
          </label>
        </div>
      </div>

    </Modal>
  );
};

export default YupingoReminderModal;
