import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import flatten from 'flat';
import localeMessages from 'i18n';

import { selectors as i18nSelectors } from 'ducks/i18n';
import App from 'containers/App';


export default () => {
  const locale = useSelector(i18nSelectors.getLocale);
  const messages = Object
    .keys(localeMessages)
    .reduce(
      (flatMessages, loc) => ({
        [loc]: flatten((localeMessages as { [key: string]: string | object })[loc]),
        ...flatMessages,
      }), {});

  return (
    <IntlProvider locale={locale} messages={messages}>
      <App />
    </IntlProvider>
  );
};
