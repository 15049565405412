import { Path } from 'types/api';
import { error, info } from 'util/log';
import { post } from 'effects/api';


export default async (orderId: number, email?: string) => {
  try {
    const response = await post(Path.yumpingo, { orderId, email });
    if (!response.url) {
      error('Posted request to Yumpingo, but unable to start review', response);
    } else if (email) {
      // TODO - notify user? Not in design but we probably should.
      info('Yumpingo review email scheduled');
    } else {
      window.location.href = response.url;
    }
  } catch (err) {
    error('Unable to post review request to Yumpingo', err);
  }
};
