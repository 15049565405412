import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as sessionActions, selectors as sessionSelectors } from 'ducks/session';
import { selectors as socketSelectors } from 'ducks/socket';
import authorizeDevice from 'effects/authorizeDevice';
import SocketManager from 'effects/socketManager';


export const SocketManagerContext = createContext<SocketManager | null>(null);

// This should never be null as this context will wrap the entire app...
// ... but I need to make it nullable so I can set the initial value to null
// due to the way the Context API works :(
export const useWebSocket = () => useContext(SocketManagerContext)!;

const SocketManagerProvider: FC = ({ children }) => {
  const [socketManager, setSocketManager] = useState<SocketManager>();
  const dispatch = useDispatch();
  const identification = useSelector(sessionSelectors.getIdentification);
  const needsReauthentication = useSelector(sessionSelectors.getNeedsReauthentication);
  const hasLostConnection = useSelector(socketSelectors.hasLostConnection);

  useEffect(
    () => {
      const manager = new SocketManager(dispatch, hasLostConnection);
      if (needsReauthentication) {
        authorizeDevice(dispatch, manager, identification);
        dispatch(sessionActions.setNeedsReauthentication(false));
      }
      setSocketManager(manager);
      return () => { manager.close(); };
    },
    [setSocketManager, dispatch, needsReauthentication, identification, hasLostConnection],
  );

  if (!socketManager) {
    return null;
  }

  return (
    <SocketManagerContext.Provider value={socketManager}>
      {children}
    </SocketManagerContext.Provider>
  );
};

export default SocketManagerProvider;
