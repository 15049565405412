import { createSelector } from 'reselect';
import { ActionType, createAction, getType } from 'typesafe-actions';

import { Menu, MenuItem, Modifier, Section } from 'types/model';
import { hasMinChoiceOfFilteredOptions, meetsFilterConditions } from 'util/filterTags';
import {
  getModifierWithAvailableOptions,
  hasMinChoiceOfAvailableOptions,
  isChoiceModifier,
} from 'util/modifiers';
import { selectors as settingsSelectors } from 'ducks/settings';
import { selectors as tagSelectors } from 'ducks/tags';

import { RootState } from '.';


export interface MenuSectionState {
  menuSections: Map<number, Section>;
  menuItems: Map<number, MenuItem>;
  modifiers: Map<number, Modifier>;
  selectedSectionId?: number;
  scrollSelectedSectionIntoView?: boolean;
}

// initial state
export const initialState: MenuSectionState = {
  menuSections: new Map<number, Section>(),
  menuItems: new Map<number, MenuItem>(),
  modifiers: new Map<number, Modifier>(),
};

// actions
export const actions = {
  setMenuSections: createAction('menuSection/setMenuSections')<Section[]>(),
  setMenuItems: createAction('menuSection/setMenuItems')<Set<MenuItem>>(),
  setModifiers: createAction('menuSection/setModifiers')<Set<Modifier>>(),
  setSelectedSectionId: createAction('menuSection/setSelectedSection')<number>(),
  setScrollSelectedSectionIntoView: createAction('menuSection/setScrollSelectedSectionIntoView')<boolean>(),
};

export type Actions = ActionType<typeof actions>;

// selectors
const getMenuSections = (state: RootState) => state.menuSection.menuSections;
const getSelectedSectionId = (state: RootState): number | undefined => state.menuSection.selectedSectionId;
const getScrollSelectedSectionIntoView = (state: RootState): boolean | undefined => state.menuSection.scrollSelectedSectionIntoView;
const getMenuItemByNodeId = (state: RootState, nodeId: number): MenuItem | undefined => state.menuSection.menuItems.get(nodeId);
const getModifierById = (state: RootState, modifierId: number): Modifier | undefined => state.menuSection.modifiers.get(modifierId);
const getMenuItemsMap = (state: RootState) => state.menuSection.menuItems;
const getMenuItems = createSelector(
  getMenuItemsMap,
  menuItems => Array.from(menuItems.values())
);

const getMenuItemsWithAvailableOptions = createSelector(
  getMenuItems,
  settingsSelectors.getIsPosConnected,
  (menuItems, isPosConnected) => menuItems
    .filter(menuItem => !isPosConnected || !!menuItem.posId)
    .filter((menuItem) => {
      const choiceModifiers = menuItem.modifiers.filter(isChoiceModifier);
      return choiceModifiers.every(choiceModifier => hasMinChoiceOfAvailableOptions(choiceModifier, isPosConnected));
    })
    .map(menuItem => ({
      ...menuItem,
      modifiers: menuItem.modifiers.flatMap(modifier => getModifierWithAvailableOptions(modifier, isPosConnected)),
    }))
);

const getFilteredMenuItems = createSelector(
  getMenuItemsWithAvailableOptions,
  tagSelectors.getSelectedAllergens,
  tagSelectors.getSelectedFilters,
  (menuItems, selectedAllergens, selectedFilters) =>
    selectedAllergens.size === 0 && selectedFilters.size === 0
      ? menuItems
      : menuItems
        .filter(menuItem =>
          meetsFilterConditions(menuItem.allergens, menuItem.filters, selectedAllergens, selectedFilters)
          && menuItem.modifiers.every(modifier => hasMinChoiceOfFilteredOptions(modifier, selectedAllergens, selectedFilters))
        )
);

const getFilteredMenuItemsMap = createSelector(
  getFilteredMenuItems,
  items => new Map(items.map(item => [item.nodeId, item]))
);

const isSectionNotEmpty = (section: Section) => section.menuItems.length !== 0 || section.subSections.length !== 0;

const getFilteredMenuSection = (section: Section, filteredMenuItems: Map<number, MenuItem>): Section => {
  const menuItems = section.menuItems.flatMap(item => filteredMenuItems.get(item.nodeId) ?? []);
  const subSections = section.subSections
    .map(subSection => getFilteredMenuSection(subSection, filteredMenuItems))
    .filter(isSectionNotEmpty);
  return ({ ...section, menuItems, subSections });
};

type GetFilteredMenuSections = (state: RootState) => Section[];

const createGetFilteredMenuSections = (menu: Menu): GetFilteredMenuSections => createSelector(
  getMenuSections,
  getFilteredMenuItemsMap,
  (menuSections, filteredMenuItems) => menu.sectionIds
    .map(sectionId => {
      const menuSection = menuSections.get(sectionId);
      return menuSection && getFilteredMenuSection(menuSection, filteredMenuItems);
    })
    .filter(section => section && isSectionNotEmpty(section)) as Section[]
);

export const selectors = {
  getMenuSections,
  createGetFilteredMenuSections,
  getSelectedSectionId,
  getScrollSelectedSectionIntoView,
  getMenuItemByNodeId,
  getModifierById,
  getMenuItems,
};

// reducer
const setMenuSections = (state: MenuSectionState, menuSections: Section[]): MenuSectionState => ({
  ...state,
  menuSections: new Map(menuSections.map(menuSection => [menuSection.id, menuSection])),
});

const setMenuItems = (state: MenuSectionState, menuItems: Set<MenuItem>): MenuSectionState => ({
  ...state,
  menuItems: Array.from(menuItems.values()).reduce(
    (menuItemMap, menuItem) => menuItemMap.set(menuItem.nodeId, menuItem),
    state.menuItems,
  ),
});

const setModifiers = (state: MenuSectionState, modifiers: Set<Modifier>): MenuSectionState => ({
  ...state,
  modifiers: Array.from(modifiers.values()).reduce(
    (modifiersMap, modifier) => modifiersMap.set(modifier.id, modifier),
    state.modifiers,
  ),
});

const setSelectedSectionId = (state: MenuSectionState, selectedSectionId: number): MenuSectionState => ({
  ...state,
  selectedSectionId,
});

const setScrollSelectedSectionIntoView = (state: MenuSectionState, scrollSelectedSectionIntoView: boolean): MenuSectionState => ({
  ...state,
  scrollSelectedSectionIntoView,
});

export const reducer = (state: MenuSectionState = initialState, action: Actions): MenuSectionState => {
  switch (action.type) {
    case getType(actions.setMenuSections):
      return setMenuSections(state, action.payload);
    case getType(actions.setMenuItems):
      return setMenuItems(state, action.payload);
    case getType(actions.setModifiers):
      return setModifiers(state, action.payload);
    case getType(actions.setSelectedSectionId):
      return setSelectedSectionId(state, action.payload);
    case getType(actions.setScrollSelectedSectionIntoView):
      return setScrollSelectedSectionIntoView(state, action.payload);

    default:
      return state;
  }
};
