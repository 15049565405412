import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { range, sortedUniq } from 'lodash';
import moment from 'moment';

import { DayOfTheWeek, Period } from 'types/model';
import { weekdayNumberToDayOfTheWeek } from 'util/time';
import { selectors as ordersSelectors } from 'ducks/orders';
import { selectors as scheduleSelectors } from 'ducks/schedule';
import useConfigurableText from 'hooks/useConfigurableText';

import { Dropdown } from '../Dropdown';

import './datepicker.scss';


interface DatePickerProps {
  title?: string;
  setTimestamp: (timestamp?: number) => void;
}

interface Day {
  date: number;
  weekday?: DayOfTheWeek;
  isAvailable: boolean;
}

const AVAILABLE_MINUTES_STEP = 10;

const getAvailableMinutes = (
  day: Day,
  takeawayPeriods: Map<DayOfTheWeek, Period[]>,
  preparationTime: number,
  hour?: number
): number[] => {
  const dayPeriods = day.weekday ? takeawayPeriods.get(day.weekday) : undefined;

  if (!dayPeriods || hour === undefined) {
    return [];
  }

  const nowPlusPrepTime = moment().add(preparationTime, 'm');
  const possibleMinutes = range(
    nowPlusPrepTime.date() === day.date && nowPlusPrepTime.hour() === hour
      ? Math.ceil(nowPlusPrepTime.minute() / AVAILABLE_MINUTES_STEP) * AVAILABLE_MINUTES_STEP
      : 0,
    60,
    AVAILABLE_MINUTES_STEP
  );

  const selectedBaseTime = hour * 60;
  return possibleMinutes.filter((minutes) => {
    const testTime = selectedBaseTime + minutes;
    return !!dayPeriods.find(
      ({ startTime, endTime }) => testTime >= startTime && testTime <= endTime
    );
  });
};

const getAvailableHours = (
  day: Day,
  takeawayPeriods: Map<DayOfTheWeek, Period[]>,
  preparationTime: number
): number[] => {
  const dayPeriods = day.weekday ? takeawayPeriods.get(day.weekday) : undefined;

  if (!dayPeriods) {
    return [];
  }

  const now = moment();
  const nowDate = now.date();
  const nowPlusPrepTime = now.clone().add(preparationTime, 'm');

  if (nowDate === day.date && nowDate !== nowPlusPrepTime.date()) {
    // preparation time makes it impossible to still prepare the food today -> no available periods today anymore
    return [];
  }

  const nearestPossibleHour = nowPlusPrepTime.hour();

  const availableHours: number[] = [];
  dayPeriods.forEach(({ startTime, endTime }) => {
    const periodStartHour = Math.trunc(startTime / 60);
    const periodEndHour = Math.trunc(endTime / 60);

    let startHour;
    if (nowPlusPrepTime.date() === day.date && nearestPossibleHour > periodStartHour) {
      if (nearestPossibleHour <= periodEndHour) {
        startHour = Math.max(periodStartHour, nearestPossibleHour);
      } // else -> the period has already completely passed, leave startHour undefined
    } else {
      startHour = periodStartHour;
    }

    if (startHour !== undefined) {
      const periodRange = [...range(startHour, periodEndHour), periodEndHour];

      if (
        nowPlusPrepTime.date() === day.date &&
        startHour === nearestPossibleHour &&
        getAvailableMinutes(day, takeawayPeriods, preparationTime, startHour).length === 0
      ) {
        // the current hour of today can't be offered if there aren't any available minute slots left
        periodRange.shift();
      }

      availableHours.push(...periodRange);
    }
  });

  return sortedUniq(availableHours.sort((h1, h2) => h1 - h2));
};

const getFollowingDays = (
  scheduleLimitDays: number,
  takeawayPeriods: Map<DayOfTheWeek, Period[]>,
  preparationTime: number
): Day[] => {
  const today = moment();
  const followingDays = [today];

  for (let i = 0; i < scheduleLimitDays; i++) {
    followingDays.push(moment(followingDays[i]).add(1, 'd'));
  }

  const days = followingDays.map(day => {
    let weekday;
    try {
      weekday = weekdayNumberToDayOfTheWeek(day.isoWeekday() - 1); // with isoWeekday, Monday is 1, Sunday is 7
    } catch (e) {
      // nothing to do
    }
    const isAvailable = weekday ? takeawayPeriods.has(DayOfTheWeek[weekday]) : false;

    return ({
      date: day.date(),
      weekday,
      isAvailable,
    });
  });

  const todayDay = days[0];
  todayDay.isAvailable = todayDay.isAvailable &&
    getAvailableHours(todayDay, takeawayPeriods, preparationTime).length !== 0;

  return days;
};

export const DatePicker: FC<DatePickerProps> = ({ title, setTimestamp }) => {
  const ct = useConfigurableText();
  const preparationTime = useSelector(scheduleSelectors.getPreparationTime);
  const takeawayPeriods = useSelector(scheduleSelectors.getTakeawayPeriodsGroupedByDay);
  const scheduleLimitDays = useSelector(scheduleSelectors.getScheduleLimitDays);
  const schedule = useSelector(ordersSelectors.getSchedule);

  const followingDays = useMemo(
    () => getFollowingDays(scheduleLimitDays, takeawayPeriods, preparationTime),
    [scheduleLimitDays, takeawayPeriods, preparationTime]
  );

  const scheduledMoment = schedule?.scheduleTimestamp && moment.utc(schedule?.scheduleTimestamp).local();
  const scheduledDay = scheduledMoment && followingDays.find(({ date }) => date === scheduledMoment.date());
  const scheduledHour = scheduledMoment && scheduledDay ? scheduledMoment.hour().toString(10) : undefined;
  const scheduledMinute = scheduledMoment && scheduledHour
    ? scheduledMoment.minute().toString(10).padStart(2, '0')
    : undefined;

  const [selectedDay, setSelectedDay] = useState(scheduledDay || followingDays.find(({ isAvailable }) => isAvailable));
  const [selectedHour, setSelectedHour] = useState<string | undefined>(scheduledHour);
  const [selectedMinute, setSelectedMinute] = useState<string | undefined>(scheduledMinute);

  const selectedWeekday = useMemo(
    () => {
      if (selectedDay) {
        if (moment().date() === selectedDay.date) {
          return ct('takeawayScheduleInfo.today');
        } else {
          return ct(`takeawayScheduleInfo.daysOfTheWeek.${selectedDay.weekday}`);
        }
      }
      return undefined;
    }, [ct, selectedDay]
  );

  const selectedTime = useMemo(
    () => selectedHour && selectedMinute && moment()
      .hour(parseInt(selectedHour, 10))
      .minute(parseInt(selectedMinute, 10))
      .toDate()
      .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    [selectedHour, selectedMinute]
  );

  const availableHours = useMemo(
    () => selectedDay ? getAvailableHours(selectedDay, takeawayPeriods, preparationTime) : [],
    [selectedDay, takeawayPeriods, preparationTime]
  );

  const availableMinutes = useMemo(
    () => selectedDay && selectedHour
      ? getAvailableMinutes(selectedDay, takeawayPeriods, preparationTime, parseInt(selectedHour, 10))
      : [],
    [selectedDay, takeawayPeriods, preparationTime, selectedHour]
  );

  useEffect(() => {
    if (selectedDay && selectedHour && selectedMinute) {
      const now = moment();
      const scheduled = now.clone();

      const date = selectedDay.date;
      if (date < now.date()) {
        scheduled.add(1, 'M');
      }
      scheduled
        .date(date)
        .hour(parseInt(selectedHour, 10))
        .minute(parseInt(selectedMinute, 10))
        .second(0)
        .millisecond(0);

      setTimestamp(scheduled.utc().valueOf());
    } else {
      setTimestamp();
    }
  }, [setTimestamp, selectedDay, selectedHour, selectedMinute]);

  const selectHour = (hour?: string, day = selectedDay) => {
    if (selectedDay && hour && day) {
      const newHourAvailableMinutes = getAvailableMinutes(day, takeawayPeriods, preparationTime, parseInt(hour, 10));
      if (selectedMinute && !newHourAvailableMinutes.includes(parseInt(selectedMinute, 10))) {
        setSelectedMinute(undefined);
      }
    } else {
      setSelectedMinute(undefined);
    }
    setSelectedHour(hour);
  };

  const selectDay = (day: Day) => {
    const newDayAvailableHours = getAvailableHours(day, takeawayPeriods, preparationTime);
    if (selectedHour) {
      if (newDayAvailableHours.includes(parseInt(selectedHour, 10))) {
        selectHour(selectedHour, day);
      } else {
        selectHour(undefined);
      }
    }

    setSelectedDay(day);
  };

  return (
    <div className="Datepicker">
      <h3>{title}</h3>
      <ul className="Datepicker-line">
        { followingDays.map((day, key) => (
          <li
            key={key}
            className={cx('item', {
              active: selectedDay && day.date === selectedDay.date,
              disabled: !day.isAvailable,
            })}
            onClick={day.isAvailable ? () => selectDay(day) : undefined}
          >
            <span className="title">{ct(`takeawayScheduleInfo.shortDaysOfTheWeek.${day.weekday}`).toUpperCase()}</span>
            <span className="body">{day.date}</span>
          </li>
        ))}
      </ul>

      { selectedWeekday ? ( // days can't be unselected thus no selected day means there is none available to begin with
        <>
          <h2 className="Selector-title">
            { selectedTime
              ? ct('takeawaySchedulingOrders.texts.dateAtTime', { date: selectedWeekday, time: selectedTime })
              : selectedWeekday
            }
          </h2>

          <div className="Dropdown-box">
            <Dropdown
              title={ct('takeawaySchedulingOrders.labels.hours')}
              placeholder={ct('takeawaySchedulingOrders.labels.selectHours')}
              options={availableHours.map(String)}
              selectedOption={selectedHour}
              onSelect={selectHour}
            />
            <Dropdown
              title={ct('takeawaySchedulingOrders.labels.minutes')}
              placeholder={ct('takeawaySchedulingOrders.labels.selectMinutes')}
              options={availableMinutes.map(minute => String(minute).padStart(2, '0'))}
              selectedOption={selectedMinute}
              onSelect={setSelectedMinute}
              disabled={!selectedHour}
            />
          </div>
        </>
      ) : (
        ct('takeawaySchedulingOrders.texts.neitherDayIsAvailable')
      )}
    </div>
  );
};
