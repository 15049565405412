import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { actions as paymentActions } from 'ducks/payment';
import { actions as sessionActions, selectors as sessionSelectors } from 'ducks/session';
import useConfigurableText from 'hooks/useConfigurableText';
import ServerActionButton from 'containers/ServerActionButton';
import { Header } from 'components/Header';


const PhoneNumber: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ct = useConfigurableText();

  const userInfo = useSelector(sessionSelectors.getUserInfo);
  const dineIn = useSelector(sessionSelectors.getDineIn);
  const [orderSent, setOrderSent] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(userInfo?.phoneNumber ?? '');

  const buttonContinueText = dineIn ? ct('shared.labels.pay') : ct('shared.labels.payNow');

  const handleBackClick = () => {
    dispatch(sessionActions.setUserInfo({ phoneNumber }));
    history.goBack();
  };

  const isFormValid = () => phoneNumber.length > 0;

  const handleSubmit = () => {
    setOrderSent(true);
    dispatch(paymentActions.setIsVatReceiptAllowed(true));
    dispatch(sessionActions.setUserInfo({ phoneNumber }));
    dispatch(push('/payment'));
  };

  return (
    <div className="container-fixed Order">
      <Header
        hideLogo
        title={ct('shared.titles.requiredInformation')}
        onClick={orderSent ? undefined : handleBackClick}
      />
      <div className="Order-container">
        <div className="inputBox">
          <label htmlFor="userInfoContactPhone">
            {ct('userInfo.yourNumber')}
            <input
              id="userInfoContactPhone"
              type="tel"
              value={phoneNumber}
              placeholder={ct('userInfo.yourNumberPlaceholder')}
              disabled={orderSent}
              onChange={e => setPhoneNumber(e.currentTarget.value)}
            />
          </label>
          <small>{ct('phoneNumberScreen.textWillBeSent')}</small>
        </div>
      </div>
      <div className="OrderDetails-buttons">
        <div className="buttons">
          <ServerActionButton
            label={buttonContinueText}
            size="large"
            type="submit"
            variant="primary"
            disabled={orderSent || !isFormValid()}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
