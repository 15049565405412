import React, { FC } from 'react';

import { getDiffInCalendarDays } from 'util/time';
import useConfigurableText from 'hooks/useConfigurableText';
import useTimer from 'hooks/useTimer';


interface Props {
  orderScheduledTakeAwayTime: Date;
}

const ScheduledOrderReadyTime: FC<Props> = ({ orderScheduledTakeAwayTime }) => {
  const currentTime = useTimer(1000);
  const ct = useConfigurableText();

  const readyDiffDays = getDiffInCalendarDays(orderScheduledTakeAwayTime, currentTime);

  const readyTime = orderScheduledTakeAwayTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const getEstimatedReadyText = (): string => {
    switch (readyDiffDays) {
      case 0:
        return ct('takeawaySchedulingOrders.texts.yourOrderWillBeReadyWeekday',
          {
            day: ct('takeawaySchedulingOrders.texts.today'),
            time: readyTime,
          });
      case 1:
        return ct('takeawaySchedulingOrders.texts.yourOrderWillBeReadyWeekday',
          {
            day: ct('takeawaySchedulingOrders.texts.tomorrow'),
            time: readyTime,
          });
      default:
        return ct('takeawaySchedulingOrders.texts.yourOrderWillBeReadyDate',
          {
            date: orderScheduledTakeAwayTime.toLocaleDateString([]),
            time: readyTime,
          });
    }
  };

  return (
    <>
      {getEstimatedReadyText()}
    </>
  );
};

export default ScheduledOrderReadyTime;
