import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { selectors as paymentSelectors } from 'ducks/payment';
import useConfigurableText from 'hooks/useConfigurableText';
import { Button } from 'components/Button';
import { Header } from 'components/Header';
import { ReactComponent as SVGPaymentError } from 'style/svg/payment-error.svg';


const PaymentFailureScreen = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();
  const error = useSelector(paymentSelectors.getError);

  const handleBackToBasket = () => {
    dispatch(push('/order'));
  };

  const handleTryAgain = () => {
    dispatch(push('/payment'));
  };

  return (
    <div className="paymentFailure container-fixed">
      <Header hideLogo title={ct('paymentFailureScreen.title')} />
      <section>
        <div className="head">
          <SVGPaymentError />
        </div>
        <div className="body">
          <h5 className="title">{ct('paymentFailureScreen.oops')}</h5>
          <p>{ct('paymentFailureScreen.error')}</p>
          {error && (
            <div className="orderError">
              <p>{`${error.code}: ${error.message}`}</p>
            </div>
          )}
        </div>
        <div className="buttons">
          <Button
            type="button"
            variant="secondary"
            size="large"
            label={ct('shared.labels.backToBasket')}
            onClick={handleBackToBasket}
          />
          <Button
            type="button"
            variant="primary"
            size="large"
            label={ct('shared.labels.tryAgain')}
            onClick={handleTryAgain}
          />
        </div>
      </section>
    </div>
  );
};

export default PaymentFailureScreen;
