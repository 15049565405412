import { useSelector } from 'react-redux';

import { selectors as settingsSelectors } from 'ducks/settings';
import useHistoryListener from 'hooks/useHistoryListener';

import useGoogleAnalytics from './useGoogleAnalytics';


export default () => {
  const gaIds = useSelector(settingsSelectors.getGoogleAnalyticsIds);

  const ga = useGoogleAnalytics(gaIds);

  useHistoryListener(ga?.sendData);
};
