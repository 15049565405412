import React, { FC, Ref, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Menu } from 'types/model';
import { selectors as menuSectionSelectors } from 'ducks/menuSection';
import { selectors as tagsSelectors } from 'ducks/tags';
import useConfigurableText from 'hooks/useConfigurableText';
import MenuListSection from 'containers/MenuListSection';


interface MenuListProps {
  menu: Menu;
}

const MenuList: FC<MenuListProps> = ({ menu }) => {
  const ulRef: Ref<HTMLUListElement> = useRef(null);
  const ct = useConfigurableText();

  const getFilteredMenuSections = useMemo(() => menuSectionSelectors.createGetFilteredMenuSections(menu), [menu]);
  const menuSections = useSelector(getFilteredMenuSections);
  const hasSelectedTags = useSelector(tagsSelectors.hasSelectedTags);

  return (
    menuSections.length === 0 ?
      <span className="message">
        {hasSelectedTags
          ? ct('filters.allMenuItemsFilteredOut')
          : ct('menuListScreen.noDishesAvailable')
        }
      </span>
      :
      <ul ref={ulRef}>
        {menuSections
          .map(section =>
            <MenuListSection key={section.id} section={section} parentRef={ulRef} />
          )
        }
      </ul>
  );
};

export default MenuList;
