import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { ChoiceModifier, Modifier, ModifierSubtype, ModifierType } from 'types/model';
import { compareModifierOptions, compareModifiers } from 'util/compare';
import { meetsFilterConditions } from 'util/filterTags';
import { formatCurrency, formatText } from 'util/i18n';
import { actions as ordersActions, selectors as ordersSelectors } from 'ducks/orders';
import { selectors as paymentSelectors } from 'ducks/payment';
import { selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import { selectors as tagsSelectors } from 'ducks/tags';
import addOrderedItem from 'effects/addOrderedItem';
import { useWebSocket } from 'contexts/SocketManagerContext';
import useConfigurableText from 'hooks/useConfigurableText';
import useModifierState from 'hooks/useModifierState';
import ServerActionButton from 'containers/ServerActionButton';
import { Button } from 'components/Button';
import { Counter } from 'components/Counter';
import { Drawer } from 'components/Drawer';
import { ReactComponent as SvgMinus } from 'style/svg/minus.svg';
import { ReactComponent as SvgPlus } from 'style/svg/plus.svg';


const appendComma = (text: string | undefined, add: boolean) => text && add ? `${text},` : text;

const MenuItemDetails: FC = () => {
  const webSocket = useWebSocket();
  const dispatch = useDispatch();
  const ct = useConfigurableText();

  const currentItem = useSelector(ordersSelectors.getCurrentItem);
  const sessionId = useSelector(sessionSelectors.getSessionId)!;
  const deviceId = useSelector(sessionSelectors.getDeviceId)!;
  const dineIn = useSelector(sessionSelectors.getDineIn);
  const currency = useSelector(settingsSelectors.getCurrency);
  const isPaymentInProgress = useSelector(paymentSelectors.getIsPaymentInProgress);
  const selectedAllergens = useSelector(tagsSelectors.getSelectedAllergens);
  const selectedFilters = useSelector(tagsSelectors.getSelectedFilters);

  const {
    orderedModifiers,
    setModifierValue,
    isChecked,
    minChoicesSelected,
  } = useModifierState(currentItem);
  const [quantity, setQuantity] = useState(1);

  const itemToOrder = currentItem && {
    menuItem: currentItem,
    orderedModifiers,
  };

  // set the initial value to 1 when the item is changed
  useEffect(() => {
    if (currentItem) setQuantity(1);
  }, [currentItem]);

  const addToOrder = () => {
    if (!itemToOrder) return;
    addOrderedItem(webSocket, sessionId, deviceId, dineIn, itemToOrder, quantity);
    setQuantity(1);
    dispatch(ordersActions.setCurrentItem(undefined));
  };

  const displayOptions = (modifier: ChoiceModifier) => {
    const options = modifier.options
      .filter(modifierOption => meetsFilterConditions(modifierOption.allergens, modifierOption.filters, selectedAllergens, selectedFilters))
      .sort(compareModifierOptions)
      .map(option => {
        const isSelection = modifier.subType === ModifierSubtype.selection;
        const inputId = `${modifier.id}-${option.id}`;
        return (
          <label key={inputId} className={cx(isSelection ? 'Radiobox' : 'Checkbox', (option.allergens.length > 0 || option.filters.length > 0) && 'withAllergens')} htmlFor={inputId}>
            <input
              id={inputId}
              type={isSelection ? 'radio' : 'checkbox'}
              value={option.id}
              name={String(modifier.id)}
              checked={isChecked(modifier.type, modifier.id, option)}
              onChange={() => { setModifierValue(modifier, option); }}
            />
            <div className="State">
              <span>
                {formatText(option.title)}
                {(option.allergens.length > 0 || option.filters.length > 0) &&
                <div className="allergens">
                  {option.allergens.map((allergen, index) =>
                    <small key={allergen.id} className="allergen">
                      {appendComma(formatText(allergen.title), index !== option.allergens.length - 1)}
                    </small>
                  )}
                  {option.filters.map((filter, index) =>
                    <small key={filter.id} className="allergen">
                      {appendComma(formatText(filter.title), index !== option.filters.length - 1)}
                    </small>
                  )}
                </div>
                }
              </span>
              {option.price ? <span className="price">&#43; {formatCurrency(option.price, currency)}</span> : ''}
            </div>
          </label>
        );
      });
    if (options.length > 0) {
      return options;
    }

    return ct('filters.allModifierOptionsFilteredOut');
  };

  const modifierInstructions = (modifier: Modifier) => {
    if (modifier.subType === ModifierSubtype.selection) {
      return 'Select one option:';
    }
    const { minChoices, maxChoices } = modifier as ChoiceModifier;
    if (minChoices && maxChoices) {
      return `Choose ${minChoices}-${maxChoices} options:`;
    } else if (minChoices) {
      return `Choose at least ${minChoices} options:`;
    } else if (maxChoices) {
      return `Choose at most ${maxChoices} options:`;
    }
    return 'Choose any number of options:';
  };

  return (
    <>
      {currentItem &&
        <Drawer
          isOpen={!!currentItem}
          onClose={() => dispatch(ordersActions.setCurrentItem(undefined))}
        >
          <div className={cx('Drawer-card', { hasModifier: (currentItem.modifiers.length > 0) })}>
            <div className="head">
              <h4 className="title">{formatText(currentItem.title)}</h4>
              {currentItem.price !== 0 && <span className="price">{formatCurrency(currentItem.price, currency)}</span>}
            </div>
            <div className="body">
              {currentItem.description && <p className="description">{formatText(currentItem.description)}</p>}
            </div>
            <div className="footer">
              <div className="allergensFilterBox">
                {currentItem.allergens.length > 0 &&
                  <div className="allergens">
                    <span className="title">{ct('shared.labels.allergens')}:</span>
                    {currentItem.allergens.map((allergen, index) =>
                      <span key={allergen.id} className="allergen">
                        {appendComma(formatText(allergen.title), index !== currentItem.allergens.length - 1)}
                      </span>
                    )}
                  </div>
                }
                {currentItem.filters.length > 0 &&
                  <div className="allergens">
                    <span className="title">{ct('shared.labels.filters')}:</span>
                    {currentItem.filters.map((filter, index) =>
                      <span key={filter.id} className="allergen">
                        {appendComma(formatText(filter.title), index !== currentItem.filters.length - 1)}
                      </span>
                    )}
                  </div>
                }
              </div>
              <div className="buttons">
                { (quantity > 0) &&
                  <Button
                    type="button"
                    variant="circle"
                    size="medium"
                    onClick={() => setQuantity(quantity - 1)}
                  >
                    <SvgMinus />
                  </Button>
                }
                <Counter value={quantity} />
                <Button
                  type="button"
                  variant="circle"
                  size="medium"
                  onClick={() => setQuantity(quantity + 1)}
                >
                  <SvgPlus />
                </Button>
              </div>
            </div>
          </div>
          <div className="scrollArea">
            {currentItem.modifiers
              .sort(compareModifiers)
              .map(modifier => (
                <div key={modifier.id}>
                  <h5>{formatText(modifier.title)}</h5>
                  <small>
                    {modifierInstructions(modifier)}
                  </small>
                  <fieldset className="inputs">
                    {modifier.type === ModifierType.text
                      ? (
                        <div className="inputBox">
                          <input
                            type="text"
                            onChange={(event) => { setModifierValue(modifier, event.target.value.trim()); }}
                          />
                        </div>
                      )
                      : displayOptions(modifier as ChoiceModifier)
                    }
                  </fieldset>
                </div>
              ))
            }
          </div>
          <ServerActionButton
            label={isPaymentInProgress ? ct('orderScreen.button.paymentInProgress') : ct('shared.labels.addToOrder')}
            type="button"
            variant="primary"
            size="large"
            onClick={addToOrder}
            disabled={!minChoicesSelected() || isPaymentInProgress}
          >
            <Counter value={quantity} />
          </ServerActionButton>
        </Drawer>
      }
    </>
  );
};

export default MenuItemDetails;
