import { ActionType, createAction, getType } from 'typesafe-actions';

import { CustomTexts, I18nText, LocaleInfo, SupportedLocale } from 'types/model';

import { RootState } from '.';


export interface I18nState {
  currentLocale?: SupportedLocale;
  localeInfo: LocaleInfo;
  customTexts: CustomTexts;
}

// initial state
export const initialState: I18nState = {
  localeInfo: {
    defaultLocale: SupportedLocale.EN,
    availableLocales: [SupportedLocale.EN],
  },
  customTexts: {},
};

// actions
export const actions = {
  setLocaleInfo: createAction('tables/setLocaleInfo')<LocaleInfo>(),
  setCustomTexts: createAction('tables/setCustomTexts')<CustomTexts>(),
};

export type Actions = ActionType<typeof actions>;

// selectors
const getLocale = (state: RootState): string => state.i18n.currentLocale || state.i18n.localeInfo.defaultLocale;
const getCustomText = (state: RootState) => (forKey: string): I18nText | undefined => state.i18n.customTexts[`web.${forKey}`];

export const selectors = {
  getLocale,
  getCustomText,
};

// reducer
const setLocaleInfo = (state: I18nState, localeInfo: LocaleInfo): I18nState => ({
  ...state,
  localeInfo,
});

const setCustomTexts = (state: I18nState, customTexts: CustomTexts): I18nState => ({
  ...state,
  customTexts,
});

export const reducer = (state: I18nState = initialState, action: Actions): I18nState => {
  switch (action.type) {
    case getType(actions.setLocaleInfo):
      return setLocaleInfo(state, action.payload);
    case getType(actions.setCustomTexts):
      return setCustomTexts(state, action.payload);

    default:
      return state;
  }
};
