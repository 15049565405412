import { ActionType, createAction, getType } from 'typesafe-actions';

import { PaymentError, PaymentStatus } from 'types/model';

import { RootState } from '.';


export interface PaymentState {
  paidAmount?: number;
  paidTipAmount?: number;
  isVatReceiptAllowed: boolean;
  error?: PaymentError;
  status?: PaymentStatus;
}

// initial state
export const initialState: PaymentState = {
  isVatReceiptAllowed: false,
};

// actions
export const actions = {
  setPaidAmount: createAction('payment/setPaidAmount')<number | undefined>(),
  setPaidTipAmount: createAction('payment/setPaidTipAmount')<number | undefined>(),
  setIsVatReceiptAllowed: createAction('payment/setIsVatReceiptAllowed')<boolean>(),
  setError: createAction('payment/setError')<PaymentError | undefined>(),
  setStatus: createAction('payment/setStatus')<PaymentStatus>(),
};

export type Actions = ActionType<typeof actions>;

// selectors
const getIsPaymentInProgress = (state: RootState) => state.payment.status && [
  PaymentStatus.processing,
  PaymentStatus.started,
].includes(state.payment.status);
const getPaidAmount = (state: RootState) => state.payment.paidAmount;
const getPaidTipAmount = (state: RootState) => state.payment.paidTipAmount;
const getIsVatReceiptAllowed = (state: RootState) => state.payment.isVatReceiptAllowed;
const getError = (state: RootState) => state.payment.error;
const getStatus = (state: RootState) => state.payment.status;

export const selectors = {
  getIsPaymentInProgress,
  getPaidAmount,
  getPaidTipAmount,
  getIsVatReceiptAllowed,
  getError,
  getStatus,
};

// reducer
const setPaidAmount = (state: PaymentState, paidAmount?: number): PaymentState => ({
  ...state,
  paidAmount,
});

const setPaidTipAmount = (state: PaymentState, paidTipAmount?: number): PaymentState => ({
  ...state,
  paidTipAmount,
});

const setIsVatReceiptAllowed = (state: PaymentState, isVatReceiptAllowed: boolean): PaymentState => ({
  ...state,
  isVatReceiptAllowed,
});

const setError = (state: PaymentState, error: PaymentError | undefined): PaymentState => ({
  ...state,
  error,
});

const setStatus = (state: PaymentState, status: PaymentStatus): PaymentState => ({
  ...state,
  status,
});

export const reducer = (state: PaymentState = initialState, action: Actions): PaymentState => {
  switch (action.type) {
    case getType(actions.setPaidAmount):
      return setPaidAmount(state, action.payload);
    case getType(actions.setPaidTipAmount):
      return setPaidTipAmount(state, action.payload);
    case getType(actions.setIsVatReceiptAllowed):
      return setIsVatReceiptAllowed(state, action.payload);
    case getType(actions.setError):
      return setError(state, action.payload);
    case getType(actions.setStatus):
      return setStatus(state, action.payload);

    default:
      return state;
  }
};
