import { createSelector } from 'reselect';
import { ActionType, createAction, getType } from 'typesafe-actions';

import { Tag, TagType } from 'types/model';
import { compareTags } from 'util/compare';

import { RootState } from '.';


export interface TagsState {
  tags: Tag[];
  selectedAllergens: Set<number>;
  selectedFilters: Set<number>;
}

// initial state
export const initialState: TagsState = {
  tags: [],
  selectedAllergens: new Set<number>(),
  selectedFilters: new Set<number>(),
};

// actions
export const actions = {
  setTags: createAction('tags/setTags')<Tag[]>(),
  setSelectedAllergens: createAction('tags/setSelectedAllergens')<Set<number>>(),
  setSelectedFilters: createAction('tags/setSelectedFilters')<Set<number>>(),
  removeSelectedAllergen: createAction('tags/removeSelectedAllergen')<Tag>(),
  removeSelectedFilter: createAction('tags/removeSelectedFilter')<Tag>(),
};

export type Actions = ActionType<typeof actions>;

// selectors
const getTags = (state: RootState) => state.tags.tags;
const getAllergens = createSelector(
  getTags,
  tags =>
    tags.filter(tag => tag.tagType === TagType.allergen)
);
const getFilters = createSelector(
  getTags,
  tags =>
    tags.filter(tag => tag.tagType === TagType.filter)
);
const getAllergensSorted = createSelector(
  getAllergens,
  allergens => allergens.sort(compareTags)
);
const getFiltersSorted = createSelector(
  getFilters,
  filters => filters.sort(compareTags)
);
const getSelectedAllergens = (state: RootState) => state.tags.selectedAllergens;
const getSelectedFilters = (state: RootState) => state.tags.selectedFilters;

const getSelectedAllergenValues = createSelector(
  getTags,
  getSelectedAllergens,
  (tags, selectedAllergens) =>
    tags.filter(tag => selectedAllergens.has(tag.id)).sort(compareTags)
);

const getSelectedFilterValues = createSelector(
  getTags,
  getSelectedFilters,
  (tags, selectedFilters) =>
    tags.filter(tag => selectedFilters.has(tag.id)).sort(compareTags)
);

const hasSelectedTags = createSelector(
  getSelectedAllergens,
  getSelectedFilters,
  (selectedAllergens, selectedFilters) => selectedAllergens.size > 0 || selectedFilters.size > 0
);

export const selectors = {
  getTags,
  getAllergens,
  getFilters,
  getAllergensSorted,
  getFiltersSorted,
  getSelectedFilters,
  getSelectedAllergens,
  getSelectedAllergenValues,
  getSelectedFilterValues,
  hasSelectedTags,
};

// reducer
const setTags = (state: TagsState, tags: Tag[]): TagsState => ({
  ...state,
  tags,
});

const setSelectedAllergens = (state: TagsState, selectedAllergens: Set<number>): TagsState => ({
  ...state,
  selectedAllergens,
});

const setSelectedFilters = (state: TagsState, selectedFilters: Set<number>): TagsState => ({
  ...state,
  selectedFilters,
});

const removeSelectedAllergen = (state: TagsState, tag: Tag): TagsState => {
  const selectedAllergens = new Set(state.selectedAllergens);
  selectedAllergens.delete(tag.id);
  return {
    ...state,
    selectedAllergens,
  };
};

const removeSelectedFilter = (state: TagsState, tag: Tag): TagsState => {
  const selectedFilters = new Set(state.selectedFilters);
  selectedFilters.delete(tag.id);
  return {
    ...state,
    selectedFilters,
  };
};

export const reducer = (state = initialState, action: Actions): TagsState => {
  switch (action.type) {
    case getType(actions.setTags):
      return setTags(state, action.payload);
    case getType(actions.setSelectedAllergens):
      return setSelectedAllergens(state, action.payload);
    case getType(actions.setSelectedFilters):
      return setSelectedFilters(state, action.payload);
    case getType(actions.removeSelectedAllergen):
      return removeSelectedAllergen(state, action.payload);
    case getType(actions.removeSelectedFilter):
      return removeSelectedFilter(state, action.payload);
    default:
      return state;
  }
};
