import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectors as settingsSelectors } from 'ducks/settings';


export default () => {
  const theme = useSelector(settingsSelectors.getTheme);
  useEffect(() => {
    if (theme.primaryColour) {
      document.documentElement.style.setProperty('--c-primary', theme.primaryColour);
    }
    if (theme.secondaryColour) {
      document.documentElement.style.setProperty('--c-secondary', theme.secondaryColour);
    }
    if (theme.textColour) {
      document.documentElement.style.setProperty('--c-text', theme.textColour);
    }
    if (theme.titleTextColour) {
      document.documentElement.style.setProperty('--c-title', theme.titleTextColour);
    }
    if (theme.backgroundColour) {
      document.documentElement.style.setProperty('--bg-main', theme.backgroundColour);
    }
    if (theme.sectionBackgroundColour) {
      document.documentElement.style.setProperty('--section-bg', theme.sectionBackgroundColour);
    }
    if (theme.cardBackgroundColour) {
      document.documentElement.style.setProperty('--card-bg', theme.cardBackgroundColour);
    }
    if (theme.textSize) {
      document.documentElement.style.setProperty('--f-base-size', `${theme.textSize}px`);
    }
  }, [theme]);
};
