import { ActionType, createAction, getType } from 'typesafe-actions';

import { RootState } from '.';


export interface YumpingoState {
  isEnabled: boolean;
  isPromptShowing: boolean;
  shouldShowModal: boolean;
  isModalShowing: boolean;
  isReminderModalShowing: boolean;
  reminderEmail?: string;
}


// initial state
export const initialState: YumpingoState = {
  isEnabled: true,
  isPromptShowing: true,
  shouldShowModal: true,
  isModalShowing: false,
  isReminderModalShowing: false,
};


// actions
export const actions = {
  setIsEnabled: createAction('yumpingo/setIsEnabled')<boolean>(),
  setIsPromptShowing: createAction('yumpingo/setIsPromptShowing')<boolean>(),
  setShouldShowModal: createAction('yumpingo/setShouldShowModal')<boolean>(),
  setIsModalShowing: createAction('yumpingo/setIsModalShowing')<boolean>(),
  setIsReminderModalShowing: createAction('yumpingo/setIsReminderModalShowing')<boolean>(),
  setReminderEmail: createAction('yumpingo/setReminderEmail')<string | undefined>(),
};

export type Actions = ActionType<typeof actions>;


// selectors
const getIsEnabled = (state: RootState) => state.yumpingo.isEnabled;
const getIsPromptShowing = (state: RootState) => state.yumpingo.isPromptShowing;
const getShouldShowModal = (state: RootState) => state.yumpingo.shouldShowModal;
const getIsModalShowing = (state: RootState) => state.yumpingo.isModalShowing;
const getIsReminderModalShowing = (state: RootState) => state.yumpingo.isReminderModalShowing;
const getReminderEmail = (state: RootState) => state.yumpingo.reminderEmail;

export const selectors = {
  getIsEnabled,
  getIsPromptShowing,
  getShouldShowModal,
  getIsModalShowing,
  getIsReminderModalShowing,
  getReminderEmail,
};


// reducer
const setIsEnabled = (state: YumpingoState, isEnabled: boolean): YumpingoState => ({
  ...state,
  isEnabled,
});

const setIsPromptShowing = (state: YumpingoState, isPromptShowing: boolean): YumpingoState => ({
  ...state,
  isPromptShowing,
});

const setShouldShowModal = (state: YumpingoState, shouldShowModal: boolean): YumpingoState => ({
  ...state,
  shouldShowModal,
});

const setIsModalShowing = (state: YumpingoState, isModalShowing: boolean): YumpingoState => ({
  ...state,
  isModalShowing,
});

const setIsReminderModalShowing = (state: YumpingoState, isReminderModalShowing: boolean): YumpingoState => ({
  ...state,
  isReminderModalShowing,
});

const setReminderEmail = (state: YumpingoState, reminderEmail?: string): YumpingoState => ({
  ...state,
  reminderEmail,
});

export const reducer = (state: YumpingoState = initialState, action: Actions): YumpingoState => {
  switch (action.type) {
    case getType(actions.setIsEnabled):
      return setIsEnabled(state, action.payload);
    case getType(actions.setIsPromptShowing):
      return setIsPromptShowing(state, action.payload);
    case getType(actions.setShouldShowModal):
      return setShouldShowModal(state, action.payload);
    case getType(actions.setIsModalShowing):
      return setIsModalShowing(state, action.payload);
    case getType(actions.setIsReminderModalShowing):
      return setIsReminderModalShowing(state, action.payload);
    case getType(actions.setReminderEmail):
      return setReminderEmail(state, action.payload);

    default:
      return state;
  }
};
