import React, { FC } from 'react';

import { Tag } from 'types/model';
import { formatText } from 'util/i18n';
import { ReactComponent as SVGCrossIcon } from 'style/svg/cross.svg';

import './tags.scss';


interface Props {
  title: string;
  selected: Tag[];
  removeSelectedTag: (tag: Tag) => void;
}

export const TagSelectedList: FC<Props> = ({ title, selected, removeSelectedTag }) =>
  <>
    {selected.length > 0 &&
      <div className="TagBox">
        <h5>{title}</h5>
        <div>
          {selected.map(tag =>
            <span
              className="tag"
              key={tag.id}
            >
              {formatText(tag.title)}
              <SVGCrossIcon onClick={() => removeSelectedTag(tag)} />
            </span>
          )}
        </div>
      </div>
    }
  </>;
