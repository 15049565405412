import React, { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Path } from 'types/api';
import { error } from 'util/log';
import { selectors as ordersSelectors } from 'ducks/orders';
import { selectors as sessionSelectors } from 'ducks/session';
import { post } from 'effects/api';

import JudoPayPaymentScreen from './JudoPayPaymentScreen';


const JudoPayPaymentStart: FC = () => {
  const [paymentUuid, setPaymentUuid] = useState<string | null>(null);
  const [fetchSuccessful, setFetchState] = useState<boolean>(true);

  const sessionId = useSelector(sessionSelectors.getSessionId);
  const deviceId = useSelector(sessionSelectors.getDeviceId);
  const tipAmount = useSelector(ordersSelectors.getServiceChargeAmount);
  const userInfo = useSelector(sessionSelectors.getUserInfo);
  const note = useSelector(sessionSelectors.getNote);

  useEffect(() => {
    let cancelled = false;
    if (deviceId && sessionId) {
      const initiatePayment = async () => {
        try {
          const result = await post(
            Path.judoPayPaymentStart, { deviceId, sessionId, tipAmount, userInfo, note }
          );
          if (!cancelled) {
            setPaymentUuid(result.uuid);
          } else {
            post(Path.judoPayPaymentCancel, { deviceId, sessionId, uuid: result.uuid })
              .catch((err) => { error(err); });
            setFetchState(false);
          }
        } catch (err) {
          error(err);
          if (!cancelled) {
            setFetchState(false);
          }
        }
      };

      initiatePayment();
    }

    return () => {
      cancelled = true;
    };
  }, [deviceId, sessionId, tipAmount, userInfo, note]);

  if (!fetchSuccessful) {
    return <Redirect
      to={{
        pathname: '/payment-failure',
      }}
    />;
  }

  if (paymentUuid) {
    return <JudoPayPaymentScreen uuid={paymentUuid} />;
  }

  return null;
};

export default JudoPayPaymentStart;
