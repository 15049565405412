/* eslint-disable max-len */

import React, { FC, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';

import { Visitor } from 'types/model';
import { actions as sessionActions, selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import useConfigurableText from 'hooks/useConfigurableText';
import { Modal } from 'components/Modal';


interface VisitorModalErrors {
  [prop: string]: string | undefined;
  firstNames?: string;
  lastName?: string;
  contactPhone?: string;
  numberOfGuests?: string;
}

const VisitorModal: FC = () => {
  const ct = useConfigurableText();
  const dispatch = useDispatch();
  const visitorCaptureActive = useSelector(settingsSelectors.getIsVisitorCaptureActive);
  const visitor = useSelector(sessionSelectors.getVisitor);
  const [errors, setErrors] = useState<VisitorModalErrors>();
  const [isShown, setIsShown] = useState(visitorCaptureActive);
  const [firstNames, setFirstNames] = useState(visitor?.firstNames ?? '');
  const [lastName, setLastName] = useState(visitor?.lastName ?? '');
  const [contactPhone, setContactPhone] = useState(visitor?.contactPhone ?? '');
  const [numberOfGuests, setNumberOfGuests] = useState(visitor?.numberOfGuests ? `${visitor?.numberOfGuests}` : '');

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const currentErrors: VisitorModalErrors = {
      firstNames: ct('visitorModal.error.noFirstNames'),
      lastName: ct('visitorModal.error.noLastName'),
      contactPhone: ct('visitorModal.error.noContactPhone'),
      numberOfGuests: ct('visitorModal.error.noNumberOfGuests'),
    };
    if (firstNames) {
      delete currentErrors.firstNames;
    }
    if (lastName) {
      delete currentErrors.lastName;
    }
    if (contactPhone) {
      delete currentErrors.contactPhone;
    }
    if (numberOfGuests) {
      delete currentErrors.numberOfGuests;
    }
    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors);
    } else {
      const newVisitor = { firstNames, lastName, contactPhone, numberOfGuests: Number(numberOfGuests) } as Visitor;
      if (!_.isEqual(newVisitor, visitor)) {
        dispatch(sessionActions.setVisitor(newVisitor));
      }
      setIsShown(false);
    }
  };

  return (
    <Modal
      position="bottom"
      className="text-left Modal-email"
      isShown={isShown}
      onSubmit={handleSubmit}
      buttons={[
        { label: 'Confirm & Continue', type: 'submit', size: 'large' },
      ]}
    >
      <h5 className="text-center">CORONAVIRUS REQUIREMENTS AS PER GOVERNMENT GUIDANCE</h5>
      <p>
        The UK Government guidance requires that we keep a register of our customers for &apos;Track and Trace&apos; purposes for 21 days when it will then be purged.
        Your contact details will only be released to the Health Authority or a Government Department on request.
        Your data will not be used by us or passed on to any third party for any promotional purposes and will be kept securely until purged as per Government guidelines.
      </p>
      <div className={cx('inputBox', errors?.firstNames && 'hasError')}>
        <label htmlFor="visitorFirstNames">
          {ct('visitorModal.firstNames')}:
          <input
            id="visitorFirstNames"
            type="text"
            value={firstNames}
            onChange={e => setFirstNames(e.currentTarget.value)}
          />
        </label>
        {errors?.firstNames && (
          <div className="error-message">*{errors.firstNames}</div>
        )}
      </div>
      <div className={cx('inputBox', errors?.lastName && 'hasError')}>
        <label htmlFor="visitorLastName">
          {ct('visitorModal.lastName')}:
          <input
            id="visitorLastName"
            type="text"
            value={lastName}
            onChange={e => setLastName(e.currentTarget.value)}
          />
        </label>
        {errors?.lastName && (
          <div className="error-message">*{errors.lastName}</div>
        )}
      </div>
      <div className={cx('inputBox', errors?.contactPhone && 'hasError')}>
        <label htmlFor="visitorContactPhone">
          {ct('visitorModal.contactPhone')}:
          <input
            id="visitorContactPhone"
            type="tel"
            value={contactPhone}
            onChange={e => setContactPhone(e.currentTarget.value)}
          />
        </label>
        {errors?.contactPhone && (
          <div className="error-message">*{errors.contactPhone}</div>
        )}
      </div>
      <div className={cx('inputBox', errors?.numberOfGuests && 'hasError')}>
        <label htmlFor="visitorNumberOfGuests">
          {ct('visitorModal.numberOfGuests')}:
          <input
            id="visitorNumberOfGuests"
            type="number"
            min="1"
            value={numberOfGuests}
            onChange={e => setNumberOfGuests(e.currentTarget.value)}
          />
        </label>
        {errors?.numberOfGuests && (
          <div className="error-message">*{errors.numberOfGuests}</div>
        )}
      </div>
    </Modal>
  );
};

export default VisitorModal;
