import moment from 'moment';
import { ActionType, createAction, getType } from 'typesafe-actions';

import { DayOfTheWeek, Period } from 'types/model';
import { groupPeriodsByDay, weekdayNumberToDayOfTheWeek } from 'util/time';

import { RootState } from '.';


export interface ScheduleState {
  takeawayPeriods: Period[];
  preparationTime: number;
  scheduleLimitDays: number;
}

// initial state
export const initialState: ScheduleState = {
  takeawayPeriods: [],
  preparationTime: 0,
  scheduleLimitDays: 6,
};

// actions
export const actions = {
  setTakeawayPeriods: createAction('settings/setTakeawayPeriods')<Period[]>(),
  setPreparationTime: createAction('settings/setPreparationTime')<number>(),
  setScheduleLimitDays: createAction('settings/setScheduleLimitDays')<number>(),
};

export type Actions = ActionType<typeof actions>;


// selectors
const getTakeawayPeriods = (state: RootState) => state.schedule.takeawayPeriods;
const getTakeawayPeriodsGroupedByDay = (state: RootState) => groupPeriodsByDay(getTakeawayPeriods(state));
const getPreparationTime = (state: RootState) => state.schedule.preparationTime;
const getScheduleLimitDays = (state: RootState) => state.schedule.scheduleLimitDays;
const getAsapReadyTime = (state: RootState): string | undefined => {
  const readyMoment = moment().add(getPreparationTime(state), 'm');
  const readyMinutesFromStartOfDay = (readyMoment.hour() * 60) + readyMoment.minute();
  let readyWeekday;
  try {
    readyWeekday = weekdayNumberToDayOfTheWeek(
      readyMoment.isoWeekday() - 1 // with isoWeekday, Monday is 1, Sunday is 7
    );
  } catch (e) {
    // nothing to do
  }

  const periods = readyWeekday ? getTakeawayPeriodsGroupedByDay(state).get(DayOfTheWeek[readyWeekday]) : [];
  const readyPeriod = periods && periods.find(period =>
    readyMinutesFromStartOfDay >= period.startTime && readyMinutesFromStartOfDay <= period.endTime
  );

  return readyPeriod && readyMoment.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const selectors = {
  getTakeawayPeriods,
  getTakeawayPeriodsGroupedByDay,
  getPreparationTime,
  getScheduleLimitDays,
  getAsapReadyTime,
};


// reducer
const setTakeawayPeriods = (state: ScheduleState, takeawayPeriods: Period[]): ScheduleState => ({
  ...state,
  takeawayPeriods,
});

const setPreparationTime = (state: ScheduleState, preparationTime: number): ScheduleState => ({
  ...state,
  preparationTime,
});

const setScheduleLimitDays = (state: ScheduleState, scheduleLimitDays: number): ScheduleState => ({
  ...state,
  scheduleLimitDays,
});

export const reducer = (state: ScheduleState = initialState, action: Actions): ScheduleState => {
  switch (action.type) {
    case getType(actions.setTakeawayPeriods):
      return setTakeawayPeriods(state, action.payload);
    case getType(actions.setPreparationTime):
      return setPreparationTime(state, action.payload);
    case getType(actions.setScheduleLimitDays):
      return setScheduleLimitDays(state, action.payload);

    default:
      return state;
  }
};
