import { PaymentBypassType } from 'types/model';


export const getDineInBypassPaymentType =
  (isPaymentDisabled: boolean, isOrderFree: boolean): PaymentBypassType | undefined => {
    if (isPaymentDisabled) {
      return PaymentBypassType.WEB_NO_DINE_IN_PREPAYMENT;
    } else if (isOrderFree) {
      return PaymentBypassType.FREE_ORDER;
    }
    return undefined;
  };

export const getTakeAwayBypassPaymentType =
  (isTakeAwayPayOnCollectionEnabled: boolean, isPayingOnline: boolean, isOrderFree: boolean): PaymentBypassType | undefined => {
    if (isOrderFree) {
      return PaymentBypassType.FREE_ORDER;
    } else if (isTakeAwayPayOnCollectionEnabled && !isPayingOnline) {
      return PaymentBypassType.WEB_PAY_ON_COLLECTION;
    }
    return undefined;
  };
