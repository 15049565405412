import React, { FC, FormEvent } from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';

import { Button } from 'components/Button';

import { ModalButton, ModalPosition } from './modalTypes';

import './modal.scss';


interface WrapperProps {
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
}

const Wrapper: FC<WrapperProps> = ({ onSubmit, children }) => {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit!(e);
  };

  return onSubmit
    ? <form className="container" onSubmit={handleSubmit}>{children}</form>
    : <div className="container">{children}</div>;
};

interface ModalProps {
  position: ModalPosition;
  buttons?: ModalButton[];
  isShown?: boolean;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  errorMessages?: string[];
  className?: string;
}

export const Modal: FC<ModalProps> = ({
  position,
  buttons = [],
  isShown = false,
  onSubmit,
  errorMessages = [],
  className,
  children,
}) => {
  const modal = (
    <div className={cx(`Modal-${position}`, className)}>
      <Wrapper onSubmit={onSubmit}>
        <div className="body">
          {children}
          {errorMessages.map(errorMessage => (
            <div key={errorMessage} className="error-message">*{errorMessage}</div>
          ))}
        </div>
        <div className="buttons">
          {buttons.map((modalButton, index) => (
            <Button
              key={index}
              type={modalButton.type || 'button'}
              variant={modalButton.variant || 'primary'}
              disabled={modalButton.disabled || false}
              size={modalButton.size || 'medium'}
              label={modalButton.label}
              onClick={modalButton.onClick}
            />
          ))}
        </div>
      </Wrapper>
    </div>
  );

  return isShown ? createPortal(modal, document.body) : null;
};
