import React, { FC, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import useIsInViewport from 'use-is-in-viewport';

import useConfigurableText from 'hooks/useConfigurableText';
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import { ReactComponent as SVGArrow } from 'style/svg/arrow.svg';

import './dropdown.scss';


enum OpenDirection {
  UP = 'openDirectionUp',
  DOWN = 'openDirectionDown'
}

interface DropdownListProps {
  options: string[];
  onSelect: (selectedOption?: string) => void;
  className?: string;
}

interface DropdownProps {
  title: string;
  options: string[];
  onSelect: (selectedOption?: string) => void;
  selectedOption?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

// The direction in which the list is opened is determined by trying to open the list downwards at first and checking
// whether it fits the viewport entirely. That's why it is rendered invisible until the direction is determined,
// otherwise if it didn't fit, it would still appear open downwards for a split second before moving up.
const DropdownList: FC<DropdownListProps> = ({
  options,
  onSelect,
  className,
}) => {
  const [isInViewport, ref] = useIsInViewport({ threshold: 100 });

  const [openDirection, setOpenDirection] = useState<OpenDirection>();

  useEffect(() => {
    if (!openDirection) {
      if (isInViewport === false) {
        setOpenDirection(OpenDirection.UP);
      } else if (isInViewport === true) {
        setOpenDirection(OpenDirection.DOWN);
      }
    }
  }, [openDirection, isInViewport]);

  return (
    <div
      className={cx(
        'Dropdown-list',
        'isOpen',
        openDirection,
        openDirection && 'visible',
        className)
      }
      ref={ref}
    >
      {options.map((option: string, idx: number) => (
        <div key={idx} className="item" onClick={() => onSelect(option)}>
          {option}
        </div>
      ))}
    </div>
  );
};

export const Dropdown: FC<DropdownProps> = ({
  title,
  options,
  selectedOption,
  onSelect,
  placeholder,
  disabled,
  className,
}) => {
  const ct = useConfigurableText();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useDetectOutsideClick(dropdownRef, false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleSelect = (option?: string) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className={cx('Dropdown Dropdown-ellipsis', { disabled })} ref={dropdownRef}>
      <div className="Dropdown-header">
        {title}
      </div>
      <div
        className={cx('Dropdown-selected', isOpen ? 'isOpen' : 'isClosed', selectedOption ? '' : 'placeholder')}
        onClick={!disabled ? toggleOpen : undefined}
      >
        <div>
          {selectedOption || placeholder || ct('shared.labels.selectOption')}
        </div>
        <SVGArrow />
      </div>
      { isOpen && (
        <DropdownList
          options={options}
          onSelect={handleSelect}
          className={className}
        />
      )}
    </div>
  );
};
