import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { APIJudoPayPaymentFinishResponse, Path } from 'types/api';
import { CardDetails, JudoPay, PaymentMethod } from 'types/judoPay';
import { JudoPayPaymentConfig, PaymentStatus } from 'types/model';
import { canMakeApplePayPayments } from 'util/device';
import { debug, error } from 'util/log';
import { selectors as paymentSelectors } from 'ducks/payment';
import { selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import { post } from 'effects/api';
import useConfigurableText from 'hooks/useConfigurableText';
import useJudoPaySession from 'hooks/useJudoPaySession';
import { BounceDots } from 'components/BounceDots';
import { Header } from 'components/Header';
import { Overlay } from 'components/Overlay';
import { restaurantConfig } from 'config/restaurantConfig';

import styles from './iframe-judopay.json';
import JudoPayAppleButton from './JudoPayAppleButton';
import JudoPayCardPaymentButton from './JudoPayCardPaymentButton';
import MockJudoPayAppleButton from './MockJudoPayAppleButton';


enum PaymentOptions {
  ApplePay = 'ApplePay',
  Card = 'Card',
}

interface JudoPayPaymentScreenProps {
  uuid: string;
}

const JudoPayPaymentScreen: FC<JudoPayPaymentScreenProps> = ({ uuid }) => {
  const ct = useConfigurableText();

  const dispatch = useDispatch();

  const formRef = useRef<HTMLFormElement>(null);
  const history = useHistory();

  const [paymentSent, setPaymentSent] = useState(false);
  const [judo, setJudo] = useState<JudoPay>();
  const [cardDetails, setCardDetails] = useState<CardDetails>();
  const [redirectionDetails, setRedirectionDetails] = useState<APIJudoPayPaymentFinishResponse>();
  const [paymentOption, setPaymentOption] = useState<PaymentOptions>(
    canMakeApplePayPayments() ? PaymentOptions.ApplePay : PaymentOptions.Card
  );
  const paymentStatus = useSelector(paymentSelectors.getStatus);

  const paymentConfig = useSelector(settingsSelectors.getPaymentConfig) as JudoPayPaymentConfig;
  const sessionId = useSelector(sessionSelectors.getSessionId);
  const deviceId = useSelector(sessionSelectors.getDeviceId);

  const paymentSession = useJudoPaySession({ deviceId, sessionId, uuid });

  const cancelPayment = useCallback(() => {
    if (deviceId && sessionId) {
      post(Path.judoPayPaymentCancel, { deviceId, sessionId, uuid })
        .catch((err) => { error(err); });
    }
  }, [deviceId, sessionId, uuid]);


  const goToBasket = useCallback(() => {
    cancelPayment();
    dispatch(push('/order'));
  }, [dispatch, cancelPayment]);

  useEffect(() => {
    const stopListening = history.listen((_, action) => {
      if (action === 'POP') {
        cancelPayment();
      }

      stopListening();
    });
  }, [cancelPayment, history]);

  useEffect(() => {
    const { apiToken, isSandbox } = paymentConfig;
    if (!judo) {
      setJudo(new window.JudoPay(apiToken, isSandbox));
    }
  }, [judo, setJudo, paymentConfig]);


  useEffect(() => {
    if (judo) {
      setCardDetails(judo.createCardDetails('payment-iframe', {
        iframe: {
          enabledPaymentMethods: ['CARD'],
          isCountryAndPostcodeVisible: false,
          styles,
        },
        isGeoLocationGatheringAllowed: false,
      }));
    }
  }, [judo, setCardDetails]);

  useEffect(() => {
    if (redirectionDetails) {
      formRef.current?.submit();
    }
  }, [formRef, redirectionDetails]);

  const handleClick = async () => {
    if (cardDetails && judo && judo.getPaymentMethod() === PaymentMethod.Card) {
      try {
        setPaymentSent(true);
        const response = await judo.createToken(cardDetails);
        if (response.oneUseToken && deviceId && sessionId && uuid) {
          const result = await post(
            Path.judoPayPaymentFinish,
            {
              oneUseToken: response.oneUseToken,
              deviceId,
              sessionId,
              uuid,
            }
          );
          if (result.message === 'Issuer authentication required') {
            setRedirectionDetails(result);
          }
        }
      } catch (err) {
        error(err);
        if (err.message === 'Network Error') {
          window.location.reload(); // TODO - Once Judo Pay adds support for handling disconnections, we can remove this call.
        }
      }
    }
  };

  const handleOptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentOption(e.currentTarget.value as PaymentOptions);
  }, []);

  const ApplePayButtonComponent = restaurantConfig.isApplePayMocked ? MockJudoPayAppleButton : JudoPayAppleButton;

  const applePayButton = paymentSession && judo ? <ApplePayButtonComponent session={paymentSession} judo={judo} /> : null;

  const PaymentOptionSelect = canMakeApplePayPayments() ?
    <>
      <h3 className="paymentLabel">{ct('paymentScreen.choosePaymentOption')}</h3>
      <fieldset className="inputs horizontal">
        <label className="Radiobox" htmlFor="applePayOptionInput">
          <input
            id="applePayOptionInput"
            type="radio"
            name="paymentMethod"
            onChange={handleOptionChange}
            value={PaymentOptions.ApplePay}
            checked={PaymentOptions.ApplePay === paymentOption}
            disabled={paymentSent}
          />
          <div className="State">
            {ct('paymentScreen.ApplePay')}
          </div>
        </label>
        <label className="Radiobox" htmlFor="cardOptionInput">
          <input
            id="cardOptionInput"
            type="radio"
            name="paymentMethod"
            onChange={handleOptionChange}
            value={PaymentOptions.Card}
            checked={PaymentOptions.Card === paymentOption}
          />
          <div className="State">
            {ct('paymentScreen.ByCard')}
          </div>
        </label>
      </fieldset>
    </> : null;
  debug('JudoPayPaymentScreen.render', judo, cardDetails);
  return (
    <div className="PageSimple judoPayScreen">
      <div className="container">
        {redirectionDetails ? (
          <>
            <Header
              title={ct('paymentScreen.secureCheckout')}
              hideLogo
              buttons={[<span key="cancel" onClick={goToBasket}>{ct('paymentScreen.cancel')}</span>]}
            />
            <iframe
              style={{ width: '100vw', height: '100vh' }}
              title="3D-secure"
              name="3D-secure"
            />
            <form target="3D-secure" ref={formRef} action={redirectionDetails.acsUrl} method="post" style={{ display: 'none' }}>
              <input type="hidden" name="MD" value={redirectionDetails.md} />
              <input type="hidden" name="PaReq" value={redirectionDetails.paReq} />
              <input type="hidden" name="TermUrl" value={redirectionDetails.termUrl} />
            </form>
            {paymentStatus === PaymentStatus.processing &&
              <Overlay illustration={<BounceDots />}>
                <p>{ct('paymentIsInProgress.pleaseWait')}</p>
              </Overlay>
            }
          </>
        ) : (
          <>
            <Header title={ct('paymentScreen.title')} backTo="/order" onClick={cancelPayment} />
            <div className="body">
              {PaymentOptionSelect}
              <div id="payment-iframe" hidden={paymentOption !== PaymentOptions.Card} />
            </div>
            { paymentSent &&
              <Overlay illustration={<BounceDots />}>
                <p>{ct('paymentIsInProgress.pleaseWait')}</p>
              </Overlay>
            }
            <div className="buttons">
              {/* Always render the payment button to allow JudoPay iframe to control it properly */}
              <JudoPayCardPaymentButton
                hidden={paymentOption !== PaymentOptions.Card}
                disabled={paymentSent}
                onClick={handleClick}
              />
              {paymentOption === PaymentOptions.ApplePay && applePayButton}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default JudoPayPaymentScreen;
