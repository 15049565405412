import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { getMenuHomePath } from 'util/path';
import { selectors as menuSelectors } from 'ducks/menu';
import { selectors as sessionSelectors } from 'ducks/session';
import closeSession from 'effects/closeSession';
import { useWebSocket } from 'contexts/SocketManagerContext';
import useConfigurableText from 'hooks/useConfigurableText';
import { Modal } from 'components/Modal';


const NewSessionModal: FC = () => {
  const dispatch = useDispatch();
  const webSocket = useWebSocket();
  const ct = useConfigurableText();

  const dineIn = useSelector(sessionSelectors.getDineIn);
  const sessionId = useSelector(sessionSelectors.getSessionId);
  const menus = useSelector(menuSelectors.getSelectableMenus);

  const ButtonCancelText = ct('newSession.button.cancel');
  const ButtonContinueText = ct('newSession.button.continue');

  return (
    <Modal
      position="bottom"
      isShown={!!sessionId}
      buttons={[
        { label: ButtonCancelText, variant: 'secondary', onClick: () => closeSession(dispatch, webSocket, dineIn, sessionId!) },
        { label: ButtonContinueText, onClick: () => dispatch(push(getMenuHomePath(menus))) },
      ]}
    >
      <h5>{ct('newSession.areYouDeciding')}</h5>
      <p>{ct('newSession.continueOrStart')}</p>
    </Modal>
  );
};

export default NewSessionModal;
