import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { routePath } from 'constants/routes';
import { formatText } from 'util/i18n';
import { getMenuImageSrc } from 'util/image';
import { selectors as menusSelectors } from 'ducks/menu';
import { selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import useConfigurableText from 'hooks/useConfigurableText';
import { DinerInfoButton } from 'containers/DinerInfoButton';
import PaymentInProgressModal from 'containers/PaymentInProgressModal';
import Avatar from 'components/Avatar';
import { BasketButton } from 'components/BasketButton';
import { Button } from 'components/Button';
import { Header } from 'components/Header';
import { ReactComponent as SVGKnifeAndSpoonIcon } from 'style/svg/knife-and-spoon.svg';


const MenuSelectScreen: FC = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();
  const restaurantName = useSelector(settingsSelectors.getRestaurantName);
  const dineIn = useSelector(sessionSelectors.getDineIn);
  const menus = useSelector(menusSelectors.getSelectableMenus);

  const headerButtons = dineIn && <DinerInfoButton />;
  return (
    <div className="container-fixed">
      <Header
        title={restaurantName}
        backTo="/"
        buttons={headerButtons}
      />
      <section className="menuSelect">
        {menus.length > 0 ?
          <>
            <h3 className="title">{ct('menuSelectScreen.chooseMenu')}</h3>
            <div className="menuContainer">
              {menus.map(({ id, title, hasPhoto }) =>
                <div key={`${id}-menu-card`} className="menuCard">
                  <Button
                    type="button"
                    className={hasPhoto ? 'hasAvatar' : ''}
                    variant="square"
                    size="large"
                    onClick={() => dispatch(push(`${routePath.menus}/${id}`))}
                  >
                    {hasPhoto ? <Avatar src={getMenuImageSrc(id)} width={100} height={100} alt={`${formatText(title)} image`} /> : <SVGKnifeAndSpoonIcon />}
                  </Button>
                  <span className="title">{formatText(title)}</span>
                </div>)}
            </div>
          </>
          :
          <span className="message">
            {ct('menuSelectScreen.noMenusAvailable')}
          </span>
      }
        <BasketButton />
        <PaymentInProgressModal />
      </section>
    </div>
  );
};

export default MenuSelectScreen;
