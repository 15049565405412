import React, { ChangeEvent, FC, MouseEvent, ReactNode, TouchEvent } from 'react';
import cx from 'classnames';

import { Button } from '../Button';

import './inputWithButton.scss';


interface Props {
  className?: string;
  title?: string;
  placeholder: string;
  label: string;
  id: string;
  error?: ReactNode;
  onClick?: (event: MouseEvent| TouchEvent) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const InputWithButton: FC<Props> = ({ className, title, placeholder, label, id, error, onClick, onChange }) => (
  <div className={cx('inputBox withButton', className)}>
    <label htmlFor={id}>
      {title}
      <div>
        <input
          id={id}
          type="text"
          placeholder={placeholder}
          onChange={onChange}
        />
        <Button
          label={label}
          type="submit"
          variant="primary"
          size="small"
          onClick={onClick}
        />
      </div>
    </label>
    {error}
  </div>
);
