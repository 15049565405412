import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { getOrderDisplayNr } from 'util/getOrderDisplayNumber';
import { selectors as ordersSelectors } from 'ducks/orders';
import useConfigurableText from 'hooks/useConfigurableText';


interface ErrorInfoProps {
  message: string;
  head?: ReactNode;
}

export const ErrorInfo: FC<ErrorInfoProps> = ({ head, message }) => {
  const ct = useConfigurableText();
  const currentOrder = useSelector(ordersSelectors.getCurrentOrder);
  const orderDisplayNumber = getOrderDisplayNr(currentOrder?.id);

  return (
    <>
      <div className="head">
        {head}
      </div>
      <div className="body">
        <h5 className="title">{message}</h5>
        <p>{ct('contactStaffScreen.contactStaff')}</p>
        <div className="body">
          {currentOrder && orderDisplayNumber
            ? (
              <div className="orderDetail">
                <p>{ct('contactStaffScreen.orderId')}</p>
                <h1>{orderDisplayNumber}</h1>
              </div>
            ) : <p>{ct('contactStaffScreen.noOrder')}</p>}
        </div>
      </div>
    </>
  );
};
