import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { formatCurrency } from 'util/i18n';
import { selectors as settingsSelectors } from 'ducks/settings';

import './counter.scss';


interface Props {
  value: number;
  isCurrency?: boolean;
}

export const Counter: FC<Props> = ({ value, isCurrency = false }) => {
  const currency = useSelector(settingsSelectors.getCurrency);
  return value > 0
    ? (
      <span className="counter">{isCurrency ? formatCurrency(value, currency) : value}</span>
    ) : null;
};
