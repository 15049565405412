import { ActionType, createAction, getType } from 'typesafe-actions';

import {
  Currency,
  DiningMode,
  DiningModeName,
  I18nRichText,
  PaymentConfig,
  ReceiptDetailsSettings,
  Theme,
  VerifiableValue,
} from 'types/model';

import { selectors as sessionSelectors } from './session';
import { RootState } from '.';


export const ORDER_THRESHOLD_DISABLED = -1;

export interface SettingsState {
  isLoaded: boolean;
  restaurantName: string;
  paymentConfig: PaymentConfig | null;
  theme?: Theme;
  isDineInServiceChargeActive: boolean;
  isTakeAwayServiceChargeActive: boolean;
  defaultServiceChargePercent: number;
  serviceChargePercent?: number;
  privacyPolicy?: VerifiableValue<I18nRichText>;
  termsOfUse?: VerifiableValue<I18nRichText>;
  dineInUserHelp?: string;
  enabledDiningModes: number;
  smsNotificationsDineIn: boolean;
  smsNotificationsTakeAway: boolean;
  smsNotificationsText: string;
  isPaymentDisabled: boolean;
  isTakeAwayPayOnCollectionEnabled: boolean;
  orderThreshold: number;
  isVisitorCaptureActive: boolean;
  isEmailReceiptPromptEnabled: boolean;
  currency: Currency;
  receiptDetails: ReceiptDetailsSettings;
  posConnected: boolean;
  googleAnalyticsIds: string[];
}

// initial state
export const initialState: SettingsState = {
  isLoaded: false,
  restaurantName: '',
  paymentConfig: null,
  smsNotificationsDineIn: false,
  smsNotificationsTakeAway: false,
  smsNotificationsText: 'Your order #{orderNumber} is ready',
  isDineInServiceChargeActive: true,
  isTakeAwayServiceChargeActive: true,
  defaultServiceChargePercent: 0,
  enabledDiningModes: DiningMode.dineIn | DiningMode.takeAway,
  isPaymentDisabled: false,
  isTakeAwayPayOnCollectionEnabled: false,
  orderThreshold: ORDER_THRESHOLD_DISABLED,
  isVisitorCaptureActive: false,
  isEmailReceiptPromptEnabled: true,
  currency: { code: '', display: false },
  receiptDetails: {
    companyName: '',
    VAT: '',
    street: '',
    number: '',
    city: '',
    country: '',
  },
  posConnected: false,
  googleAnalyticsIds: [],
};

// actions
export const actions = {
  setIsLoaded: createAction('settings/setIsLoaded')<boolean>(),
  setRestaurantName: createAction('settings/setRestaurantName')<string>(),
  setPaymentConfig: createAction('settings/setPaymentConfig')<PaymentConfig>(),
  setTheme: createAction('settings/setTheme')<Theme>(),
  setIsDineInServiceChargeActive: createAction('settings/setIsDineInServiceChargeActive')<boolean>(),
  setIsTakeAwayServiceChargeActive: createAction('settings/setIsTakeAwayServiceChargeActive')<boolean>(),
  setDefaultServiceChargePercent: createAction('settings/setDefaultServiceChargePercent')<number>(),
  setServiceChargePercent: createAction('settings/setServiceChargePercent')<number | undefined>(),
  setPrivacyPolicy: createAction('settings/setPrivacyPolicy')<VerifiableValue<I18nRichText>>(),
  setTermsOfUse: createAction('settings/setTermsOfUse')<VerifiableValue<I18nRichText>>(),
  setDineInUserHelp: createAction('settings/setDineInUserHelp')<string | undefined>(),
  setEnabledDiningMode: createAction('settings/setEnabledDiningMode')<DiningModeName>(),
  setSmsNotificationsDineIn: createAction('settings/setSmsNotificationsDineIn')<boolean>(),
  setSmsNotificationsTakeAway: createAction('settings/setSmsNotificationsTakeAway')<boolean>(),
  setSmsNotificationsText: createAction('settings/setSmsNotificationsText')<boolean>(),
  setIsPaymentDisabled: createAction('settings/setIsPaymentDisabled')<boolean>(),
  setIsTakeAwayPayOnCollectionEnabled: createAction('settings/setIsTakeAwayPayOnCollectionEnabled')<boolean>(),
  setOrderThreshold: createAction('settings/setOrderThreshold')<number>(),
  setIsVisitorCaptureActive: createAction('settings/setIsVisitorCaptureActive')<boolean>(),
  setIsEmailReceiptPromptEnabled: createAction('settings/setIsEmailReceiptPromptEnabled')<boolean>(),
  setCurrency: createAction('settings/setCurrency')<Currency>(),
  setReceiptDetails: createAction('settings/setReceiptDetails')<any>(),
  setPosConnected: createAction('settings/setPosConnected')<any>(),
  setGoogleAnalyticsIds: createAction('settings/setGoogleAnalyticsIds')<string[]>(),
};

export type Actions = ActionType<typeof actions>;

// selectors
const getIsLoaded = (state: RootState) => state.settings.isLoaded;
const getRestaurantName = (state: RootState) => state.settings.restaurantName;
const getPaymentConfig = (state: RootState) => state.settings.paymentConfig;
const getTheme = (state: RootState) => state.settings.theme || {};
const getIsServiceChargeActive = (state: RootState) => sessionSelectors.getDineIn(state)
  ? !state.settings.isPaymentDisabled && state.settings.isDineInServiceChargeActive
  : state.settings.isTakeAwayServiceChargeActive;
const getDefaultServiceChargePercent = (state: RootState) => state.settings.defaultServiceChargePercent;
const getServiceChargePercent = (state: RootState) => state.settings.serviceChargePercent === undefined
  ? getDefaultServiceChargePercent(state)
  : state.settings.serviceChargePercent;
const getPrivacyPolicy = (state: RootState) => state.settings.privacyPolicy;
const getDineInUserHelp = (state: RootState) => state.settings.dineInUserHelp;
const getTermsOfUse = (state: RootState) => state.settings.termsOfUse;
const getIsDineInEnabled = (state: RootState) => !!(state.settings.enabledDiningModes & DiningMode.dineIn);
const getIsTakeAwayEnabled = (state: RootState) => !!(state.settings.enabledDiningModes & DiningMode.takeAway);
const getIsRoomServiceEnabled = (state: RootState) => !!(state.settings.enabledDiningModes & DiningMode.roomService);
const getSmsNotificationDineIn = (state: RootState) => state.settings.smsNotificationsDineIn;
const getSmsNotificationTakeAway = (state: RootState) => state.settings.smsNotificationsTakeAway;
const getSmsNotificationText = (state: RootState) => state.settings.smsNotificationsText;
const getIsPaymentDisabled = (state: RootState) => state.settings.isPaymentDisabled;
const getIsTakeAwayPayOnCollectionEnabled = (state: RootState) => state.settings.isTakeAwayPayOnCollectionEnabled;
const getOrderThreshold = (state: RootState) => state.settings.orderThreshold;
const getIsVisitorCaptureActive = (state: RootState) => state.settings.isVisitorCaptureActive;
const getIsEmailReceiptPromptEnabled = (state: RootState) => state.settings.isEmailReceiptPromptEnabled;
const getCurrency = (state: RootState) => state.settings.currency;
const getReceiptDetails = (state: RootState) => state.settings.receiptDetails;
const getIsPosConnected = (state: RootState) => state.settings.posConnected;
const getGoogleAnalyticsIds = (state: RootState) => state.settings.googleAnalyticsIds;

export const selectors = {
  getIsLoaded,
  getRestaurantName,
  getPaymentConfig,
  getTheme,
  getIsServiceChargeActive,
  getDefaultServiceChargePercent,
  getServiceChargePercent,
  getPrivacyPolicy,
  getTermsOfUse,
  getDineInUserHelp,
  getIsDineInEnabled,
  getIsTakeAwayEnabled,
  getIsRoomServiceEnabled,
  getSmsNotificationDineIn,
  getSmsNotificationTakeAway,
  getSmsNotificationText,
  getIsPaymentDisabled,
  getIsTakeAwayPayOnCollectionEnabled,
  getOrderThreshold,
  getIsVisitorCaptureActive,
  getIsEmailReceiptPromptEnabled,
  getCurrency,
  getReceiptDetails,
  getIsPosConnected,
  getGoogleAnalyticsIds,
};

// reducer
const setIsLoaded = (state: SettingsState, isLoaded: boolean): SettingsState => ({
  ...state,
  isLoaded,
});

const setRestaurantName = (state: SettingsState, restaurantName = '') => ({
  ...state,
  restaurantName,
});

const setPaymentConfig = (state: SettingsState, paymentConfig: PaymentConfig) => ({
  ...state,
  paymentConfig,
});

const setTheme = (state: SettingsState, theme: Theme) => ({
  ...state,
  theme,
});

const setIsDineInServiceChargeActive = (state: SettingsState, isDineInServiceChargeActive: boolean): SettingsState => ({
  ...state,
  isDineInServiceChargeActive,
});

const setIsTakeAwayServiceChargeActive = (state: SettingsState, isTakeAwayServiceChargeActive: boolean): SettingsState => ({
  ...state,
  isTakeAwayServiceChargeActive,
});

const setDefaultServiceChargePercent = (state: SettingsState, defaultServiceChargePercent: number): SettingsState => ({
  ...state,
  defaultServiceChargePercent,
});

const setServiceChargePercent = (state: SettingsState, serviceChargePercent?: number): SettingsState => {
  if (serviceChargePercent === undefined) {
    return ({
      ...state,
      serviceChargePercent: undefined,
    });
  }
  return ({
    ...state,
    serviceChargePercent: serviceChargePercent > 0 ? serviceChargePercent : 0,
  });
};

const setPrivacyPolicy = (state: SettingsState, privacyPolicy: VerifiableValue<I18nRichText>): SettingsState => ({
  ...state,
  privacyPolicy,
});

const setTermsOfUse = (state: SettingsState, termsOfUse: VerifiableValue<I18nRichText>): SettingsState => ({
  ...state,
  termsOfUse,
});

const setDineInUserHelp = (state: SettingsState, dineInUserHelp?: string): SettingsState => ({
  ...state,
  dineInUserHelp,
});

const setEnabledDiningMode = (state: SettingsState, diningModeName: DiningModeName): SettingsState => ({
  ...state,
  enabledDiningModes: DiningMode[diningModeName],
});

const setSmsNotificationsDineIn = (state: SettingsState, smsNotificationsDineIn: boolean): SettingsState => ({
  ...state,
  smsNotificationsDineIn,
});

const setSmsNotificationsTakeAway = (state: SettingsState, smsNotificationsTakeAway: boolean): SettingsState => ({
  ...state,
  smsNotificationsTakeAway,
});

const setIsPaymentDisabled = (state: SettingsState, isPaymentDisabled: boolean): SettingsState => ({
  ...state,
  isPaymentDisabled,
});

const setIsTakeAwayPayOnCollectionEnabled = (
  state: SettingsState,
  isTakeAwayPayOnCollectionEnabled: boolean
): SettingsState => ({
  ...state,
  isTakeAwayPayOnCollectionEnabled,
});

const setOrderThreshold = (state: SettingsState, orderThreshold: number): SettingsState => ({
  ...state,
  orderThreshold,
});

const setIsVisitorCaptureActive = (state: SettingsState, isVisitorCaptureActive: boolean): SettingsState => ({
  ...state,
  isVisitorCaptureActive,
});

const setIsEmailReceiptPromptEnabled = (state: SettingsState, isEmailReceiptPromptEnabled: boolean): SettingsState => ({
  ...state,
  isEmailReceiptPromptEnabled,
});

const setCurrency = (state: SettingsState, currency: Currency): SettingsState => ({
  ...state,
  currency,
});

const setReceiptDetails = (state: SettingsState, receiptDetails: ReceiptDetailsSettings): SettingsState => ({
  ...state,
  receiptDetails,
});

const setPosConnected = (state: SettingsState, posConnected: boolean): SettingsState => ({
  ...state,
  posConnected,
});

const setGoogleAnalyticsIds = (state: SettingsState, googleAnalyticsIds: string[]): SettingsState => ({
  ...state,
  googleAnalyticsIds,
});

export const reducer = (state: SettingsState = initialState, action: Actions): SettingsState => {
  switch (action.type) {
    case getType(actions.setIsLoaded):
      return setIsLoaded(state, action.payload);
    case getType(actions.setRestaurantName):
      return setRestaurantName(state, action.payload);
    case getType(actions.setPaymentConfig):
      return setPaymentConfig(state, action.payload);
    case getType(actions.setTheme):
      return setTheme(state, action.payload);
    case getType(actions.setIsDineInServiceChargeActive):
      return setIsDineInServiceChargeActive(state, action.payload);
    case getType(actions.setIsTakeAwayServiceChargeActive):
      return setIsTakeAwayServiceChargeActive(state, action.payload);
    case getType(actions.setDefaultServiceChargePercent):
      return setDefaultServiceChargePercent(state, action.payload);
    case getType(actions.setServiceChargePercent):
      return setServiceChargePercent(state, action.payload);
    case getType(actions.setPrivacyPolicy):
      return setPrivacyPolicy(state, action.payload);
    case getType(actions.setTermsOfUse):
      return setTermsOfUse(state, action.payload);
    case getType(actions.setDineInUserHelp):
      return setDineInUserHelp(state, action.payload);
    case getType(actions.setEnabledDiningMode):
      return setEnabledDiningMode(state, action.payload);
    case getType(actions.setSmsNotificationsDineIn):
      return setSmsNotificationsDineIn(state, action.payload);
    case getType(actions.setSmsNotificationsTakeAway):
      return setSmsNotificationsTakeAway(state, action.payload);
    case getType(actions.setIsPaymentDisabled):
      return setIsPaymentDisabled(state, action.payload);
    case getType(actions.setIsTakeAwayPayOnCollectionEnabled):
      return setIsTakeAwayPayOnCollectionEnabled(state, action.payload);
    case getType(actions.setOrderThreshold):
      return setOrderThreshold(state, action.payload);
    case getType(actions.setIsVisitorCaptureActive):
      return setIsVisitorCaptureActive(state, action.payload);
    case getType(actions.setIsEmailReceiptPromptEnabled):
      return setIsEmailReceiptPromptEnabled(state, action.payload);
    case getType(actions.setCurrency):
      return setCurrency(state, action.payload);
    case getType(actions.setReceiptDetails):
      return setReceiptDetails(state, action.payload);
    case getType(actions.setPosConnected):
      return setPosConnected(state, action.payload);
    case getType(actions.setGoogleAnalyticsIds):
      return setGoogleAnalyticsIds(state, action.payload);

    default:
      return state;
  }
};
