import { useCallback } from 'react';

import { gtagConfig, gtagJs } from 'util/ga';
import { error } from 'util/log';
import useScript from 'hooks/useScript';

import useGoogleAnalyticsConfig from './useGoogleAnalyticsConfig';


export default (gaIds: string[]) => {
  const gaConfig = useGoogleAnalyticsConfig(gaIds);

  const sendData = useCallback(() => {
    if (gaConfig) {
      Object.keys(gaConfig.clientIdsByGaIds).forEach((gaId) => {
        gtagConfig(gaId, {
          anonymize_ip: true,
          send_page_view: true,
          client_storage: 'none',
          client_id: gaConfig.clientIdsByGaIds[gaId],
          page_title: document.title,
          page_location: window.location.href,
          page_path: window.location.pathname,
        });
      });
    }
  }, [gaConfig]);

  const onLoad = useCallback(() => {
    if (gaConfig) {
      gtagJs(new Date());
      sendData();
    }
  }, [gaConfig, sendData]);

  const onError = useCallback((e) => error('GA: useScript failed', e), []);

  useScript({
    scriptUrl: gaConfig ? gaConfig.scriptURL : undefined,
    onLoad,
    onError,
  });

  return gaConfig ? { sendData } : undefined;
};
