import { ActionType, createAction, getType } from 'typesafe-actions';

import { Table } from 'types/model';

import { RootState } from '.';


export interface TablesState {
  tables: Table[];
  currentTable?: Table;
}

// initial state
export const initialState: TablesState = {
  tables: [],
};

// actions
export const actions = {
  setTables: createAction('tables/setTables')<Table[]>(),
  setCurrentTable: createAction('tables/setCurrentTable')<Table | undefined>(),
};

export type Actions = ActionType<typeof actions>;

// selectors
const getTables = (state: RootState) => state.tables.tables;
const getCurrentTable = (state: RootState) => state.tables.currentTable;

export const selectors = {
  getTables,
  getCurrentTable,
};

// reducer
const setTables = (state: TablesState, tables: Table[]): TablesState => ({
  ...state,
  tables,
});

const setCurrentTable = (state: TablesState, currentTable?: Table): TablesState => ({
  ...state,
  currentTable,
});

export const reducer = (state: TablesState = initialState, action: Actions): TablesState => {
  switch (action.type) {
    case getType(actions.setTables):
      return setTables(state, action.payload);
    case getType(actions.setCurrentTable):
      return setCurrentTable(state, action.payload);

    default:
      return state;
  }
};
