import React, { FC } from 'react';

import useConfigurableText from 'hooks/useConfigurableText';
import OrdamoLogo from 'style/img/ordamologo.png';


const Loading: FC = () => {
  const ct = useConfigurableText();

  return (
    <div className="PageSimple">
      <div className="container">
        <div className="head">
          <img src={OrdamoLogo} alt="Ordamo Logo" />
        </div>
        <div className="body">
          <p>{ct('loadingScreen.loading')}</p>
        </div>
      </div>
    </div>
  );
};

export default Loading;
