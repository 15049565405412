import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as ordersSelectors } from 'ducks/orders';
import { actions as yumpingoActions, selectors as yumpingoSelectors } from 'ducks/yumpingo';
import startReview from 'effects/startYumpingoReview';
import useConfigurableText from 'hooks/useConfigurableText';
import ServerActionButton from 'containers/ServerActionButton';


const YumpingoReviewPrompt: FC = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();

  const mostRecentOrder = useSelector(ordersSelectors.getMostRecentPastOrder);
  const isShowing = useSelector(yumpingoSelectors.getIsPromptShowing);

  const ButtonRemindText = ct('yumpingo.buttons.remind');
  const ButtonReviewText = ct('yumpingo.buttons.review');

  const buttonPressed = () => {
    dispatch(yumpingoActions.setIsPromptShowing(false));
    dispatch(yumpingoActions.setShouldShowModal(false));
  };

  return mostRecentOrder && isShowing
    ? (
      <div className="review-box">
        <p className="review-text">
          {ct('yumpingo.texts.workingHard')}<br />{ct('yumpingo.texts.pleaseHelp')}
        </p>
        <div className="review-btns">
          <ServerActionButton
            type="button"
            variant="secondary"
            size="medium"
            label={ButtonRemindText}
            onClick={() => {
              buttonPressed();
              dispatch(yumpingoActions.setIsReminderModalShowing(true));
            }}
          />
          <ServerActionButton
            type="button"
            variant="primary"
            size="medium"
            label={ButtonReviewText}
            onClick={() => {
              buttonPressed();
              startReview(mostRecentOrder.id);
            }}
          />
        </div>
      </div>
    )
    : null;
};

export default YumpingoReviewPrompt;
