import { Event } from 'types/sockets';

import SocketManager from './socketManager';


export default async (webSocket: SocketManager, deviceId: number) => {
  const { diner_session_id: sessionId } = await webSocket.emit(
    Event.kioskSessionOpenV1,
    {
      deviceId,
    }
  );
  return { sessionId };
};
