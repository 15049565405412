import moment from 'moment';

import { DayOfTheWeek, Period } from 'types/model';


export const MS_PER_SEC = 1000;
export const SEC_PER_MIN = 60;
export const MIN_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;

export const getCurrentDayOfTheWeek = () => {
  // Date.getDay() is zero indexed on Sunday, DayOfTheWeek is zero indexed on Monday
  const day = (new Date().getDay() + 6) % 7;
  return Object.values(DayOfTheWeek)[day];
};

export const weekdayNumberToDayOfTheWeek = (weekday: number): DayOfTheWeek => {
  switch (weekday) {
    case 0:
      return DayOfTheWeek.monday;
    case 1:
      return DayOfTheWeek.tuesday;
    case 2:
      return DayOfTheWeek.wednesday;
    case 3:
      return DayOfTheWeek.thursday;
    case 4:
      return DayOfTheWeek.friday;
    case 5:
      return DayOfTheWeek.saturday;
    case 6:
      return DayOfTheWeek.sunday;
    default:
      throw new Error('Invalid argument');
  }
};

export const comparePeriods = (p1: Period, p2: Period) => {
  if (p1.day === p2.day) {
    return p1.startTime - p2.startTime;
  }

  const days = Object.values(DayOfTheWeek);
  return days.findIndex((day: DayOfTheWeek) => day === p1.day)
    - days.findIndex((day: DayOfTheWeek) => day === p2.day);
};

export const groupPeriodsByDay = (periods: Period[]) => periods
  .reduce(
    (grouped, period) => grouped.set(
      period.day,
      (grouped.get(period.day) ?? []).concat(period)
    ),
    new Map<DayOfTheWeek, Period[]>()
  );

export const formatTime = (time = 0): string => {
  const hour = String(Math.trunc(time / 60));
  const mins = String(time % 60).padStart(2, '0');
  const formatted = `${hour}:${mins}`;
  return formatted;
};

export const formatPeriodsAsOpeningHours = (periods: Period[] = [], closedText = '') => periods
  .sort(comparePeriods)
  .reduce(
    (dayHours, period) =>
      `${dayHours ? `${dayHours}; ` : ''}${formatTime(period.startTime)} - ${formatTime(period.endTime)}`,
    ''
  ) || closedText;

export const msDurationToMinsSecs = (msDuration: number) => {
  const duration = moment.duration(msDuration);
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return `${minutes ? `${minutes} ${
    minutes !== 1 ? 'minutes' : 'minute'
  } ` : ''}${seconds.toFixed(0)} ${
    seconds !== 1 ? 'seconds' : 'second'
  }`;
};

export const getDiffInCalendarDays = (date1: Date, date2: Date) => {
  const date1RoundToDays = moment([date1.getFullYear(), date1.getMonth(), date1.getDate()]);
  const date2RoundToDays = moment([date2.getFullYear(), date2.getMonth(), date2.getDate()]);

  return date1RoundToDays.diff(date2RoundToDays, 'days');
};
