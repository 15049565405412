import React, { FC } from 'react';

import { Tag } from 'types/model';
import { formatText } from 'util/i18n';

import './tagSettingSection.scss';


interface Props {
  title: string;
  tags: Tag[];
  selectedTags: Set<number>;
  toggleTag: (tag: Tag, checked: boolean) => void;
}

export const TagSettingSection: FC<Props> = ({ title, tags, selectedTags, toggleTag }) =>
  <>
    {tags.length > 0 &&
      <div className="tagSection">
        <h4 className="title">
          {title}
        </h4>
        <fieldset className="inputs">
          {tags.map(tag => (
            <label key={tag.id} className="Checkbox" htmlFor={`tag_${tag.id}`}>
              <input
                id={`tag_${tag.id}`}
                type="checkbox"
                checked={selectedTags.has(tag.id)}
                onChange={(e) => toggleTag(tag, e.currentTarget.checked)}
              />
              <div className="State">
                <span>{formatText(tag.title)}</span>
              </div>
            </label>
          ))}
        </fieldset>
      </div>
    }
  </>;
