import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { OrderedItem } from 'types/model';
import { formatCurrency } from 'util/i18n';
import { getUniqueKey, getUniqueOrderedItems, sortOrderedItems } from 'util/orderedItems';
import { selectors as ordersSelectors } from 'ducks/orders';
import { selectors as paymentSelectors } from 'ducks/payment';
import { selectors as settingsSelectors } from 'ducks/settings';
import useConfigurableText from 'hooks/useConfigurableText';
import { Drawer } from 'components/Drawer';
import { OrderedItemCard } from 'components/OrderedItemCard';


interface OrderSummaryProps {
  orderNumber: string;
  orderedItems: OrderedItem[];
  isOpen: boolean;
  unpaidTipAmount?: number;
  onOpen?: () => void;
  onClose: () => void;
}

const OrderSummary: FC<OrderSummaryProps> = ({ orderNumber, orderedItems, isOpen, unpaidTipAmount, onOpen, onClose }) => {
  const ct = useConfigurableText();
  const orderTotal = useSelector(() => ordersSelectors.getOrderTotal(orderedItems));
  const discount = useSelector(ordersSelectors.getMostRecentDiscount);
  const voucher = useSelector(ordersSelectors.getMostRecentVoucher);
  const paidTipAmount = useSelector(paymentSelectors.getPaidTipAmount) || 0;
  const isServiceChargeActive = useSelector(settingsSelectors.getIsServiceChargeActive);
  const currency = useSelector(settingsSelectors.getCurrency);
  const tipAmount = unpaidTipAmount ?? paidTipAmount;

  return (
    <>
      <Drawer
        className="PageSimple-drawer"
        title={ct('orderSummary.orderNumber', { orderNumber })}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <div className="orderList Order">
          <div className="scrollArea">
            {orderedItems && sortOrderedItems(getUniqueOrderedItems(orderedItems))
              .map((orderedItem) => (
                <OrderedItemCard key={getUniqueKey(orderedItem)} orderedItem={orderedItem} currentlyOrderedItems={orderedItems} showCounter />
              ))
            }
          </div>
          {/* Service charges and Total price */}
          <div className="cost">
            {voucher &&
              <div>
                <h2 className="title">{ct('orderSummary.discount')} <small>{voucher.description || ''}</small></h2>
                <span className="price">{formatCurrency(-discount, currency)}</span>
              </div>
            }
            {isServiceChargeActive &&
              <div className="card">
                <div className="dish">
                  <h4 className="title">{ct('orderSummary.serviceCharges')}<small>{ct('orderSummary.optional')}</small></h4>
                  <span className="price">{formatCurrency(tipAmount, currency)}</span>
                </div>
              </div>
            }
            <div>
              <h2 className="title">{ct('orderSummary.totalPrice')}</h2>
              <span className="price">{formatCurrency(Math.max(orderTotal - discount, 0) + tipAmount, currency)}</span>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default OrderSummary;
