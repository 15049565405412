import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { push } from 'connected-react-router';

import { Event } from 'types/sockets';
import { getMenuHomePath } from 'util/path';
import { selectors as menuSelectors } from 'ducks/menu';
import { selectors as ordersSelectors } from 'ducks/orders';
import { actions as sessionActions, selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import { actions as tablesActions, selectors as tablesSelectors } from 'ducks/tables';
import initDinerSession from 'effects/initDinerSession';
import initKioskSession from 'effects/initKioskSession';
import updateDinerInfo from 'effects/updateDinerInfo';
import updateOpenOrderScheduledTakeaway from 'effects/updateOpenOrderScheduledTakeaway';
import { useWebSocket } from 'contexts/SocketManagerContext';
import useConfigurableText from 'hooks/useConfigurableText';
import DineInUserHelp from 'containers/DineInUserHelp';
import NewSessionModal from 'containers/NewSessionModal';
import ServerActionButton from 'containers/ServerActionButton';
import TakeawayScheduleInfo from 'containers/TakeawayScheduleInfo';
import TakeawayScheduleSelect from 'containers/TakeawayScheduleSelect';
import VisitorModal from 'containers/VisitorModal';
import { Button } from 'components/Button';
import { loadDinerName, saveDinerName } from 'localStorage';
import OrdamoLogo from 'style/img/ordamologo.png';
import { ReactComponent as SvgDineIn } from 'style/svg/dineIn.svg';
import { ReactComponent as SvgTakeAway } from 'style/svg/takeAway.svg';


const WelcomeScreen: FC = () => {
  const dispatch = useDispatch();
  const webSocket = useWebSocket();
  const ct = useConfigurableText();

  const dineIn = useSelector(sessionSelectors.getDineIn);
  const deviceId = useSelector(sessionSelectors.getDeviceId);
  const identification = useSelector(sessionSelectors.getIdentification);
  const visitor = useSelector(sessionSelectors.getVisitor);
  const theme = useSelector(settingsSelectors.getTheme);
  const currentTable = useSelector(tablesSelectors.getCurrentTable);
  const tables = useSelector(tablesSelectors.getTables);
  const dineInEnabled = useSelector(settingsSelectors.getIsDineInEnabled);
  const roomServiceEnabled = useSelector(settingsSelectors.getIsRoomServiceEnabled);
  const takeAwayEnabled = useSelector(settingsSelectors.getIsTakeAwayEnabled);
  const schedule = useSelector(ordersSelectors.getSchedule);
  const menus = useSelector(menuSelectors.getSelectableMenus);

  const [tableTitle, setTableTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [dinerName, setDinerName] = useState(() => loadDinerName() ?? '');
  const [submitting, setSubmitting] = useState(false);

  const tableOrRoom = ct(roomServiceEnabled ? 'model.room' : 'model.table');

  useEffect(
    () => {
      setTableTitle(currentTable?.title || '');
    },
    [currentTable]
  );

  const handleChangeDineIn = (newDineIn: boolean) => {
    if (newDineIn !== dineIn) {
      setErrorMessage('');
      dispatch(sessionActions.setDineIn(newDineIn));
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!deviceId || submitting) return;
    let sessionId: number;

    setSubmitting(true);

    if (dineIn) {
      const table = tables.find((t) => t.title === tableTitle);
      if (!table) {
        setErrorMessage(ct('welcomeScreen.error.invalidNumber', { tableOrRoom }));
        setSubmitting(false);
        return;
      }

      dispatch(tablesActions.setCurrentTable(table));
      sessionId = (await initDinerSession(webSocket, deviceId, table)).sessionId;
    } else {
      sessionId = (await initKioskSession(webSocket, deviceId)).sessionId;
    }

    if (visitor) await webSocket.emit(Event.createVisitorV1, { identification, visitor });
    if (dineIn) {
      saveDinerName(dinerName);
      await updateDinerInfo(webSocket, dispatch, deviceId, dinerName);
    }

    let canContinue = true;
    if (schedule?.scheduleTimestamp) {
      canContinue = await updateOpenOrderScheduledTakeaway(dispatch, webSocket, sessionId, schedule.scheduleTimestamp);
    }

    if (canContinue) {
      dispatch(sessionActions.setSessionId(sessionId));
      dispatch(push(getMenuHomePath(menus)));
    } else {
      // TODO: inform the user about failure
    }
  };

  return (
    <>
      <main className="Welcome">
        <div className="CompanyLogo">
          <img src={theme.logo || OrdamoLogo} alt="CompanyLogo" />
          <div className="Welcome-message">
            <p>{ct('welcomeScreen.welcomeMessage')}</p>
          </div>
        </div>
        <section>
          <form className="Welcome-form" onSubmit={handleSubmit}>
            <div className="Welcome-btn">
              {(dineInEnabled || roomServiceEnabled) && (
                <Button
                  className={cx({ active: dineIn })}
                  type="button"
                  label={ct(roomServiceEnabled ? 'model.diningMode.roomService' : 'model.diningMode.dineIn')}
                  variant="square"
                  size="large"
                  onClick={() => handleChangeDineIn(true)}
                >
                  <SvgDineIn />
                </Button>
              )}
              {takeAwayEnabled && (
                <Button
                  className={cx({ active: !dineIn })}
                  type="button"
                  label={ct('model.diningMode.takeAway')}
                  variant="square"
                  size="large"
                  onClick={() => handleChangeDineIn(false)}
                >
                  <SvgTakeAway />
                </Button>
              )}
            </div>
            {dineIn ? (
              <div className="Welcome-inputBox">
                <div className="inputBox">
                  <label htmlFor="tableOrRoomNumber">
                    {ct('welcomeScreen.number', { tableOrRoom })}
                    <input
                      id="tableOrRoomNumber"
                      className={cx({ error: errorMessage })}
                      type="text"
                      value={tableTitle}
                      disabled={!!currentTable}
                      onChange={
                        (e: FormEvent<HTMLInputElement>) => {
                          setErrorMessage('');
                          setTableTitle(e.currentTarget.value);
                        }
                      }
                      placeholder={ct('welcomeScreen.numberPrompt', { tableOrRoom: tableOrRoom?.toLowerCase() })}
                    />
                  </label>
                  {errorMessage && <span className="error-message">*{errorMessage}</span>}
                </div>
                <div className="inputBox">
                  <label htmlFor="customerName">
                    {ct('dinerInfo.name')}
                    <input
                      id="customerName"
                      type="text"
                      value={dinerName}
                      onChange={
                        (e: FormEvent<HTMLInputElement>) => {
                          setDinerName(e.currentTarget.value);
                        }
                      }
                      placeholder={ct('dinerInfo.namePlaceholder')}
                    />
                  </label>
                </div>
              </div>
            ) : (
              <>
                <TakeawayScheduleInfo />
                <TakeawayScheduleSelect />
              </>
            )}
            <DineInUserHelp />
            <ServerActionButton
              label={ct('nav.continue')}
              type="submit"
              variant="primary"
              size="large"
              disabled={!!errorMessage || submitting}
            />
          </form>
        </section>
        <VisitorModal />
      </main>
      <NewSessionModal />
    </>
  );
};

export default WelcomeScreen;
