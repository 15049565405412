import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { selectors as settingsSelectors } from 'ducks/settings';
import { ReactComponent as SVGBackArrow } from 'style/svg/back-button.svg';

import './header.scss';


interface HeaderProps {
  title?: string;
  backTo?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  hideLogo?: boolean;
  buttons?: ReactNode;
}

export const Header: FC<HeaderProps> = ({ title, backTo, hideLogo, onClick, buttons = [], children }) => {
  const theme = useSelector(settingsSelectors.getTheme);

  return (
    <div className="header">
      <div className="head">
        { backTo &&
          <Link to={backTo} onClick={onClick} className="backLink">
            <SVGBackArrow />
          </Link>
        }
        { !backTo && onClick &&
          <button className="backLink" onClick={onClick}>
            <SVGBackArrow />
          </button>
        }
        <div className={cx('content', { headerButtons: !!buttons })}>
          { hideLogo || !theme.logo
            ? <h1>{title}</h1>
            : <img src={theme.logo} className="logo" alt="CompanyLogo" />
          }
        </div>
        { buttons != null &&
          <div className="box">
            {buttons}
          </div>
        }
      </div>
      { children != null &&
        <div className="body">
          {children}
        </div>
      }
    </div>
  );
};
