import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { actions as sessionActions, selectors as sessionSelectors } from 'ducks/session';
import useConfigurableText from 'hooks/useConfigurableText';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';


const AddNoteModal: FC = () => {
  const dispatch = useDispatch();
  const ct = useConfigurableText();

  const formNote = useSelector(sessionSelectors.getNote);
  const showAddNoteModal = useSelector(sessionSelectors.getShowAddNoteModal);
  const [note, setNote] = useState(formNote ?? '');

  const handleSubmit = () => {
    dispatch(sessionActions.setNote(note));
    dispatch(sessionActions.setShowAddNoteModal(false));
  };

  return (
    <Modal
      position="bottom"
      className="Modal-userinfo"
      isShown={showAddNoteModal}
      onSubmit={handleSubmit}
    >
      <div className="Modal-header">
        <h3>{ct('userInfo.addNoteHeadline')}</h3>
        <Button
          type="submit"
          size="small"
          variant="oval"
          label={ct('userInfo.done')}
        />
      </div>
      <div className={cx('inputBox')}>
        <label htmlFor="userInfoFullName">
          {ct('userInfo.addNoteLabel')}
          <textarea
            id="userInfoFullName"
            value={note}
            placeholder={ct('userInfo.addNotePlaceholder')}
            onChange={e => setNote(e.currentTarget.value)}
          />
        </label>
      </div>
    </Modal>
  );
};

export default AddNoteModal;
