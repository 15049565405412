import React, { FC } from 'react';

import { ReactComponent as SvgPickUp } from 'style/svg/pickup.svg';

import './pickup.scss';


interface PickUpBoxProps {
  title: string;
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const PickUpBox: FC<PickUpBoxProps> = ({ title, children, label, disabled, onClick }) => (
  <div className="PickUp">
    <div className="PickUp-header">{title}</div>
    <div className="body">
      { children && (
        <div>
          <SvgPickUp />
          {children}
        </div>
      )}
      <button onClick={onClick} disabled={disabled}>
        {label}
      </button>
    </div>
  </div>
);
