/* eslint-disable no-console */

import { LogLevel } from 'types/model';
import { logConfig } from 'config/logConfig';


const ordinal = (level: LogLevel): number => Object.keys(LogLevel).indexOf(level);

const logIfLevel = (level: LogLevel, ...toLog: any[]) => {
  if (ordinal(level) >= ordinal(logConfig.logLevel)) {
    console[level](...toLog);
  }
};

export const debug = (...toLog: any[]) => logIfLevel(LogLevel.debug, ...toLog);
export const info = (...toLog: any[]) => logIfLevel(LogLevel.info, ...toLog);
export const warn = (...toLog: any[]) => logIfLevel(LogLevel.warn, ...toLog);
export const error = (...toLog: any[]) => logIfLevel(LogLevel.error, ...toLog);
