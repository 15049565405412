import { CreateTokenResponse } from './judoPay';
import { I18nText, MenuType, ModifierSubtype, ModifierType, OrderStatus, UserInfo } from './model';


export enum Path {
  connectPaymentStart = '/payment/connect/start',
  judoPayPaymentStart = '/payment/judo-pay/start',
  judoPayPaymentCreateSession = '/payment/judo-pay/create-session',
  judoPayPaymentEndSession = '/payment/judo-pay/end-session',
  judoPayPaymentFinish = '/payment/judo-pay/finish',
  judoPayPaymentCancel = '/payment/judo-pay/cancel',
  mockPaymentStart = '/payment/mock/start',
  mockPaymentFinish = '/payment/mock/finish',
  yumpingo = '/yumpingo/start',
}

export interface APIPaymentStartPayload {
  sessionId: number;
  deviceId: number;
  tipAmount: number;
  userInfo?: UserInfo;
  note?: string;
}

export interface APIPaymentCreateSessionPayload {
  sessionId: number;
  deviceId: number;
  uuid: string;
}

export interface APIPaymentCreateSessionResponse {
  sessionReference: string;
  yourConsumerReference: string;
  yourPaymentReference: string;
  judoId: string;
  amount: string;
  currency: string;
}

export interface APIPaymentEndSessionPayload {
  sessionId: number;
  deviceId: number;
  uuid: string;
  sessionReference: string;
}

export interface APIPaymentFailPayload {
  sessionId: number;
  deviceId: number;
  uuid: string;
}

export interface APIJudoPayPaymentStartResponse {
  paymentId: number;
  uuid: string;
}

export interface APIJudoPayPaymentCancelPayload {
  sessionId: number;
  deviceId: number;
  uuid: string;
}

export interface APIJudoPayPaymentFinishPayload extends CreateTokenResponse {
  sessionId: number;
  deviceId: number;
  uuid: string;
}

export interface APIJudoPayPaymentFinishResponse {
  message: string;
  receiptId: string;
  acsUrl: string;
  md: string;
  paReq: string;
  termUrl: string;
}

export interface APIConnectPaymentStartResponse {
  chargeTotal: string;
  successUrl: string;
  failureUrl: string;
  hash: string;
  paymentId: number;
  txnDateTime: string;
}

export interface APIMockPaymentStartResponse {
  paymentId: number;
}

export interface APIMockPaymentFinishPayload {
  paymentId: number;
  fail: boolean;
}

export interface APIMenuItem {
  id: number;
  title: I18nText;
  description: I18nText;
  hasPhoto: boolean;
  price: number;
  available: boolean;
  modifiers: number[];
  upsell: number;
  tags: number[];
  pairs: number[];
  dineInTaxId: number;
  takeAwayTaxId: number;
  posId: string | null;
  // deleted: null
  // updated: "2020-06-02T12:26:43.250Z"
}

export interface APIModifierOption {
  id: number;
  menuItemId: number;
  price: number | null;
  title: I18nText | null;
  rank: number;
  deleted: null | string;
}

export interface APIModifier {
  id: number;
  deleted: string;
  title: I18nText;
  text: I18nText;
  modifierType: ModifierType;
  subtype: ModifierSubtype;
  options: APIModifierOption[];
  rank: number;
  minChoices?: number;
  maxChoices?: number;
}

export interface APIMenuNode {
  nodeId: number;
  title: I18nText;
  children?: APIMenuNode[];
  rank: number;
}

export interface APIItemNode extends APIMenuNode {
  itemId: number;
  price?: number;
}
export const instanceOfAPIItemNode = (apiMenuNode: APIMenuNode): apiMenuNode is APIItemNode => 'itemId' in apiMenuNode;

export interface APIMenu {
  id: number;
  title: I18nText;
  description: string | undefined;
  hasPhoto: boolean;
  active: boolean;
  type: MenuType;
  typeI18nKey: string;
  deleted: null | Date;
  rank: number;
  menuSections: APIRankedMenuSectionId[];
}

export interface APIRankedMenuSectionId {
  id: number;
  menuSectionId: number;
  rank: number;
}

export interface APIMenuSection {
  nodeId: string;
  title: I18nText;
  description?: I18nText;
  hasPhoto: boolean;
  updated: Date;
  children?: APIMenuNode[];
}

export interface APIMenuSectionChanges {
  insert: APIMenuSection[];

  // remove: []
}

export interface APIOrder {
  id: number;
  state_id: OrderStatus;
  ts_updated: string;
  ts_paid: string;
  ts_accepted: string;
  ts_ready: string;
  tip_amount: number;
  scheduled_takeaway: string;
  voucher_id: number | null;

  // diner_session_id: number;
  // dine_in: null
  // note: null
}

export interface APIModifierValues {
  [key: number]: string | number[];
}

export interface APIOrderItem {
  id: number;
  title: I18nText;
  price: number;
  order_id: number;
  menu_item_id: number;
  menu_node_id: number;
  modifierValues: APIModifierValues;

  // added: Date;
  // diner_device_id: number
}

export interface APIDeviceAssignedCallbackParams {
  itemsUpdated: Date;
  menuUpdated: Date;

  // sections: menuSectionSelectors.getMainSectionIds(reduction),
  // hashes: {
  //   [SETTING_PRIVACY_POLICY]: getPrivacyPolicyHash(reduction),
  //   [SETTING_TERMS_OF_USE]: getTermsOfUseHash(reduction),
  // },
}

export interface APIYumpingoPayload {
  orderId: number;
  email?: string;
}

export interface APIYumpingoResponse {
  url: string;
}
