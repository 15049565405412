import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { routePath } from 'constants/routes';
import AppointTime from 'containers/AppointTime';
import ContactStaff from 'containers/ContactStaff';
import ErrorScreen from 'containers/ErrorScreen';
import MenuScreen from 'containers/MenuScreen';
import MenuSelectScreen from 'containers/MenuSelectScreen';
import NotFound from 'containers/NotFound';
import OrderDetails from 'containers/OrderDetails';
import OrderScreen from 'containers/OrderScreen';
import PaymentFailureScreen from 'containers/PaymentFailureScreen';
import PaymentScreen from 'containers/PaymentScreen';
import PaymentSuccessScreen from 'containers/PaymentSuccessScreen';
import PhoneNumber from 'containers/PhoneNumber';
import PrivacyPolicyScreen from 'containers/PrivacyPolicyScreen';
import SendOrderSuccessScreen from 'containers/SendOrderSuccessScreen';
import TermsOfUse from 'containers/TermsOfUseScreen';
import WaitingForWaiterApprovalScreen from 'containers/WaitingForWaiterApprovalScreen';
import WelcomeScreen from 'containers/WelcomeScreen';


const Router: FC = () => (
  <Switch>
    <Route exact path={routePath.root} component={WelcomeScreen} />
    <Route exact path={routePath.menus} component={MenuSelectScreen} />
    {/* TODO - protect the "/menu" route */}
    <Route exact path={`${routePath.menus}/:id`} component={MenuScreen} />
    {/* TODO - protect the "/order" route */}
    <Route exact path={routePath.order} component={OrderScreen} />
    <Route exact path="/order-details" component={OrderDetails} />
    <Route exact path="/phone-number" component={PhoneNumber} />
    <Route exact path="/payment" component={PaymentScreen} />
    {/* TODO - protect the "/payment-success" route */}
    <Route exact path="/payment-success" component={PaymentSuccessScreen} />
    {/* TODO - protect the "/payment-failure" route */}
    <Route exact path="/payment-failure" component={PaymentFailureScreen} />
    <Route exact path="/waiting-waiter-approval" component={WaitingForWaiterApprovalScreen} />
    <Route exact path="/send-order-success" component={SendOrderSuccessScreen} />
    <Route exact path="/privacy-policy" component={PrivacyPolicyScreen} />
    <Route exact path="/terms-of-use" component={TermsOfUse} />
    <Route exact path="/appoint-time" component={AppointTime} />
    <Route exact path={`${routePath.contactStaff}/:id`} component={ContactStaff} />
    <Route exact path="/error" component={ErrorScreen} />
    <Route exact path={routePath.notFound} component={NotFound} />
    <Route render={() => (<NotFound />)} />
  </Switch>
);

export default Router;
