import { ChoiceModifier, Modifier, ModifierType, Tag } from 'types/model';


export const meetsFilterConditions = (itemAllergens: Tag[], itemFilters: Tag[], selectedAllergens: Set<number>, selectedFilters: Set<number>) => {
  const hasFilteredOutAllergens = itemAllergens.some(allergen => selectedAllergens.has(allergen.id));
  const matchingFilters = itemFilters.filter(tag => selectedFilters.has(tag.id));
  const hasAllAppliedFilters = matchingFilters.length === selectedFilters.size;
  return !hasFilteredOutAllergens && hasAllAppliedFilters;
};

export const hasMinChoiceOfFilteredOptions = (modifier: Modifier, selectedAllergens: Set<number>, selectedFilters: Set<number>) => {
  if (modifier.type === ModifierType.text) {
    return true;
  }
  const m = modifier as ChoiceModifier;
  if (!m.minChoices) {
    return true;
  }

  const filteredOptions = m.options.filter(option => meetsFilterConditions(option.allergens, option.filters, selectedAllergens, selectedFilters));
  return filteredOptions.length >= m.minChoices;
};
