import React, { FC, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { push } from 'connected-react-router';
import EmailValidator from 'email-validator';

import { routePath } from 'constants/routes';
import { getTakeAwayBypassPaymentType } from 'util/paymentBypass';
import { selectors as ordersSelectors } from 'ducks/orders';
import { actions as paymentActions } from 'ducks/payment';
import { actions as sessionActions, selectors as sessionSelectors } from 'ducks/session';
import { selectors as settingsSelectors } from 'ducks/settings';
import sendUnpaidTakeAwayOrder from 'effects/sendUnpaidTakeAwayOrder';
import { useWebSocket } from 'contexts/SocketManagerContext';
import useConfigurableText from 'hooks/useConfigurableText';
import AddNoteModal from 'containers/AddNoteModal';
import ServerActionButton from 'containers/ServerActionButton';
import TakeawayScheduleSelect from 'containers/TakeawayScheduleSelect';
import { Button } from 'components/Button';
import { Header } from 'components/Header';


interface UserInfoErrors {
  email?: string;
}

const OrderDetails: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const webSocket = useWebSocket();
  const ct = useConfigurableText();

  const userInfo = useSelector(sessionSelectors.getUserInfo);
  const sessionId = useSelector(sessionSelectors.getSessionId)!;
  const deviceId = useSelector(sessionSelectors.getDeviceId);
  const isTakeAway = useSelector(sessionSelectors.getTakeAway);
  const modalNote = useSelector(sessionSelectors.getNote);
  const isTakeAwayPayOnCollectionEnabled = useSelector(settingsSelectors.getIsTakeAwayPayOnCollectionEnabled);
  const orderTotal = useSelector(ordersSelectors.getCurrentOrderTotal);
  const tipAmount = useSelector(ordersSelectors.getServiceChargeAmount);

  const [orderSent, setOrderSent] = useState(false);
  const [errors, setErrors] = useState<UserInfoErrors>();
  const [fullName, setFullName] = useState(userInfo?.fullName ?? '');
  const [email, setEmail] = useState(userInfo?.email ?? '');
  const [phoneNumber, setPhoneNumber] = useState(userInfo?.phoneNumber ?? '');
  const [fieldNote, setFieldNote] = useState<string | undefined>(modalNote);

  const isOrderFree = orderTotal === 0;
  const note = fieldNote ?? modalNote;

  const buttonPayOnCollectText = ct('shared.labels.payOnCollect');
  const buttonContinueText = isOrderFree
    ? ct('orderScreen.button.sendOrder')
    : ct('shared.labels.payNow');

  const handleAddNoteClick = () => {
    dispatch(sessionActions.setShowAddNoteModal(true));
  };

  const handleBackClick = () => {
    dispatch(sessionActions.setNote(note));
    dispatch(sessionActions.setUserInfo({ fullName, email, phoneNumber }));
    history.goBack();
  };

  const isFormValid = () => fullName.length > 0 && email.length > 0 && phoneNumber.length > 0;

  const handleSubmit = async (isPaidOnline: boolean) => {
    const currentErrors: UserInfoErrors = {};
    if (!EmailValidator.validate(email)) {
      currentErrors.email = ct('shared.email.invalid');
    }

    const takeAwayPaymentBypassType = getTakeAwayBypassPaymentType(isTakeAwayPayOnCollectionEnabled, isPaidOnline, isOrderFree);

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors);
      return;
    }
    if (takeAwayPaymentBypassType) {
      setOrderSent(true);
      dispatch(paymentActions.setIsVatReceiptAllowed(false));
      await sendUnpaidTakeAwayOrder(
        webSocket,
        sessionId,
        deviceId,
        takeAwayPaymentBypassType,
        { fullName, email, phoneNumber },
        tipAmount,
        note
      );
    } else {
      setOrderSent(true);
      dispatch(paymentActions.setIsVatReceiptAllowed(true));
      dispatch(sessionActions.setNote(note));
      dispatch(sessionActions.setUserInfo({ fullName, email, phoneNumber }));
      dispatch(push('/payment'));
    }
  };

  if (!isTakeAway) {
    return <Redirect to={{ pathname: routePath.order }} />;
  }
  return (
    <div className="container-fixed Order">
      <Header
        hideLogo
        title={ct('shared.titles.orderDetails')}
        onClick={orderSent ? undefined : handleBackClick}
      />
      <div className="Order-container">
        <TakeawayScheduleSelect />
        <h3>{ct('shared.titles.requiredInformation')}</h3>
        <div className="inputBox">
          <label htmlFor="userInfoFullName">
            {ct('userInfo.yourName')}*
            <input
              id="userInfoFullName"
              type="text"
              value={fullName}
              placeholder="Full Name"
              disabled={orderSent}
              onChange={e => setFullName(e.currentTarget.value)}
            />
          </label>
        </div>
        <div className={cx('inputBox', errors?.email && 'hasError')}>
          <label htmlFor="userInfoEmail">
            {ct('shared.email.yourEmail')}*
            <input
              id="userInfoEmail"
              type="text"
              value={email}
              placeholder={ct('shared.email.placeholder')}
              disabled={orderSent}
              onChange={e => setEmail(e.currentTarget.value)}
            />
          </label>
          {errors?.email && (
            <div className="error-message">*{errors.email}</div>
          )}
        </div>
        <div className="inputBox">
          <label htmlFor="userInfoContactPhone">
            {ct('userInfo.yourNumber')}*
            <input
              id="userInfoContactPhone"
              type="tel"
              value={phoneNumber}
              placeholder={ct('userInfo.yourNumberPlaceholder')}
              disabled={orderSent}
              onChange={e => setPhoneNumber(e.currentTarget.value)}
            />
          </label>
        </div>
        {modalNote ?
          <div className="inputBox">
            <label htmlFor="userInfoNote">
              {ct('userInfo.yourNote')}
              <textarea
                id="userInfoNote"
                value={note}
                placeholder={ct('userInfo.addNotePlaceholder')}
                disabled={orderSent}
                onChange={e => setFieldNote(e.currentTarget.value)}
              />
            </label>
          </div> :
          <Button
            type="button"
            size="medium"
            variant="oval"
            label={ct('orderDetailPage.addNote')}
            disabled={orderSent}
            onClick={handleAddNoteClick}
          />
        }
      </div>
      <div className="OrderDetails-buttons">
        <div className="buttons">
          {isTakeAwayPayOnCollectionEnabled && !isOrderFree &&
            <ServerActionButton
              label={buttonPayOnCollectText}
              size="large"
              type="submit"
              variant="secondary"
              disabled={orderSent || !isFormValid()}
              onClick={() => handleSubmit(false)}
            />
          }
          <ServerActionButton
            label={buttonContinueText}
            size="large"
            type="submit"
            variant="primary"
            disabled={orderSent || !isFormValid()}
            onClick={() => handleSubmit(true)}
          />
        </div>
      </div>
      <AddNoteModal />
    </div>
  );
};

export default OrderDetails;
