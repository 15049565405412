import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectors as settingsSelectors } from 'ducks/settings';
import useAnalytics from 'hooks/analytics/useAnalytics';
import useAuthorizeDevice from 'hooks/useAuthorizeDevice';
import useTheme from 'hooks/useTheme';
import ConnectionBox from 'containers/ConnectionBox';
import Router from 'containers/Router';
import TimeoutModal from 'containers/TimeoutModal';
import Loading from 'components/Loading';


const App: FC = () => {
  const isLoaded = useSelector(settingsSelectors.getIsLoaded);
  const restaurantName = useSelector(settingsSelectors.getRestaurantName);
  useAnalytics();
  useAuthorizeDevice();
  useTheme();

  useEffect(() => {
    document.title = restaurantName;
  }, [restaurantName]);

  return isLoaded
    ? (
      <>
        <ConnectionBox />
        <Router />
        <TimeoutModal />
      </>
    ) : (
      <Loading />
    );
};

export default App;
