import React, { FC, useCallback, useEffect, useRef } from 'react';

import { Path } from 'types/api';
import { JudoPay, JudoPaySession } from 'types/judoPay';
import { debug } from 'util/log';
import { post } from 'effects/api';


interface JudoPayAppleButtonProps {
  readonly session: JudoPaySession;
  readonly judo: JudoPay;
}

const JudoPayAppleButton: FC<JudoPayAppleButtonProps> = ({ session, judo }) => {
  const buttonContainerRef = useRef<HTMLDivElement|null>(null);
  const isPaymentStartedRef = useRef<boolean>(false);

  const handleApplePayButtonClick = useCallback(() => {
    if (!isPaymentStartedRef.current) {
      const invokePaymentWithApplePay = async () => {
        const {
          deviceId,
          sessionId,
          uuid,
          sessionReference,
          amount,
          currency,
          yourConsumerReference,
          yourPaymentReference,
          judoId,
        } = session;
        const configuration = { amount, currency, yourConsumerReference, yourPaymentReference, judoId };
        try {
          isPaymentStartedRef.current = true;
          await judo.digitalWallets.invokePaymentWithApplePay(sessionReference, configuration);
        } catch (e) {
          debug('JudoPayAppleButton: Apple Pay payment failed');
        }
        post(Path.judoPayPaymentEndSession, { deviceId, sessionId, uuid, sessionReference });
      };

      invokePaymentWithApplePay();
    }
  }, [judo, session]);

  useEffect(() => {
    const buttonContainer = buttonContainerRef.current!;
    const button = judo.digitalWallets.getApplePayButton({
      height: 48,
      width: 240,
      language: 'EN',
      style: 'black',
      onClick: handleApplePayButtonClick,
    });
    if (button) {
      buttonContainer.appendChild(button);
    } else {
      debug('JudoPay: failed to create Apple Pay Button');
    }
    return () => {
      if (button) {
        buttonContainer.removeChild(button);
      }
    };
  }, [judo, handleApplePayButtonClick]);

  return <div ref={buttonContainerRef} />;
};

export default JudoPayAppleButton;
