import React, { FC } from 'react';

import './progressBar.scss';


interface Props {
  fulfilledPercentage: number;
  remainingInterval: number;
  remainingIntervalDescription: string;
}

export const ProgressBar: FC<Props> = ({ fulfilledPercentage, remainingInterval, remainingIntervalDescription }) => {
  const style = { '--progressBar': fulfilledPercentage } as React.CSSProperties;

  return (
    <div className="progressBar" style={style}>
      <div className="box">
        <div className="circle">
          <svg viewBox="0 0 151 151">
            <circle cx="70" cy="70" r="70" />
            <circle cx="70" cy="70" r="70" />
          </svg>
        </div>
        <div className="info">
          <div className="number">
            {remainingInterval}
          </div>
          <div className="text">
            {remainingIntervalDescription}
          </div>
        </div>
      </div>
    </div>
  );
};
