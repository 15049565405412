import { useMemo } from 'react';
import { useParams } from 'react-router';


export default () => {
  const { id } = useParams<{id?: string}>();

  const paramId = useMemo(() => {
    const result = Number(id);
    return Number.isNaN(result) ? undefined : result;
  }, [id]);

  return paramId;
};
