import axios from 'axios';

import {
  APIConnectPaymentStartResponse,
  APIJudoPayPaymentCancelPayload,
  APIJudoPayPaymentFinishPayload,
  APIJudoPayPaymentFinishResponse,
  APIJudoPayPaymentStartResponse,
  APIMockPaymentFinishPayload,
  APIMockPaymentStartResponse,
  APIPaymentCreateSessionPayload,
  APIPaymentCreateSessionResponse,
  APIPaymentEndSessionPayload,
  APIPaymentStartPayload,
  APIYumpingoPayload,
  APIYumpingoResponse,
  Path as ApiPath,
} from 'types/api';
import { debug } from 'util/log';
import { restaurantConfig } from 'config/restaurantConfig';


export async function get(path: ApiPath, params?: Record<string, string>): Promise<object | void> {
  const { ordamoServerBase, apiPath, apiVersion } = restaurantConfig;
  const baseURL = `${ordamoServerBase}${apiPath}/${apiVersion}`;

  const data = (
    await axios.get(path, {
      baseURL,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      params,
    })
  ).data;
  debug(`API GET ${path}`, data);
  return data;
}

export async function post(path: ApiPath.connectPaymentStart, params: APIPaymentStartPayload): Promise<APIConnectPaymentStartResponse>;
export async function post(path: ApiPath.judoPayPaymentStart, params: APIPaymentStartPayload): Promise<APIJudoPayPaymentStartResponse>;
export async function post(path: ApiPath.judoPayPaymentCreateSession, params: APIPaymentCreateSessionPayload): Promise<APIPaymentCreateSessionResponse>;
export async function post(path: ApiPath.judoPayPaymentEndSession, params: APIPaymentEndSessionPayload): Promise<void>;
export async function post(path: ApiPath.judoPayPaymentFinish, params: APIJudoPayPaymentFinishPayload): Promise<APIJudoPayPaymentFinishResponse>;
export async function post(path: ApiPath.judoPayPaymentCancel, params: APIJudoPayPaymentCancelPayload): Promise<APIJudoPayPaymentFinishResponse>;
export async function post(path: ApiPath.mockPaymentStart, params: APIPaymentStartPayload): Promise<APIMockPaymentStartResponse>;
export async function post(path: ApiPath.mockPaymentFinish, payload: APIMockPaymentFinishPayload): Promise<void>;
export async function post(path: ApiPath.yumpingo, payload: APIYumpingoPayload): Promise<APIYumpingoResponse>;
export async function post(path: ApiPath, payload?: object): Promise<object | void> {
  const { ordamoServerBase, apiPath, apiVersion } = restaurantConfig;
  const baseURL = `${ordamoServerBase}${apiPath}/${apiVersion}`;

  const data = (await axios.post(`${baseURL}${path}`, payload)).data;
  debug(`API POST ${path}`, data);
  return data;
}
