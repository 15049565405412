import { restaurantConfig } from 'config/restaurantConfig';


const isInsideIFrame = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const canMakeApplePayPayments = () => (
  !isInsideIFrame() && (
    restaurantConfig.isApplePayMocked ||
    (window.ApplePaySession && window.ApplePaySession.canMakePayments())
  )
);
